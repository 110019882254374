import AuthForm from "./AuthForm/AuthForm";
import "./AuthNew.scss"
import {MouseParallaxChild, MouseParallaxContainer} from "react-parallax-mouse";
import left_top from "../../../images/auth/left_top.png";
import left_middle from "../../../images/auth/left_middle.png";
import left_bottom from "../../../images/auth/left_bottom.png";
import right_top from "../../../images/auth/right_top.png";
import right_middle from "../../../images/auth/right_middle.png";
import right_bottom from "../../../images/auth/right_bottom.png";
import React, {useEffect, useState} from "react";
import StepsContainer from "./StepsContainer/StepsContainer";
import {useNavigate} from "react-router-dom";

const AuthNew =  () => {

    const history = useNavigate();

    const [showSteps, setShowSteps] = useState(false)

    useEffect(() => {
        if (localStorage.getItem("token")) return history("/");
    }, []);

    return (
        <div className="AuthNew">
            <div className="container">
                {!showSteps
                    ? <div className="form"><AuthForm showSteps={() => setShowSteps(true)}/></div>
                    : <StepsContainer/>
                }

            </div>
            <div
                className="block_parralax"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1
                }}
            >
                <MouseParallaxContainer
                    className="parallax"
                    containerStyles={{width: "100%", height: "98%", overflow: "inherit"}}
                    resetOnLeave
                >
                    <MouseParallaxChild factorX={0.01} factorY={0.02} updateStyles={{width: "100%", height: "100%"}}>
                        <div className="left_top"><img src={left_top} alt=""/></div>
                        <div className="left_middle"><img src={left_middle} alt=""/></div>
                        <div className="left_bottom"><img src={left_bottom} alt=""/></div>
                        <div className="right_top"><img src={right_top} alt=""/></div>
                        <div className="right_middle"><img src={right_middle} alt=""/></div>
                        <div className="right_bottom"><img src={right_bottom} alt=""/></div>
                    </MouseParallaxChild>
                </MouseParallaxContainer>
            </div>
            <div className="no_parallax" style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 0,
                    opacity: 0,
                    display: "none"
                }}>
                <div className="left_top" style={{zIndex: 0}}><img src={left_top} alt=""/></div>
                <div className="left_middle" style={{zIndex: 0}}><img src={left_middle} alt=""/></div>
                <div className="left_bottom" style={{zIndex: 0}}><img src={left_bottom} alt=""/></div>
                <div className="right_top" style={{zIndex: 0}}><img src={right_top} alt=""/></div>
                <div className="right_middle" style={{zIndex: 0}}><img src={right_middle} alt=""/></div>
                <div className="right_bottom" style={{zIndex: 0}}><img src={right_bottom} alt=""/></div>
            </div>
        </div>
    )
}

export default AuthNew