import React from 'react';
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import requests from "../../../axios/requests";

import { ReactComponent as ArrowDrop } from "../../../../images/arrow.svg";
import { ReactComponent as Rating } from "../../../../images/search_talants/rating.svg";
import fake_photos from "../../../../images/fake_photos.png";
import './FreelancerCard.scss';

export const FreelancerCard = (props) => {
    const variable_23 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_23'})
    const {
        indx, photo, salary, name, position, id, favorite, changeFavoriteState,
        skills, skillsResume, openListSkills, setOpenListSkills, inAuth = false,
        flagAuth = true, checkboxCatalog = true, isResume = false
    } = props;

    let handleAddFavorite = (id) => {
        requests.users.add_freelancer_favorite(id)
            .catch(err => console.error(err))
    }
    let handleRemoveFavorite = (id) => {
        requests.users.remove_freelance_favorite(id)
            .catch(err => console.error(err))
    };
    return (
        <li>
            <div className="info">

                <div
                    className={favorite ? "favorite_freelancer active" : "favorite_freelancer"}
                    onClick={() => {
                        if (!flagAuth || inAuth)
                            return
                        if (favorite) {
                            handleRemoveFavorite(id)
                            changeFavoriteState(id, false)
                        } else {
                            handleAddFavorite(id)
                            changeFavoriteState(id, true)
                        }
                    }}
                >
                    <Rating/>
                </div>
                <img src={photo || fake_photos}/>
                <div className="salary">{salary}</div>
                <div className="name">{name}</div>
                <div className="position">{position || ""}</div>
            </div>
            <div className={openListSkills ? "skills_list-parent open" : "skills_list-parent"}>
                <div className="skills_list">
                    {(skills?.length > 0 || skillsResume?.length > 0) &&
                        skills.concat([], skillsResume).map((elem, indx) =>
                            <div
                                className="skill"
                                key={indx}
                            >
                                <span>{elem?.name ? elem?.name : elem}</span>
                            </div>
                        )
                    }

                </div>
                <div
                    className="arrow_drop"
                    onClick={() => setOpenListSkills(indx)}
                >
                    <ArrowDrop/>
                </div>
            </div>

            <div
                className="open_resume"
                onClick={() => {
                    if (!inAuth && checkboxCatalog) {
                        if (isResume)
                            window.open(window.location.href.split("/profile")[0] + `/resume/${id}`, "_blank")
                        else window.open(window.location.href.split("/freelance-list")[0] + `/resume/${id}`, "_blank")
                    }
                }}
            >
                <span>{variable_23}</span>
            </div>
        </li>
    )
}

export default FreelancerCard;