import React from 'react';
import checked from "../../../../images/landing/checked.svg";
import checked_mobile from "../../../../images/landing/checked_mobile.svg";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";

const WhatLearn = ({data, skillRef}) => {
    const variable_19 = variableLanguage({keyPage: "landing", keyVariable: "variable_19"});
    return (
        <>
            {data && data?.quest_skills && data.quest_skills?.length > 0 &&
                <div ref={skillRef} className="what_learn">
                    <div className="title"> {variable_19}</div>
                    <ul className={"list"}>
                        {data.quest_skills.map((elem, indx) =>
                            <li key={indx}>
                                <div className="parent_check">
                                    <div className="check_icon">
                                        <img src={checked}/>
                                    </div>
                                    <div className="check_icon-mobile">
                                        <img src={checked_mobile}/>
                                    </div>
                                    <div className="skill">
                                        {elem.speciality_tag.name || ""}
                                    </div>
                                    <div className="info">
                                        {elem.description || ""}
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            }
        </>
    );
};

export default WhatLearn;