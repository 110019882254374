import React, { memo } from "react";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import add_icon_step3 from "../../../../images/icons/add_icon-step3.svg";

const DangerSkill = (props) => {
    const {
        data, addSkillTraektory, setCoordsTagAnimation,
        isFavorite = false, needAnimation = false
    } = props;
    let arrRefs = {};

    let handleAddSkill = (skill, e, indx) => {
        if (needAnimation) {
            let widthElem = window.getComputedStyle(arrRefs[indx], null).getPropertyValue("width");
            e["widthElem"] = Number(widthElem.replace("px", ""));
            setCoordsTagAnimation(e);
        }
        addSkillTraektory(skill);
    };
    let handleSetRefs = (content, indx) => {
        arrRefs = ({...arrRefs, [indx]: content});
    };

    return (
        <div className="danger_skill">
            {data.map((skill, indx) =>
                <AnimatePresence key={indx}>
                    <motion.div
                        initial={{opacity: 0, transform: "scale(0.5)"}}
                        animate={{opacity: 1, transform: "scale(1)"}}
                        transition={{delay: .6}}
                    >
                        <div
                            ref={(content) => handleSetRefs(content, indx)}
                            key={indx}
                            className={isFavorite ? "skill yellow" : "skill"}
                            onClick={(e) => handleAddSkill(skill, e, indx)}
                            style={{cursor: "pointer"}}
                        >
                            <span>{skill.name}</span>
                            <img src={add_icon_step3}/>
                        </div>
                    </motion.div>
                </AnimatePresence>
            )}
        </div>
    );
};

export default memo(DangerSkill);