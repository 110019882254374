import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    setActiveListProfession,
    setActiveProfession,
    setActiveVacancy
} from "../../../redux/actions/searchProfessionActions";
import cn from "classnames";

import { defaultFuncAndData } from "../../../../utils/defaultData";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import { Pagination, Spin } from "antd";
import Profession from "./Profession";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";

import profession_list_label from "../../../../images/search_profession/profession_list_label.svg";
import './ProfessionList.scss';

const ProfessionList = (props) => {
    const variables_2 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_2"});
    const variables_3 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_3"});
    const variables_6 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_6"});
    const variables_38 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_38"});

    const {
        searchProfessionInput, setSearchProfessionInput, flagDropDownProfession,
        setFlagDropDownProfession, displayListProfession, setFlagModalProfessionInfo,
        flagModalProfessionInfo, favoriteProfession, handleChangeFavoriteProfession,
        setCurrentPage, loadingProfession, listProfessionOrigin, currentPage, changeIntro,
        flagSteps
    } = props;
    const dispatch = useDispatch();
    const {activeListProfession, activeProfession} = useSelector(state => state.SearchProfession);

    return (
        <div className="profession_list">
            <div className="professions">
                <div
                    className="btn_intro"
                    onClick={() => changeIntro(true)}
                >
                    {variables_38}
                </div>
                <div className="title">
                    <img src={profession_list_label}/>
                    <span>{variableLanguage({keyPage: "searchProfession", keyVariable: "variables_1"})}</span>
                </div>
                <Input
                    className={cn("", {
                        z_index_0: flagSteps
                    })}
                    padding={"4px 0 6px 40px"}
                    marginParent={"12px 0 10px 0"}
                    value={searchProfessionInput}
                    setValue={setSearchProfessionInput}
                    backgroundColor={"transparent"}
                    placeholder={variables_6}
                    needFindIconMobile={true}
                />
                <div className="buttons_select">
                    <Button
                        text={variables_3}
                        click={() => {
                            dispatch(setActiveListProfession(0));
                            setFlagDropDownProfession(false);
                        }}
                        padding={"4px 15px 6px"}
                        maxWidth={"100%"}
                        margin={"0 15px 10px 0"}
                        boxShadow={"none"}
                        backgroundColor={activeListProfession === 0 ? "" : "transparent"}
                        border={"1px solid var(--text_color_dark)"}
                    />
                    <Button
                        text={variables_2}
                        click={() => {
                            dispatch(setActiveListProfession(1));
                            setFlagDropDownProfession(false);
                        }}
                        padding={"4px 15px 6px"}
                        maxWidth={"100%"}
                        margin={"0 0 10px 0"}
                        boxShadow={"none"}
                        backgroundColor={activeListProfession === 1 ? "" : "transparent"}
                        border={"1px solid var(--text_color_dark)"}
                    />
                </div>
                <ul className="list_profs">
                    {displayListProfession.length > 0 &&
                        displayListProfession.map((prof, index) =>
                            <Profession
                                key={index}
                                procentague={prof.percent && prof.percent}
                                professionTitle={prof.name}
                                click={(obj) => {
                                    dispatch(setActiveProfession(obj))
                                    dispatch(setActiveVacancy(false))
                                }}
                                activeId={activeProfession?.id}
                                setFlagModalProfessionInfo={() => {
                                    if (!flagSteps)
                                        setFlagModalProfessionInfo(!flagModalProfessionInfo)
                                }}
                                favoriteProfession={favoriteProfession}
                                changeFavoriteProfession={handleChangeFavoriteProfession}
                                prof={{...prof}}
                            />
                        )
                    }

                </ul>
                {loadingProfession &&
                    <Spin style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}/>
                }
                {listProfessionOrigin && listProfessionOrigin.length > 12 &&
                    !searchProfessionInput &&
                    <div className="pagination_all">
                        <Pagination
                            current={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={12}
                            total={listProfessionOrigin.length}
                            onChange={(e) => setCurrentPage(e)}
                            showTitle={false}
                            itemRender={defaultFuncAndData.itemRenderPagination}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default ProfessionList;