import React, { useEffect, useState } from "react";
import requests from "../../../axios/requests";
import { useNavigate, useParams } from "react-router-dom";
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

import test_result_ok from "../../../../images/icons/test_result_ok.svg";
import test_result_wrong from "../../../../images/icons/test_result_wrong.svg";
import left_top from "../../../../images/auth/left_top.png";
import left_middle from "../../../../images/auth/left_middle.png";
import left_bottom from "../../../../images/auth/left_bottom.png";
import right_top from "../../../../images/auth/right_top.png";
import right_middle from "../../../../images/auth/right_middle.png";
import right_bottom from "../../../../images/auth/right_bottom.png";
import Button from "../../../components/Button/Button";

const PaymentResp = () => {
    const history = useNavigate();
    const params = useParams();

    const idCourse = params?.id;
    const statusPayment = params?.status;

    const [dataCourse, setDataCourse] = useState({});
    const [statusResp, setStatusResp] = useState("");

    useEffect(() => {
        requests.quests.payment_init_get(idCourse)
            .then(res => {
                setDataCourse(res.data);
                if (res.data.status) {
                    switch (res.data.status) {
                        case "CREATED":
                            return setStatusResp("Платеж зарегистрирован в шлюзе, но его обработка в процессинге не начата");
                        case "NEW":
                            return setStatusResp("Инициализирован");
                        case "CANCELED":
                            return setStatusResp("Отменен");
                        case "DEADLINE_EXPIRED":
                            return setStatusResp("Время сессии истекло");
                        case "FORM_SHOWED":
                            return setStatusResp("Покупатель переправлен на страницу оплаты");
                        case "AUTHORIZING":
                            return setStatusResp("Аутентификация покупателя");
                        case "REJECTED":
                            return setStatusResp("Отклонено банком");
                        case "3DS_CHECKING":
                            return setStatusResp("Начало аутентификация 3-D Secure");
                        case "3DS_CHECKED":
                            return setStatusResp("Завершение аутентификации 3-D Secure");
                        case "AUTH_FAIL":
                            return setStatusResp("Не пройдена аутентификация 3-D Secure");
                        case "PAY_CHECKING":
                            return setStatusResp("Платеж обрабатывается");
                        case "AUTHORIZED":
                            return setStatusResp("Средства заблокированы, но не списаны");
                        case "REVERSING":
                            return setStatusResp("Начало отмены блокировки средств");
                        case "REVERSED":
                            return setStatusResp("Денежные средства разблокированы");
                        case "CONFIRMING":
                            return setStatusResp("Начало списания денежных средств");
                        case "CONFIRM_CHECKING":
                            return setStatusResp("Платеж обрабатывается");
                        case "CONFIRMED":
                            return setStatusResp("Оплата прошла успешно");
                        case "REFUNDING":
                            return setStatusResp("Начало возврата денежных средств");
                        case "ASYNC_REFUNDING":
                            return setStatusResp("Обработка возврата денежных средств по QR");
                        case "REFUNDED":
                            return setStatusResp("Произведен возврат денежных средств");
                        case "PARTIAL_REFUNDED":
                            return setStatusResp("Произведен частичный возврат денежных средств");
                        default:
                            return setStatusResp("Ошибка обработки");
                    }
                }
            })
            .catch(err => console.error(err));
    }, []);
    return (
        <div className="payment_resp">
            <MouseParallaxContainer
                className="parallax"
                resetOnLeave
            >
                <MouseParallaxChild
                    factorX={0.01}
                    factorY={0.02}
                    updateStyles={{
                        width: "100%",
                        height: "100%",
                        display: "flex"
                    }}
                >
                    <div className="block_parralax">
                        <main className="left_col">
                            <main className="left_top"><img src={left_top} alt=""/></main>
                            <main className="left_middle"><img src={left_middle} alt=""/></main>
                            <main className="left_bottom"><img src={left_bottom} alt=""/></main>
                        </main>
                        <main className="right_col">
                            <main className="right_top"><img src={right_top} alt=""/></main>
                            <main className="right_middle"><img src={right_middle} alt=""/></main>
                            <main className="right_bottom"><img src={right_bottom} alt=""/></main>
                        </main>
                    </div>
                </MouseParallaxChild>
            </MouseParallaxContainer>
            <div className="center_block">
                {statusResp &&
                    <>
                        {statusPayment === "success"
                            ? <>
                                <img src={test_result_ok}/>
                                <div className="h1">Оплата прошла успешно</div>
                                {!window.location.href.includes("education") &&
                                    <Button
                                        text={"Перейти к курсу"}
                                        click={() => history(`/student/course/lesson/${dataCourse.quest.id}`)}
                                        padding={"12px 13px 14px"}
                                        margin={"30px 0 0 0"}
                                    />
                                }
                            </>
                            : <>
                                <img src={test_result_wrong}/>
                                <div className="h1">
                                    Операция не подтверждена: <br/>
                                </div>
                                <div className="status_payment">{statusResp}</div>
                                {window.location.href.includes("education")
                                    ? <div
                                        className="error_btn"
                                        onClick={() => history("/")}
                                    >
                                        Вернуться на главную
                                    </div>

                                    : <div
                                        className="error_btn"
                                        onClick={() => history("/course-list")}
                                    >
                                        Вернуться в каталог
                                    </div>
                                }

                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default PaymentResp;