import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import { ReuseDots } from "../Landing";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";

const WhoSuits = ({data, whoSuitsRef, whoSuitsRefMobile}) => {
    const variable_18 = variableLanguage({keyPage: "landing", keyVariable: "variable_18"});

    const [activeSlideWhoSuits, setActiveSlideWhoSuits] = useState(0);
    const settingsWhoSuitsMobile = {
        /*dots: true,*/
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "who_suits-slides-mobile",
        arrows: false,
        initialSlide: activeSlideWhoSuits
    };
    const carouselRefWhoSuits = useRef(null);

    useEffect(() => {
        if (carouselRefWhoSuits.current?.slickGoTo)
            carouselRefWhoSuits.current.slickGoTo(activeSlideWhoSuits);
    }, [activeSlideWhoSuits]);
    return (
        <>
            {data && data?.quest_recommendations && data.quest_recommendations?.length > 0 &&
                <>
                    <div ref={whoSuitsRef} className="who_suits">
                        <div className="title">
                            {variable_18}
                        </div>
                        <ul className="list">
                            {data && data.quest_recommendations && data.quest_recommendations.length > 0 &&
                                data.quest_recommendations.map((elem, indx) =>
                                    <li key={indx}>
                                        <div className="number"><span>{indx + 1}</span></div>
                                        <div className="to_whom">{elem.title || ""}</div>
                                        <div className="info">{elem.description || ""}</div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    <div className="who_suits-mobile" ref={whoSuitsRefMobile}>
                        <div className="title">
                            {variable_18}
                        </div>
                        <Slider
                            {...settingsWhoSuitsMobile}
                            afterChange={(e) => setActiveSlideWhoSuits(e)}
                            ref={carouselRefWhoSuits}
                        >
                            {data.quest_recommendations.map((elem, indx) =>
                                <div key={indx}>
                                    <div className={"slider_item"}>
                                        <div className="number"><span>{indx + 1}</span></div>
                                        <div className="to_whom">{elem.title || ""}</div>
                                        <div className="info">{elem.description || ""}</div>
                                    </div>
                                </div>
                            )
                            }
                        </Slider>
                        <ReuseDots
                            activeSlide={activeSlideWhoSuits}
                            setActiveSlide={(indx) => setActiveSlideWhoSuits(indx)}
                            countDots={data.quest_recommendations.length}
                            color={"#B1E5ED"}
                        />
                    </div>
                </>
            }
        </>
    );
};

export default WhoSuits;