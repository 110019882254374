import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import requests from "../../axios/requests";
import { defaultFuncAndData } from "../../../utils/defaultData";
import { motion } from "framer-motion/dist/framer-motion";

import { Collapse, message, Progress, Spin, Tooltip, Typography } from "antd";
import variableLanguage from "../../../utils/hookGetVariablesLanguage";
import Scorm from "./Content/WebContent/Scorm/Scorm";
import WebContent from "./Content/WebContent/WebContent";
import EditorCode from "./Content/WebContent/EditorCode/EditorCode";
import StagesPage from "./Content/MobileContent/StagesPage";
import ContentPage from "./Content/MobileContent/ContentPage";
import ModalCertificate from "./ModalCertificate/ModalCertificate";

import { ReactComponent as Medal_ } from "../../../images/icons/medal_.svg";
import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { ReactComponent as Video_icon } from "../../../images/icons/course_walk_1.svg";
import { ReactComponent as Article_icon } from "../../../images/icons/course_walk_2.svg";
import { ReactComponent as Test_icon } from "../../../images/icons/course_walk_3.svg";
import { ReactComponent as HomeWork_icon } from "../../../images/icons/course_walk_4.svg";
import { ReactComponent as Code_icon } from "../../../images/icons/code_icon.svg";
import { ReactComponent as LMS_icon } from "../../../images/icons/lms_icon.svg";
import { ReactComponent as Grade1 } from "../../../images/grades/grade1.svg";
import { ReactComponent as Grade2 } from "../../../images/grades/grade2.svg";
import { ReactComponent as Grade3 } from "../../../images/grades/grade3.svg";
import { ReactComponent as Grade4 } from "../../../images/grades/grade4.svg";
import { ReactComponent as Grade5 } from "../../../images/grades/grade5.svg";
import { ReactComponent as Grade6 } from "../../../images/grades/grade6.svg";
import { ReactComponent as Grade7 } from "../../../images/grades/grade7.svg";
import { ReactComponent as Grade8 } from "../../../images/grades/grade8.svg";
import { ReactComponent as Grade9 } from "../../../images/grades/grade9.svg";
import test_result_wrong from "../../../images/icons/test_result_wrong.svg";
import cn from "classnames";

const {Panel} = Collapse;
const {Text} = Typography;

const Lesson = () => {
    const variable_1 = variableLanguage({keyPage: "lesson", keyVariable: "variable_1"});
    const variable_2 = variableLanguage({keyPage: "lesson", keyVariable: "variable_2"});
    const variable_3 = variableLanguage({keyPage: "lesson", keyVariable: "variable_3"});
    const variable_4 = variableLanguage({keyPage: "lesson", keyVariable: "variable_4"});
    const variable_5 = variableLanguage({keyPage: "lesson", keyVariable: "variable_5"});
    const variable_6 = variableLanguage({keyPage: "lesson", keyVariable: "variable_6"});
    const variable_7 = variableLanguage({keyPage: "lesson", keyVariable: "variable_7"});
    const variable_8 = variableLanguage({keyPage: "lesson", keyVariable: "variable_8"});
    const variable_9 = variableLanguage({keyPage: "lesson", keyVariable: "variable_9"});
    const variable_51 = variableLanguage({keyPage: "lesson", keyVariable: "variable_51"});
    const variable_63 = variableLanguage({keyPage: "lesson", keyVariable: "variable_63"});

    const params = useParams();
    const lesson_id = params?.id;
    const {user, language} = useSelector(state => state.Reducer);

    const [activeTheoryNum, setActiveTheoryNum] = useState(0);
    const [activeStageId, setActiveStageId] = useState(null);
    const [result, setResult] = useState(false);
    const [stages, setStages] = useState([]);
    const [stageItems, setStageItems] = useState([]);
    const [stageId, setStageId] = useState(0);
    const [progress, setProgress] = useState([]);
    const [fullScreen, setFullScreen] = useState(false);
    const [timeoutProgress, setTimeoutProgress] = useState(null);
    const [currentQuest, setCurrentQuest] = useState(null);
    const [urlCertificate, setUrlCertificate] = useState("");
    const [oldProgress, setOldProgress] = useState(null);

    const [flagIsArchived, setFlagIsArchived] = useState(false);
    const [userCode, setUserCode] = useState(``);
    const [triggerLmsEnd, setTriggerLmsEnd] = useState(0);
    const [flagErrorFetch, setFlagErrorFetch] = useState(false);
    const [activePage, setActivePage] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    const icons = [
        {
            type: "A",
            icon: <Article_icon/>,
            iconDone: <Article_icon className={"end"}/>,
            iconProgress: <Article_icon/>
        },
        {
            type: "V",
            icon: <Video_icon/>,
            iconDone: <Video_icon className={"end"}/>,
            iconProgress: <Video_icon/>
        },
        {
            type: "Q",
            icon: <Test_icon/>,
            iconDone: <Test_icon className={"end"}/>,
            iconProgress: <Test_icon/>
        },
        {
            type: "T",
            icon: <HomeWork_icon/>,
            iconDone: <HomeWork_icon className={"end"}/>,
            iconProgress: <HomeWork_icon/>
        },
        {
            type: "C",
            icon: <Code_icon/>,
            iconDone: <Code_icon className={"end"}/>,
            iconProgress: <Code_icon/>
        },
        {
            type: "LMS",
            icon: <LMS_icon/>,
            iconDone: <LMS_icon className={"end"}/>,
            iconProgress: <LMS_icon/>
        }
    ];
    let showCertificate = () => {
        if (currentQuest !== null && (currentQuest?.organization_id === 481) && !currentQuest?.certificate) {
            return currentQuest?.progress?.progress || 0
        }
        return 0
    }
    let gradeIdentifyer = (percent) => {
        if (percent > 9 && percent < 20) {
            return [<Grade1/>, <Text>{variable_1}</Text>];
        } else if (percent > 20 && percent < 31) {
            return [<Grade2/>, <Text>{variable_2}</Text>];
        } else if (percent > 30 && percent < 41) {
            return [<Grade3/>, <Text>{variable_3}</Text>];
        } else if (percent > 40 && percent < 51) {
            return [<Grade4/>, <Text>{variable_4}</Text>];
        } else if (percent > 50 && percent < 61) {
            return [<Grade5/>, <Text>{variable_5}</Text>];
        } else if (percent > 60 && percent < 71) {
            return [<Grade6/>, <Text>{variable_6}</Text>];
        } else if (percent > 70 && percent < 81) {
            return [<Grade7/>, <Text>{variable_7}</Text>];
        } else if (percent > 80 && percent < 91) {
            return [<Grade8/>, <Text>{variable_8}</Text>];
        } else if (percent > 90 && percent < 101) {
            return [<Grade9/>, <Text>{variable_9}</Text>];
        }
        return [];
    };
    let activate = (activeTheoryNum) => {
        setActiveTheoryNum(activeTheoryNum);
    };
    let getTheories = async (stage_id) => {
        if (stage_id) {
            setStageId(stage_id);
            await requests.stages.get_stages(lesson_id)
                .then((v) => {
                    if (v.status === 200) {
                        setStages(v.data);
                        setFlagErrorFetch(false)
                    }
                    if (v.data?.length === 0)
                        setTimeout(() => setFlagErrorFetch(true), 1000)
                });
            requests.stages
                .get_stage_theories_info(stage_id)
                .then(({data}) => {
                    if (data?.length === 0) {
                        recurcyGetMatherial(stage_id, 100)
                        return
                    }
                    setStageItems(data);
                    setActiveTheoryNum(0);
                    /*if (typeof activeTheoryNum !== "undefined")
                        setActiveTheoryNum(activeTheoryNum);*/
                })
                .catch((e) => {
                    console.error(e);
                })
        }
    };
    let getTheoryIcon = (type, seen, task_type) => {
        if (task_type === "code") {
            return icons.filter((x) => x.type === "C").map((x) => {
                return !seen ? x.icon : x.iconDone;
            });
        }
        return icons.filter((x) => x.type === type).map((x) => {
            return !seen ? x.icon : x.iconDone;
        });
    };
    let post_seen = (stage_theory_id) => {
        if (window.innerWidth > 820)
            requests.stages.post_seen({stage_theory_id})
                .catch(err => console.error(err))
    };
    let handleClick = () => {
        requests.stages.get_stages(lesson_id)
            .then((v) => {
                if (v.status === 200) {
                    setStages(v.data);
                    setFlagErrorFetch(false)
                }
                if (v.data?.length === 0)
                    setTimeout(() => setFlagErrorFetch(true), 1000)
            });
    };
    let checkCourseEnabled = (stages, i) => {
        if (i === 0)
            return true;
        return stages[i].available;
    };
    let genExtra = (countAll, countUser, available, stage = false) => {
        let currentColorStage = () => {
            if (available) {
                if (countAll !== countUser)
                    return "stage_span active";
                else
                    return "stage_span";
            } else
                return "stage_span disabled";
        };
        return (
            <div
                className={cn(currentColorStage(), {
                    activeLoader: stage?.generation_in_progress
                })}
            >
                <span>{available ? countUser : "0"}</span>
                <span> / </span>
                <span> {countAll}</span>
                {stage?.declined_homeworks !== 0 &&
                    <Tooltip
                        placement="right"
                        title={variable_51}
                    >
                        <div className="countDenyHomework">
                            {stage.declined_homeworks}
                        </div>
                    </Tooltip>
                }
                {stage?.generation_in_progress &&
                    <div className={"spin"}>
                        <Spin/>
                    </div>
                }
            </div>
        );
    };
    let getCurrentColorIcon = (elem, indx) => {
        switch (elem?.type) {
            case "T":
                if (stageItems.length > 0 && stageItems[indx]?.tasks.length > 0) {
                    if (stageItems[indx].tasks.length > 0) {
                        for (let i = 0; i < stageItems[indx].tasks.length; i++) {
                            if (stageItems[indx].tasks[i].checked === "passed") {
                                return "icon green";
                            }
                            if (stageItems[indx].tasks[i].checked === "need_correction") {
                                return "icon red";
                            }
                        }
                    }
                }
                break;
            case "Q":
                if (stageItems?.length > 0) {
                    let findElem = stageItems.find(x => x.id === elem?.stage_theory_id || x.id === elem.id);

                    if (findElem?.passed) {
                        return "icon green";
                    }
                }
                break;
            case "A":
                if (elem?.seen)
                    return "icon green";
                break;
            case "V":
                if (elem?.seen)
                    return "icon green";
                break;
        }
        return "icon";
    };
    let recurcyGetMatherial = (stage_id, limit) => {
        requests.stages
            .get_stage_theories_info(stage_id)
            .then(res => {
                if ((res.data?.length === 0) && limit--) {
                    if (Math.sign(limit) !== -1) {
                        return setTimeoutProgress(
                            setTimeout(() =>
                                recurcyGetMatherial(stage_id, 100), 5000)
                        );
                    }
                } else {
                    setStageItems(res.data);
                    setActiveTheoryNum(0);
                }
                clearTimeout(timeoutProgress);
            })
            .catch(err => {
                console.error(err)
            })
    }
    let fetchCurrentQuest = () => {
        requests.quests.get_course_id("/" + stages[0]?.quest_ses)
            .then(({data}) => {
                setCurrentQuest(data)
                if (data.progress?.progress !== 100)
                    setOldProgress(data.progress?.progress)
            })
            .catch(err => console.error(err));
    }
    let handleGenerateCertificate = (fio, email) => {
        requests.quests.generate_certificate(currentQuest?.ses, fio, email)
            .then((data) => {
                message.success("Сертификат отправлен на вашу почту.")
                setOpenModal(false);
                setUrlCertificate(`https://skillometer.ru${data?.certificate}` || "")
                fetchCurrentQuest()
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        requests.stages.get_stages(lesson_id)
            .then((v) => {
                if (v.status === 200) {
                    setStages(v.data);
                    setActiveStageId(v.data[0]?.id)
                    getTheories(v.data[0]?.id);
                    setFlagErrorFetch(false)
                }
                if (v.data?.length === 0)
                    setTimeout(() => setFlagErrorFetch(true), 1000)
            })
            .catch(err => console.error(err));
    }, []);
    useEffect(() => {
        (async () => {
            if (stageItems[activeTheoryNum]) {
                await requests.stages.get_stages(lesson_id)
                    .then((v) => {
                        if (v.status === 200) {
                            setStages(v.data);
                            setFlagErrorFetch(false)
                        }
                        if (v.data?.length === 0)
                            setTimeout(() => setFlagErrorFetch(true), 1000)
                    })
                    .catch(err => console.error(err))
                await post_seen(stageItems[activeTheoryNum].id);
            }
            await requests.quests
                .get_quest_progress(lesson_id)
                .then((data) => {
                    setProgress(data.data.progress);
                })
                .catch(err => console.error(err))
        })();
    }, [stageItems, activeTheoryNum, lesson_id, result, triggerLmsEnd]);
    useEffect(() => {
        setResult(false);
    }, [stageItems[activeTheoryNum]]);
    useEffect(() => {
        if (stageId) {
            requests.stages
                .get_stage_theories_info(stageId)
                .then(({data}) => {
                    if (data[0]?.archived) {
                        setFlagIsArchived(true)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [stageId]);
    useEffect(() => {
        if (activeStageId) {
            getTheories(activeStageId)
        }
    }, [activeStageId])
    useEffect(() => {
        if (stages?.length > 0)
            fetchCurrentQuest()
    }, [stages])
    return (
        <motion.div
            className="parent_lesson"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <div
                className={fullScreen
                    ? "progress_bar hide"
                    : "progress_bar"
                }
            >
                {stages?.length > 0 &&
                    <div className="quest_name">
                        <span>{stages[0].quest_name}</span>
                    </div>
                }

                <div className={"progress_header"}>
                    <div
                        className={
                            progress.progress > 60
                                ? " title fiolet"
                                : progress.progress > 20
                                    ? "title yellow"
                                    : "title red"
                        }
                    >
                        {gradeIdentifyer(progress.progress)[1]}
                        {progress.progress < 10 &&
                            <Text>{variable_1}</Text>
                        }
                    </div>
                    <div className={"group"}>
                        <div className={"procentague"}>
                            {progress.progress}%
                        </div>
                        <Medal_
                            className={progress.progress > 60
                                ? "fiolet"
                                : progress.progress > 20
                                    ? "yellow"
                                    : "red"
                            }
                        />
                        <Progress
                            className={"progress_antd"}
                            showInfo={false}
                            percent={progress.progress}
                            strokeColor={defaultFuncAndData.calcColorPercentProgressCourse(progress.progress).color}
                            trailColor="#757A88"
                            width="100%"
                        />
                    </div>
                </div>
                <Collapse
                    accordion
                    ghost
                    defaultActiveKey={0}
                    className={"collapse_antd"}
                    onChange={(value) => getTheories(value, null)}
                    expandIconPosition="end"
                    expandIcon={(v) => {
                        let indx = stages.findIndex(x => x.available && x.id === Number(v.panelKey));
                        let indxLoader = stages.findIndex(x => x.id === Number(v.panelKey));
                        if (indx !== -1 && stages[indx].available && (stages[indx].count_all !== stages[indx].count_user)) {
                            return <Arrow
                                className={cn("active", {
                                    open: v.isActive,
                                    marginR: stages[indxLoader]?.generation_in_progress
                                })}
                            />;
                        }
                        return (v.collapsible === "disabled")
                            ? <Arrow
                                className={cn("disabled", {
                                    marginR: stages[indxLoader]?.generation_in_progress
                                })}
                            />
                            : <Arrow
                                className={cn("", {
                                    open: v.isActive,
                                    marginR: stages[indxLoader]?.generation_in_progress
                                })}
                            />;
                    }}
                >
                    {(showCertificate() === 100) &&
                        <div
                            className="certificate"
                            onClick={() => setOpenModal(true)}
                        >
                            <span>{variable_63}</span>
                        </div>
                    }
                    {stages.map((stage, i) =>
                        <>
                            <Panel
                                header={stage.name}
                                key={stage.id}
                                disabled={!checkCourseEnabled(stages, i, stage.available)}
                                extra={genExtra(stage.count_all, stage.count_user, stage.available, stage)}
                            >
                                {(stage?.stage_theories?.length > 0) &&
                                    <>
                                        {stage.stage_theories.map((elem, indx) =>
                                            <>
                                                <div
                                                    className={activeTheoryNum === indx ? "stage_item active" : "stage_item"}
                                                    onClick={() => {
                                                        activate(indx);
                                                        handleClick();
                                                        setUserCode('');
                                                    }}
                                                    key={indx}
                                                >
                                                    <div className={getCurrentColorIcon(elem, indx)}>
                                                        {getTheoryIcon(elem.type, elem.seen, elem?.task_type)}
                                                    </div>
                                                    <Tooltip
                                                        placement="top"
                                                        title={elem.title}
                                                    >
                                                        <div className="text">
                                                            {defaultFuncAndData.spliceStr(elem.title, 50)}
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                                <div
                                                    className={indx === stage.stage_theories.length - 1
                                                        ? "line hide"
                                                        : "line"
                                                    }
                                                />
                                            </>
                                        )}
                                    </>
                                }
                                {stage?.generation_in_progress &&
                                    <div className="loading_matherial">
                                        <div className="text">
                                            {language === "ru"
                                                ? <> Идёт загрузка материала.<br/>
                                                    Можете обновить страницу...
                                                </>
                                                : <>
                                                    Loading content.<br/>
                                                    You can refresh the page...
                                                </>
                                            }
                                        </div>
                                        <Spin/>
                                    </div>
                                }
                            </Panel>
                        </>
                    )
                    }
                    {(openModal || (progress?.progress === 100 && oldProgress !== null && oldProgress < 100))
                        && currentQuest?.organization_id === 481 && !currentQuest?.certificate &&
                        <ModalCertificate
                            setOpenModal={setOpenModal}
                            handleGenerateCertificate={handleGenerateCertificate}
                            currentQuest={currentQuest}
                            setOldProgress={setOldProgress}
                        />
                    }
                </Collapse>
            </div>
            <div
                className={fullScreen
                    ? "right_content full_screen"
                    : "right_content"
                }
            >
                {(stageItems?.length > 0 && typeof stageItems[activeTheoryNum] !== "undefined") &&
                    <>
                        {stageItems[activeTheoryNum].task_type === "code" &&
                            <EditorCode
                                stageItems={stageItems}
                                activeTheoryNum={activeTheoryNum}
                                taskProps={stageItems[activeTheoryNum]}
                                setFullScreen={() => setFullScreen(!fullScreen)}
                                userCode={userCode}
                                setUserCode={setUserCode}
                                user={user}
                                flagIsArchived={flagIsArchived}
                            />
                        }
                        {stageItems[activeTheoryNum].type === "LMS" &&
                            <Scorm
                                taskProps={stageItems[activeTheoryNum]}
                                setTriggerLmsEnd={setTriggerLmsEnd}
                            />
                        }
                        {(stageItems[activeTheoryNum].task_type !== "code" &&
                                stageItems[activeTheoryNum].type !== "LMS") &&
                            <WebContent
                                {...stageItems[activeTheoryNum]}
                                showComments={currentQuest?.show_comments}
                            />
                        }
                    </>
                }
                {(stages?.length > 0 && stageItems.length === 0) &&
                    <div className="no_data">
                        <span>Идёт загрузка материалов...</span>
                        <Spin style={{position: "absolute", left: "50%", top: "52%"}}/>
                    </div>
                }
                {flagErrorFetch &&
                    <div className="error_fetch">
                        <img src={test_result_wrong} alt="error"/>
                        <span>Произошла ошибка при загрузке курса</span>
                    </div>
                }
            </div>
            <div className="content_mobile">
                {activePage === 0 &&
                    <StagesPage
                        progress={progress}
                        gradeIdentifyer={gradeIdentifyer}
                        stages={stages}
                        matherials={stageItems}
                        activeMatherial={activeTheoryNum}
                        getCurrentColorIcon={getCurrentColorIcon}
                        getTheoryIcon={getTheoryIcon}
                        activate={activate}
                        handleClick={handleClick}
                        setUserCode={setUserCode}
                        setActivePage={setActivePage}
                        activeStageId={activeStageId}
                        setActiveStageId={setActiveStageId}
                    />
                }
                {activePage === 1 &&
                    <ContentPage
                        {...stageItems[activeTheoryNum]}
                        setActivePage={setActivePage}
                        getTheories={getTheories}
                        stageId={stageId}
                        matherials={stageItems}
                        activeMatherial={activeTheoryNum}
                        activate={activate}
                        showComments={currentQuest?.show_comments}
                    />
                }
            </div>
        </motion.div>
    );
};

export default Lesson;
