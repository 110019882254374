import React from 'react';
import "./Button.scss";
import cn from "classnames";

const Button = (props) => {
    const {
        text, click, maxWidth, margin, padding, backgroundColor,
        boxShadow, keyDown, tabIndex, zIndex, refProps, border,
        borderRadius, width = "", disabled = false, htmlType = undefined,
        className = "",
    } = props;

    return (
        <button
            className={cn(`button ${className}`, {
                disabled
            })}
            ref={refProps}
            onClick={(e) => {
                if (typeof click === "function" && !disabled)
                    click(e)
            }}
            onKeyDown={(event) => {
                if (typeof keyDown !== "undefined")
                    keyDown(event)
            }}
            tabIndex={tabIndex ? 0 : undefined}
            style={{
                margin: margin || "",
                padding: padding || "11px 12px 12px 12px",
                width: width || "100%",
                maxWidth: maxWidth || "100%",
                boxShadow: boxShadow || "0 9px 20px rgba(48, 119, 255, 0.45)",
                backgroundColor: backgroundColor || "#3077FF",
                zIndex: zIndex || 0,
                border: border,
                borderRadius
            }}
            type={htmlType}
        >
            <div className="text_in">{text}</div>
        </button>
    );
};

export default Button;