import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import requests from "../../axios/requests";
import { motion } from "framer-motion/dist/framer-motion";
import { setUserTrajectory } from "../../redux/actions/userActions";
import { defaultFuncAndData } from "../../../utils/defaultData";
import { setScrollUp, setUseFilterSkills } from "../../redux/actions/actions";

import { Modal, Pagination, Spin } from "antd";
import { CourseCard } from "../../components/CourseCard";
import variableLanguage from '../../../utils/hookGetVariablesLanguage';
import Button from "../../components/Button/Button";
import CustomCheckbox from "../../components/CustomCheckbox";
import HeaderNavigation from "../../components/HeaderNavigation/HeaderNavigation";

import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { ReactComponent as Logo } from "../../../images/icons/logo.svg";
import close_tag from "../../../images/icons/close_tag.svg";
import close_modal_course from "../../../images/icons/close_modal-course.svg";
import close_modal_grey from "../../../images/icons/close_modal-course.svg";
import search_icon from "../../../images/icons/search_icon.svg";
import search_mobile from "../../../images/icons/search_filter.svg";
import settings_mobile from "../../../images/icons/settings_mobile.svg";
import settings_mobile_category from "../../../images/icons/settings_mobile-category.svg";
import study_traektory from "../../../images/icons/study_traektory.svg";
import Input from "../../components/Input/Input";
import Tag from "../../components/Tag/Tag";

const ReuseDots = ({activeSlide, setActiveSlide, countDots, color = false}) => {
    return (
        <div className="block_dots">
            {Array(countDots).fill(0).map((elem, indx) =>
                <div
                    key={indx}
                    onClick={() => setActiveSlide(indx)}
                    style={activeSlide === indx
                        ? {
                            width: 30,
                            height: 4,
                            background: color || "#A1A3B2",
                            borderRadius: 3,
                            marginRight: 8,
                            transition: "all .3s ease",
                            cursor: "pointer"
                        }
                        : {
                            backgroundColor: "transparent",
                            width: 20,
                            height: 4,
                            background: "#343843",
                            borderRadius: 3,
                            marginRight: 8,
                            transition: "all .3s ease",
                            cursor: "pointer"
                        }
                    }/>
            )}
        </div>
    );
};
const CourseListIsAuth = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const {trajectorySkills, useFilterSkills} = useSelector(state => state.Reducer);

    const variables_1 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_1'})
    const variables_2 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_2'})
    const variables_5 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_5'})
    const variables_6 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_6'})
    const variables_7 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_7'})
    const variables_8 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_8'})
    const variables_9 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_9'})
    const variables_10 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_10'})
    const variables_11 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_11'})
    const variables_13 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_13'})
    const variables_14 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_14'})
    const variables_24 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_24'})
    const variables_25 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_25'})
    const variables_26 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_26'})
    const variables_28 = variableLanguage({keyPage: 'courseList', keyVariable: 'variables_28'})
    const variables__8 = variableLanguage({keyPage: 'searchProfession', keyVariable: 'variables_8'})
    const variables__27 = variableLanguage({keyPage: 'searchProfession', keyVariable: 'variables_27'})
    const variables__28 = variableLanguage({keyPage: 'searchProfession', keyVariable: 'variables_28'})

    const [actualList, setActualList] = useState([]);
    const [actualCurrent, setActualCurrent] = useState(null);
    const [traektoryIsClose, setTraektoryIsClose] = useState(true);
    const [showSearchMobile, setShowSearchMobile] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeCategory, setActiveCategory] = useState(0);
    const [activeSortCatalog, setActiveSortCatalog] = useState(1);
    const [activeSlideActual, setActiveSlideActual] = useState(0);
    const [activeSearchSkill, setActiveSearchSkill] = useState([]);
    const [originalCatalog, setOriginalCatalog] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [muted, setMuted] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [countTimeout, setCountTimeout] = useState(null);
    const [closeModalTimeout, setCloseModalTimeout] = useState(null);
    const [activeLoader, setActiveLoader] = useState(false);
    const [mount, setMount] = useState(false);
    const [skillMatches, setSkillMatches] = useState([]);
    const [timeoutFetch, setTimeoutFetch] = useState(null);

    const [flagDropDownCategory, setFlagDropDownCategory] = useState(false);
    const [flagDropDownSort, setFlagDropDownSort] = useState(false);
    const [flagModalActual, setFlagModalActual] = useState(false);
    const [flagModalSettingsMobile, setFlagModalSettingsMobile] = useState(null);
    const [flagPaid, setFlagPaid] = useState(false);

    const sortCatalog = [
        variableLanguage({keyPage: 'courseList', keyVariable: 'variables_2'}),
        variableLanguage({keyPage: 'courseList', keyVariable: 'variables_3'}),
        variableLanguage({keyPage: 'courseList', keyVariable: 'variables_4'})
    ];
    const arrCategory = [
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_49'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_36'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_31'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_33'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_30'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_32'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_35'})}</span>,
        <span>{variableLanguage({keyPage: 'authorization', keyVariable: 'variables_34'})}</span>
    ];

    const countPageSize = 20;
    const refMain = useRef(null);
    const refSlidesActual = useRef(null);

    let handleCloseModal = () => {
        setFlagModalActual(false);
        setActiveSlideActual(0);
    };
    let getColorTag = (name) => {
        for (let i = 0; i < activeSearchSkill.length; i++)
            if (activeSearchSkill[i].name === name)
                return true;
        return false;
    };
    let transformToArrTags = (arr) => {
        let result = [];
        if (arr && arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                result = [...result, arr[i].id];
            }
        }
        return result;
    };
    let getApiCatalog = (arr) => {
        setLoading(true);
        if (timeoutFetch)
            clearTimeout(timeoutFetch)
        const ordering =
            activeSortCatalog === 1
                ? "-referral_url,price,name"
                : activeSortCatalog === 2
                    ? "-referral_url,dateBegin,name"
                    : "";
        const fetch = () =>
            requests.quests.get_quest_quest(
                arr || [], currentPage, countPageSize, activeCategory,
                searchValue, "", flagPaid, ordering, true
            )
                .then(res => {
                    setOriginalCatalog(res.data.results)
                    setTotal(res.data.count)
                })
                .catch((error) => {
                    console.error(error)
                    setTotal(0)

                })
                .finally(() => setLoading(false));
        setTimeoutFetch(
            setTimeout(() => fetch(), 300)
        )
    };
    let getApiActual = () => {
        requests.quests.get_actual_quest()
            .then(res => {
                let newArr = [...res.data]
                if (newArr && newArr.length > 0) {
                    for (let i = 0; i < newArr.length; i++) {
                        if (!newArr[i].demo_video && !newArr[i].demo_youtube && !newArr[i].y_image) {
                            newArr.splice(i, 1);
                        }
                    }
                    setActualList(newArr)
                }
            })
            .catch(err => console.error(err))
    }
    let func = (indx = 0) => {
        if (countTimeout)
            clearTimeout(countTimeout)
        if (closeModalTimeout)
            clearTimeout(closeModalTimeout)

        for (let i = 0; i < actualList.length - indx; i++) {
            setCountTimeout(
                setTimeout(() => {
                    setCurrentSlide(indx + i)
                    setActiveSlideActual(indx + i)
                    setTimeout(() => {
                        setActiveLoader(true);
                    }, 10)
                    if (i === (actualList.length - indx - 1)) {
                        setCloseModalTimeout(
                            setTimeout(() => {
                                setCountTimeout(null);
                                setFlagModalActual(false);
                            }, 7000)
                        )
                    }
                }, i * 7000)
            );
        }
    }
    let getApiCatalogWithValidate = () => {
        if (traektoryIsClose) {
            getApiCatalog();
        } else {
            if (activeSearchSkill && activeSearchSkill.length > 0)
                getApiCatalog(transformToArrTags(activeSearchSkill));
            else {
                if (trajectorySkills && trajectorySkills.length > 0)
                    getApiCatalog(transformToArrTags(trajectorySkills));
                else
                    getApiCatalog();
            }
        }
    }

    useEffect(() => {
        setMount(true)
        getApiActual();
        return () => {
            if (countTimeout)
                clearTimeout(countTimeout)
        }
    }, []);
    useEffect(() => {
        setTimeout(() => {
            setActiveLoader(false);
        }, 10)
        setActualCurrent(actualList[currentSlide]);
    }, [currentSlide])
    useEffect(() => {
        if (mount)
            getApiCatalogWithValidate()
    }, [searchValue, activeCategory, activeSearchSkill, flagPaid, activeSortCatalog])
    useEffect(() => {
        dispatch(setScrollUp(true)); // проскорлить вверх
        getApiCatalogWithValidate()
    }, [currentPage])
    useEffect(() => {
        if (refSlidesActual.current?.slickGoTo)
            refSlidesActual.current.slickGoTo(activeSlideActual);
        if (!activeLoader)
            setActiveLoader(true);
        if (activeLoader)
            setActiveLoader(false);
    }, [activeSlideActual]);
    useEffect(() => {
        if (originalCatalog.length > 0 && trajectorySkills.length > 0 && !traektoryIsClose) {
            let coursesTags = [];
            let trajectoryTags = [];
            for (let i = 0; i < originalCatalog.length; i++) {
                for (let j = 0; j < originalCatalog[i].speciality_tag.length; j++) {
                    coursesTags = [...coursesTags, originalCatalog[i].speciality_tag[j].name]
                }
            }
            for (let i = 0; i < trajectorySkills.length; i++) {
                trajectoryTags = [...trajectoryTags, trajectorySkills[i].name]
            }
            setSkillMatches(coursesTags.filter(tag => trajectoryTags.includes(tag)))
        }
        if (traektoryIsClose)
            setSkillMatches([])

    }, [originalCatalog, trajectorySkills, traektoryIsClose])
    useEffect(() => {// При переходе из скилсета по кнопке "подобрать курсы"
        if (useFilterSkills && originalCatalog.length > 0 && trajectorySkills.length > 0) {
            dispatch(setUseFilterSkills(false))
            setOriginalCatalog([]);
            setTraektoryIsClose(false);
            setCurrentPage(1)
            getApiCatalog(transformToArrTags(trajectorySkills));
        }
    }, [useFilterSkills, originalCatalog, trajectorySkills])
    return (
        <motion.div
            className="course_list"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <div className="top_block-mobile">
                <div className={"top_filter"}>
                    <div className="icon">
                        <Logo
                            width={38}
                            height={38}
                            onClick={() => window.open("https://skillometer.ru/", 'blank')}
                        />
                    </div>
                    <div className="img">
                        <img
                            src={search_mobile}
                            className={showSearchMobile ? "btn_search hide" : "btn_search"}
                            onClick={() => setShowSearchMobile(true)}
                        />
                    </div>
                    <div
                        className={showSearchMobile
                            ? "search active"
                            : "search"}
                    >
                        <Input
                            padding={"3px 0 5px 40px"}
                            marginParent={"0 0 0 10px"}
                            value={searchValue}
                            setValue={setSearchValue}
                            backgroundColor={"transparent"}
                            needFindIconMobile={true}
                            zIndex={4}
                        />
                        <span
                            onClick={() => {
                                setShowSearchMobile(false);
                                setSearchValue("");
                            }}
                        >
                           {variables_13}
                        </span>
                    </div>
                    <div
                        className="settings"
                        onClick={() => setFlagModalSettingsMobile(true)}
                    >
                        <img src={settings_mobile}/>
                    </div>
                </div>
            </div>
            <HeaderNavigation
                activeNavigation={0}
                setActiveNavigation={(index) => {
                    if (index === 1) history("/student/my-courses");
                }}
                title={variables_1}
                textVariable_1={variables_5}
                textVariable_2={variables_6}
            />
            <div className="sort">
                <div className={"search"}>
                    <Input
                        padding={"5px 96px 7px 46px"}
                        value={searchValue}
                        setValue={setSearchValue}
                        backgroundColor={"transparent"}
                        maxWidthParent={"420px"}
                        widthParent={"100%"}
                        needFindBtn={true}
                        placeholder={variables_24}
                    />
                </div>
                <div className="category">
                    <span>{variables_8}</span>
                    <div
                        className={flagDropDownSort ? "select_block active" : "select_block"}
                        onClick={() => setFlagDropDownSort(!flagDropDownSort)}
                    >
                            <span style={{color: "#B3DAFF"}}>
                                {sortCatalog[activeSortCatalog]}
                            </span>
                        <Arrow/>
                        <ul
                            className={flagDropDownSort
                                ? "drop_down-category active"
                                : "drop_down-category"
                            }
                        >
                            {sortCatalog.map((elem, indx) =>
                                <li
                                    key={indx}
                                    onClick={() => {
                                        setActiveSortCatalog(indx);
                                        setFlagDropDownSort(false);
                                    }}
                                >
                                    {elem}
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="category">
                    <span>{variables_9}</span>
                    <div
                        className={flagDropDownCategory ? "select_block active" : "select_block"}
                        onClick={() => setFlagDropDownCategory(!flagDropDownCategory)}
                    >
                            <span style={{color: "#B3DAFF"}}>
                                {arrCategory[activeCategory]}
                            </span>
                        <Arrow/>
                        <ul className={flagDropDownCategory ? "drop_down-category active" : "drop_down-category"}>
                            {arrCategory.map((elem, indx) =>
                                <li
                                    key={indx}
                                    onClick={() => {
                                        setActiveCategory(indx);
                                        setFlagDropDownCategory(false);
                                    }}
                                >
                                    {elem}
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className="checkbox_paid">
                        <span onClick={() => setFlagPaid(!flagPaid)}>
                            {variables_26}:
                        </span>
                    <CustomCheckbox
                        value={flagPaid}
                        setValue={(e) => setFlagPaid(e)}
                        label={""}
                        id={"paid"}
                    />
                </div>
            </div>
            {actualList.length > 0 &&
                <>
                    <div className="actual_title">
                        <span>{variables_10}</span>
                    </div>
                    <div className="actual_list">
                        <ul>
                            {actualList.map((elem, indx) =>
                                <li
                                    key={indx}
                                    onClick={() => {
                                        setActualCurrent(actualList[indx])
                                        setFlagModalActual(true)
                                        func(indx);
                                    }}
                                >
                                    {elem.y_image &&
                                        <img src={elem.y_image}/>
                                    }
                                </li>
                            )}
                        </ul>
                    </div>
                </>
            }
            <div className="traektory_title">{variables__8}</div>
            <div className="parent_traektory">
                {traektoryIsClose
                    ? <Button
                        text={variables__27}
                        click={() => {
                            setOriginalCatalog([]);
                            setTraektoryIsClose(false);
                            setCurrentPage(1)
                            getApiCatalog(transformToArrTags(trajectorySkills));
                        }}
                        padding={"5px"}
                    />
                    : <>
                        <div className="traektory">
                            {trajectorySkills.map((elem) =>
                                <Tag
                                    elem={elem}
                                    border={skillMatches.includes(elem.name)}
                                    colorActive={getColorTag(elem.name)}
                                    cursorPointer={true}
                                    removeTag={() => {
                                        let newArr;
                                        for (let i = 0; i < trajectorySkills.length; i++) {
                                            if (trajectorySkills[i].name === elem.name) {
                                                newArr = trajectorySkills.filter(t => t.name !== elem.name);
                                                break;
                                            }
                                        }
                                        dispatch(setUserTrajectory(newArr))
                                        requests.quests.remove_trajectory_skill({name: elem.name})
                                            .catch((e) => console.error(e));
                                    }}
                                    click={() => {
                                        let newArr = [...activeSearchSkill];
                                        for (let i = 0; i < activeSearchSkill.length; i++) {
                                            if (activeSearchSkill[i].name === elem.name) {
                                                newArr = newArr.filter(el => el.name !== activeSearchSkill[i].name);
                                                return setActiveSearchSkill(newArr);
                                            }
                                        }
                                        newArr = [...newArr, elem];
                                        setActiveSearchSkill(newArr);
                                    }}
                                />
                            )}
                        </div>
                        <div
                            className="btn_bottom"
                            onClick={() => {
                                setTraektoryIsClose(true);
                                setActiveSearchSkill([]);
                                setOriginalCatalog([]);
                            }}
                        >
                            {variables_11}
                        </div>
                    </>
                }
            </div>
            <div className="catalog">
                <ul>
                    {(originalCatalog && originalCatalog.length > 0) &&
                        originalCatalog.map((course, indx) => {
                            return (
                                <li key={indx} className={"reuse_course-item"}>
                                    <CourseCard
                                        course={course}
                                        dateCourse={true}
                                        cost={true}
                                        redirectUrl={course.id}
                                        isCourseList={true}
                                        skillMatches={skillMatches}
                                    />
                                </li>
                            );
                        })
                    }
                </ul>
                {total && total > countPageSize
                    ? <div className="pagination_all">
                        <Pagination
                            itemRender={defaultFuncAndData.itemRenderPagination}
                            defaultCurrent={1}
                            defaultPageSize={countPageSize}
                            total={total}
                            onChange={(e) => setCurrentPage(e)}
                            showTitle={false}
                        />
                    </div>
                    : <></>
                }
                {loading &&
                    <Spin style={{position: "absolute"}}/>
                }

            </div>
            {(flagDropDownCategory || flagDropDownSort) &&
                <div
                    className="overlay"
                    onClick={() => {
                        setFlagDropDownCategory(false);
                        setFlagDropDownSort(false);
                    }}
                />
            }
            <>
                {flagModalActual &&
                    <Modal
                        open={flagModalActual}
                        footer={null}
                        className={"modal_actual"}
                        closable={true}
                        closeIcon={
                            <div className="close" onClick={handleCloseModal}>
                                <img src={close_modal_course}/>
                            </div>
                        }
                        centered={true}
                        onCancel={handleCloseModal}
                        maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                        bodyStyle={{backgroundColor: "transparent", position: 'relative'}}
                    >
                        {actualCurrent !== null && ((actualCurrent?.demo_video || actualCurrent?.demo_youtube) || (actualCurrent?.y_image)) &&
                            <>
                                <div className="parent_loader" key={currentSlide}>
                                    <div
                                        className={activeLoader ? "loader active" : "loader "}
                                    />
                                </div>
                                {(actualCurrent?.demo_video || actualCurrent?.demo_youtube)
                                    ? <div className="show_video">
                                        <div className="video">
                                            <ReactPlayer
                                                className='react-player'
                                                style={{marginBottom: 15, borderRadius: 30}}
                                                controls={true}
                                                onReady={(e) => {
                                                    setPlaying(true)
                                                    setMuted(false)
                                                }}
                                                muted={muted}
                                                onPlay={() => setPlaying(true)}
                                                playing={playing}
                                                url={actualCurrent?.demo_youtube ? actualCurrent?.demo_youtube : actualCurrent?.demo_video}
                                            />
                                        </div>
                                    </div>
                                    : <div className="show_image">
                                        <img src={actualCurrent?.y_image}/>
                                    </div>
                                }
                                <Button
                                    text={variables_25}
                                    click={() => history("/student/course-preview/" + actualCurrent.ses)}
                                    padding={"6px 12px"}
                                    margin={"15px auto 0 auto"}
                                    maxWidth={"fit-content"}
                                />
                            </>
                        }

                        <ReuseDots
                            activeSlide={activeSlideActual}
                            setActiveSlide={(indx) => {
                                setActiveSlideActual(indx);
                                setActualCurrent(actualList[indx]);
                                setTimeout(() => {
                                    setActiveLoader(false);
                                }, 6)
                                if (countTimeout)
                                    clearTimeout(countTimeout);
                                if (closeModalTimeout)
                                    clearTimeout(closeModalTimeout)
                                for (let i = 0; i < actualList.length - indx; i++) {
                                    setCountTimeout(
                                        setTimeout(() => {
                                            setCurrentSlide(indx + i)
                                            setActiveSlideActual(indx + i)
                                            setTimeout(() => {
                                                setActiveLoader(true);
                                            }, 10)
                                        }, i * 7000)
                                    );
                                    if (i === (actualList.length - indx - 1)) {
                                        setCloseModalTimeout(
                                            setTimeout(() => {
                                                setCountTimeout(null);
                                                setFlagModalActual(false);
                                            }, i ? (i + 1) * 7000 : 7000)
                                        )
                                    }
                                }
                            }}
                            countDots={actualList?.length}
                        />
                    </Modal>
                }
            </>
            <>
                <Modal
                    open={flagModalSettingsMobile}
                    footer={null}
                    className={"modal_settings-mobile"}
                    // width={"auto"}
                    closable={false}
                    centered={true}
                    onCancel={() => setFlagModalSettingsMobile(false)}
                    maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                    bodyStyle={{padding: 15, backgroundColor: "#343843", borderRadius: 16}}
                >
                    <div className="title">
                        <div className="group_left">
                            <img src={settings_mobile_category}/>
                            <span>{variables_9}</span>
                        </div>
                        <div
                            className="close"
                            onClick={() => setFlagModalSettingsMobile(false)}
                        >
                            <img src={close_modal_grey} alt=""/>
                        </div>
                    </div>
                    <div className="list">
                        {arrCategory.map((elem, indx) =>
                            <div
                                onClick={() => {
                                    setActiveCategory(indx);
                                    setFlagModalSettingsMobile(false);
                                }}
                                key={indx}
                                style={activeCategory === indx ? {color: "rgb(179, 218, 255)"} : {}}
                            >
                                {elem}
                            </div>
                        )}
                    </div>
                    <div className="title" style={{marginTop: 20}}>
                        <div className="group_left">
                            <img src={settings_mobile_category}/>
                            <span>{variables_8}</span>
                        </div>
                    </div>
                    <div
                        className="list"
                        style={{minHeight: "initial"}}
                    >
                        {sortCatalog.map((elem, indx) =>
                            <div
                                onClick={() => {
                                    setActiveSortCatalog(indx);
                                    setFlagModalSettingsMobile(false);
                                }}
                                key={indx}
                            >
                                        <span style={activeSortCatalog === indx ? {color: "rgb(179, 218, 255)"} : {}}>
                                            {elem}
                                        </span>
                            </div>
                        )}
                    </div>
                    <div className="title" style={{marginTop: 20}}>
                        <div className="group_left">
                            <img src={settings_mobile_category}/>
                            <span>{variables_28}</span>
                        </div>
                    </div>
                    <div
                        className="list"
                        style={{minHeight: "initial"}}
                    >

                        <div
                            onClick={() => {
                                setFlagPaid(false)
                                setFlagModalSettingsMobile(false);
                            }}
                        >
                                        <span
                                            style={!flagPaid
                                                ? {color: "rgb(179, 218, 255)"}
                                                : {}
                                            }
                                        >
                                            {variables_2}
                                        </span>
                        </div>
                        <div
                            onClick={() => {
                                setFlagPaid(true)
                                setFlagModalSettingsMobile(false);
                            }}
                        >
                                        <span
                                            style={flagPaid
                                                ? {color: "rgb(179, 218, 255)"}
                                                : {}
                                            }
                                        >
                                            {variables_26}
                                        </span>
                        </div>
                    </div>
                </Modal>
            </>
            <>
                <Modal
                    open={!traektoryIsClose && window.innerWidth < 820}
                    footer={null}
                    className={"modal_traektory-mobile"}
                    // width={"auto"}
                    closable={false}
                    // centered={true}
                    onCancel={() => setTraektoryIsClose(true)}
                    maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                    bodyStyle={{padding: 15, backgroundColor: "#27292F", borderRadius: 20}}
                >
                    <div className="title" style={{marginBottom: 17}}>
                        <img src={study_traektory}/>
                        <span>{variables__8}</span>
                    </div>
                    {/* <div className="h3">
                                Мои навыки
                            </div>
                            <div className="block_traektory">
                                {trajectorySkills && trajectorySkills.length > 0 &&
                                    trajectorySkills.filter(t => t.status !== "added").map((elem, indx) =>
                                        <div className={"tag"} key={indx}>{elem.name || elem.title}</div>
                                    )
                                }
                            </div>*/}
                    <div className="h3">
                        {variables_14}
                    </div>
                    <div className="block_traektory">
                        {trajectorySkills.map((elem, indx) =>
                            <div
                                key={indx}
                                className="tag"
                                onClick={() => {
                                    let newArr = [...activeSearchSkill];
                                    for (let i = 0; i < activeSearchSkill.length; i++) {
                                        if (activeSearchSkill[i].name === elem.name) {
                                            newArr = newArr.filter(el => el.name !== activeSearchSkill[i].name);
                                            return setActiveSearchSkill(newArr);
                                        }
                                    }
                                    newArr = [...newArr, elem];
                                    setActiveSearchSkill(newArr);
                                }}
                                style={getColorTag(elem.name) ?
                                    {
                                        background: "rgba(48, 224, 161, 0.2)",
                                        border: "1px solid #30E0A1",
                                        color: "#30E0A1"
                                    }
                                    : {}
                                }
                            >
                                <span>{elem.name}</span>
                                <img
                                    onClick={() => {
                                        let newArr;
                                        for (let i = 0; i < trajectorySkills.length; i++) {
                                            if (trajectorySkills[i].name === elem.name) {
                                                newArr = trajectorySkills.filter(t => t.name !== elem.name);
                                                break;
                                            }
                                        }
                                        dispatch(setUserTrajectory(newArr))
                                        /*setTagsTrajectory(newArr);*/
                                        requests.quests.remove_trajectory_skill({name: elem.name})
                                            .then(() => {
                                                if (trajectorySkills && trajectorySkills.length > 1)
                                                    getApiCatalog(transformToArrTags(trajectorySkills));
                                                else
                                                    getApiCatalog(false);
                                            })
                                            .catch((e) => {
                                                console.log(e, "error remove_trajectory_skill ");
                                            });
                                    }}
                                    src={close_tag}
                                />
                            </div>
                        )}
                    </div>
                    <div
                        className="btn_bottom"
                        onClick={() => {
                            setTraektoryIsClose(true);
                            setOriginalCatalog([]);
                            getApiCatalog(false, currentPage, countPageSize, activeCategory);
                        }}
                    >{variables_11}
                    </div>
                    <div className="close"
                         onClick={() => setTraektoryIsClose(true)}>{variables__28}</div>
                </Modal>
            </>
        </motion.div>
    );
};

export default CourseListIsAuth;