import React from "react";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";

import DangerSkill from "./DangerSkill";
import { defaultFuncAndData } from "../../../../utils/defaultData";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import cn from "classnames";

const SkillBlockVacancy = (props) => {
    const {
        userSkills, needSkillsThisVacansy, addSkillTraektory, setCoordsTagAnimation, needAnimation
    } = props;

    let dataDanger = defaultFuncAndData.funcMissingsSkill(userSkills, needSkillsThisVacansy).missingSkills;
    let dataHave = defaultFuncAndData.funcMissingsSkill(userSkills, needSkillsThisVacansy).haveSkills;

    const variables_20_1 = variableLanguage({keyPage: 'searchProfession', keyVariable: 'variables_20'})?.part_1;
    const variables_20_2 = variableLanguage({keyPage: 'searchProfession', keyVariable: 'variables_20'})?.part_2;
    const variables_34 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_34"});
    return (
        <div className={cn("tags_block intro_")}
        >
            {dataHave && dataHave.length > 0 &&
                <>
                    <div className="h3">{variables_34}</div>
                    <div className="left_skill">
                        {dataHave.map((skill, indx) =>
                            <AnimatePresence key={indx}>
                                <motion.div
                                    initial={{opacity: 0, transform: "scale(0.5)"}}
                                    animate={{opacity: 1, transform: "scale(1)"}}
                                    transition={{delay: .6}}
                                >
                                    <div className={skill.status === "added" ? "skill unlearned" : "skill"}>
                                        {skill.name}
                                    </div>
                                </motion.div>
                            </AnimatePresence>
                        )
                        }
                    </div>
                </>
            }
            {typeof dataDanger !== 'undefined' &&
                <div className="h3">
                    {dataDanger.length < 1 ? variables_20_1 : variables_20_2}
                </div>
            }
            {typeof dataDanger !== 'undefined' && dataDanger.length !== 0 &&
                <DangerSkill
                    data={dataDanger}
                    addSkillTraektory={(skill) => addSkillTraektory(skill)}
                    setCoordsTagAnimation={setCoordsTagAnimation}
                    needAnimation={needAnimation}
                />
            }
            {typeof dataDanger === 'undefined' && needSkillsThisVacansy.length !== 0 &&
                <DangerSkill
                    data={needSkillsThisVacansy}
                    addSkillTraektory={(skill) => addSkillTraektory(skill)}
                    setCoordsTagAnimation={setCoordsTagAnimation}
                    needAnimation={needAnimation}
                />
            }
        </div>
    );
};

export default SkillBlockVacancy;