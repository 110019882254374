import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import requests from "../../axios/requests";
import { defaultFuncAndData } from "../../../utils/defaultData";

import { CourseCard } from "../../components/CourseCard";
import { Modal, Pagination, Spin } from "antd";
import { changeLanguage } from "../../redux/actions/authorizationActions";
import { useDispatch, useSelector } from "react-redux";
import variableLanguage from "../../../utils/hookGetVariablesLanguage";
import Input from "../../components/Input/Input";

import close_modal_course from "../../../images/icons/close_modal-course.svg";
import close_modal_grey from "../../../images/icons/close_modal-course.svg";
import slider_prev from "../../../images/landing/slider_prev.png";
import slider_next from "../../../images/landing/slider_next.png";
import search_icon from "../../../images/icons/search_icon.svg";
import nav_bar_icon from "../../../images/icons/logo.svg";
import search_mobile from "../../../images/icons/search_filter.svg";
import settings_mobile from "../../../images/icons/settings_mobile.svg";
import settings_mobile_category from "../../../images/icons/settings_mobile-category.svg";
import rus from "../../../images/country/russian_icon.png";
import usa from "../../../images/icons/usa.png";
import { ReactComponent as Arrow } from "../../../images/arrow.svg";

const ReuseDots = ({activeSlide, setActiveSlide, countDots, color = false}) => {
    return (
        <div className="block_dots">
            {Array(countDots).fill(0).map((elem, indx) =>
                <div
                    key={indx}
                    onClick={() => setActiveSlide(indx)}
                    style={activeSlide === indx
                        ? {
                            width: 30,
                            height: 4,
                            background: color || "#A1A3B2",
                            borderRadius: 3,
                            marginRight: 8,
                            transition: "all .3s ease",
                            cursor: "pointer"
                        }
                        : {
                            backgroundColor: "transparent",
                            width: 20,
                            height: 4,
                            background: "#343843",
                            borderRadius: 3,
                            marginRight: 8,
                            transition: "all .3s ease",
                            cursor: "pointer"
                        }
                    }/>
            )}
        </div>
    );
};
const PrevArrow = ({className, style, onClick}) => {
    return (
        <div
            className={className}
            style={{...style, display: "block"}}
            onClick={onClick}
        >
            <img src={slider_prev}/>
        </div>
    );
};
const NextArrow = ({className, style, onClick}) => {
    return (
        <div
            className={className}
            style={{...style, display: "block"}}
            onClick={onClick}
        >
            <img src={slider_next}/>
        </div>
    );
};

const CourseList = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const {language} = useSelector(state => state.Reducer);

    const variables_1 = variableLanguage({keyPage: "courseList", keyVariable: "variables_1"});
    const variables_2 = variableLanguage({keyPage: "courseList", keyVariable: "variables_2"});
    const variables_3 = variableLanguage({keyPage: "courseList", keyVariable: "variables_3"});
    const variables_4 = variableLanguage({keyPage: "courseList", keyVariable: "variables_4"});
    const variables_7 = variableLanguage({keyPage: "courseList", keyVariable: "variables_7"});
    const variables_8 = variableLanguage({keyPage: "courseList", keyVariable: "variables_8"});
    const variables_9 = variableLanguage({keyPage: "courseList", keyVariable: "variables_9"});
    const variables_10 = variableLanguage({keyPage: "courseList", keyVariable: "variables_10"});
    const variables_12 = variableLanguage({keyPage: "courseList", keyVariable: "variables_12"});
    const variables_13 = variableLanguage({keyPage: "courseList", keyVariable: "variables_13"});
    const variables_15 = variableLanguage({keyPage: "courseList", keyVariable: "variables_15"});
    const variables_16 = variableLanguage({keyPage: "courseList", keyVariable: "variables_16"});
    const variables_17 = variableLanguage({keyPage: "courseList", keyVariable: "variables_17"});
    const variables_18 = variableLanguage({keyPage: "courseList", keyVariable: "variables_18"});
    const variables_19 = variableLanguage({keyPage: "courseList", keyVariable: "variables_19"});
    const variables_20 = variableLanguage({keyPage: "courseList", keyVariable: "variables_20"});
    const variables_21 = variableLanguage({keyPage: "courseList", keyVariable: "variables_21"});
    const variables_22 = variableLanguage({keyPage: "courseList", keyVariable: "variables_22"});
    const variables_23 = variableLanguage({keyPage: "courseList", keyVariable: "variables_23"});

    const [showSearchMobile, setShowSearchMobile] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [activeCategory, setActiveCategory] = useState(0);
    const [activeSortCatalog, setActiveSortCatalog] = useState(1);
    const [activeSlideActual, setActiveSlideActual] = useState(0);
    const [originalCatalog, setOriginalCatalog] = useState([]);
    const [displayCourses, setDisplayCourses] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [actualList, setActualList] = useState(false);
    const [actualCurrent, setActualCurrent] = useState(false);
    const [activeLanguage, setActiveLanguage] = useState(null);
    const [activeImageLoaded, setActiveImageLoaded] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [muted, setMuted] = useState(true);
    const [page_size] = useState(20);

    const [flagDropDownCategory, setFlagDropDownCategory] = useState(false);
    const [flagDropDownSort, setFlagDropDownSort] = useState(false);
    const [flagModalActual, setFlagModalActual] = useState(false);
    const [flagModalSettingsMobile, setFlagModalSettingsMobile] = useState(null);
    const [flagDropdownLanguage, setFlagDropDownLanguage] = useState(false);

    const sortCatalog = [variables_2, variables_3, variables_4];
    const arrCategory = [
        <span>{variables_22}</span>,
        <span>{variables_21}</span>,
        <span>{variables_16}</span>,
        <span>{variables_18}</span>,
        <span>{variables_15}</span>,
        <span>{variables_17}</span>,
        <span>{variables_20}</span>,
        <span>{variables_19}</span>
    ];
    const arrSort = [
        <span>{variables_2}</span>,
        <span>{variables_3}</span>,
        <span>{variables_4}</span>
    ];
    const settingsSlider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: "slides_actual-course",
        prevArrow: <PrevArrow/>,
        nextArrow: <NextArrow/>,
        initialSlide: activeSlideActual
    };
    const refSlidesActual = useRef(null);
    const timeoutImage = useRef();
    const timeoutImage2 = useRef();
    let timeoutVideoVsImage;
    let timeoutVideoVsImage2;

    let handleCloseModal = () => {
        setFlagModalActual(false);
        setActiveImageLoaded(false);
        setActiveSlideActual(0);
    };
    let searchCourse = (e = "", isMobile = false) => {
        if (isMobile) {
            let newArray = originalCatalog.filter(p => p.name.includes(e));
            return setDisplayCourses(newArray);
        }
        if (!searchValue) {
            return setDisplayCourses(originalCatalog);
        }
        let newArray = originalCatalog.filter(p => p.name.includes(searchValue));
        setDisplayCourses(newArray);
    };
    let handleSearchCourseKeyDown = (e) => {
        if (e.keyCode === 13)
            searchCourse();
    };
    let getApiCatalog = () => {
        setLoading(true);
        const ordering =
            activeSortCatalog === 1
                ? "-referral_url,price,name"
                : activeSortCatalog === 2
                    ? "-referral_url,dateBegin,name"
                    : "";
        requests.quests.get_quest_quest([], currentPage, page_size, activeCategory, "",
            "", false, ordering, true)
            .then(res => {
                setOriginalCatalog(res.data.results)
                setTotal(res.data.count)
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    };
    let getApiActual = (ses = '') => {
        if (!ses) {
            requests.quests.get_actual_quest()
                .then(res => {
                    let newArr = [...res.data]
                    if (newArr && newArr.length > 0) {
                        for (let i = 0; i < newArr.length; i++) {
                            if (!newArr[i].demo_video && !newArr[i].demo_youtube && !newArr[i].y_image) {
                                newArr.splice(i, 1);
                            }
                        }
                        setActualList(newArr)
                    }
                })
                .catch(err => console.error(err))
        }

        if (ses) {
            requests.quests.get_actual_quest(ses)
                .then(res => {
                    setActualCurrent(res.data)
                    setFlagModalActual(true)
                    if (!res.data.demo_video && !res.data.demo_youtube) {
                        setTimeout(() => {
                            setActiveImageLoaded(true)
                        }, 50)
                        timeoutImage.current = window.setTimeout(() => setFlagModalActual(false), 5000);
                        timeoutImage2.current = window.setTimeout(() => setActiveImageLoaded(false), 5000);
                    }
                })
                .catch(err => console.error(err))
        }
    }

    useEffect(() => {
        getApiActual()
    }, []);
    useEffect(() => {
        setDisplayCourses(originalCatalog);
    }, [originalCatalog]);
    useEffect(() => {
        getApiCatalog()
    }, [activeSortCatalog, activeCategory, currentPage]);
    useEffect(() => {
        setActiveLanguage(language === "en" ? 1 : 0);
        if (language)
            localStorage.setItem("language", language);
    }, [language]);
    useEffect(() => {
        if (refSlidesActual.current?.slickGoTo)
            refSlidesActual.current.slickGoTo(activeSlideActual);
        if (activeSlideActual === 1) {
            timeoutVideoVsImage = window.setTimeout(() => setFlagModalActual(false), 4500);
            timeoutVideoVsImage2 = window.setTimeout(() => setActiveImageLoaded(false), 4500);
        }
    }, [activeSlideActual]);
    useEffect(() => {
        if (!flagModalActual) {
            setActiveSlideActual(0)
            setActiveImageLoaded(false);
            setPlaying(false)
            window.clearTimeout(timeoutVideoVsImage);
            window.clearTimeout(timeoutVideoVsImage2);
            window.clearTimeout(timeoutImage.current);
            window.clearTimeout(timeoutImage2.current);
        }
    }, [flagModalActual])
    useEffect(() => {
        return () => {
            window.clearTimeout(timeoutVideoVsImage);
            window.clearTimeout(timeoutVideoVsImage2);
        };
    })
    return (
        <div className={"course_list not_authenticated"}>
            <div className="group">
                <div className="top_block-mobile">
                    <div className={"top_filter"}>
                        <div className="icon">
                            <img src={nav_bar_icon}
                                 width={38}
                                 height={38}
                                 onClick={() => window.open("https://skillometer.ru/", 'blank')}
                            />
                        </div>
                        <img
                            src={search_mobile}
                            className={showSearchMobile ? "btn_search hide" : "btn_search"}
                            onClick={() => setShowSearchMobile(true)}
                        />
                        <div className={showSearchMobile ? "search active" : "search"}>
                            <Input
                                className={"input_classic"}
                                placeholder={variables_12}
                                value={searchValue}
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                    searchCourse(e.target.value, true);
                                }}
                                onKeyDown={(e) => handleSearchCourseKeyDown(e)}
                                onBlur={() => setShowSearchMobile(false)}
                            />
                            <img src={search_icon}/>
                            <span onClick={() => {
                                setShowSearchMobile(false);
                                setSearchValue("");
                                setDisplayCourses(originalCatalog);
                            }}
                            >
                                {variables_13}
                            </span>
                        </div>

                        <div
                            className="settings"
                            onClick={() => {
                                setFlagModalSettingsMobile(true);
                                /* setFlagHiddenFotter(false);*/
                            }}
                        >
                            <img src={settings_mobile}/>
                        </div>
                    </div>
                </div>
                <div className="top_block">
                    <div className="left">{variables_1}</div>
                    <div
                        className={flagDropdownLanguage ? "drop_down-language open" : "drop_down-language"}
                        tabIndex={0}
                        onClick={() => setFlagDropDownLanguage(!flagDropdownLanguage)}
                        onBlur={() => setFlagDropDownLanguage(false)}
                    >
                        {activeLanguage === 0
                            ? <img src={rus}/>
                            : <img src={usa}/>
                        }
                        <span> {["RU", "EN"][activeLanguage]}</span>
                        <Arrow/>
                        <div className={flagDropdownLanguage ? "drop_down open" : "drop_down"}>
                            {["RU", "EN"].map((elem, indx) =>
                                <div
                                    className="language"
                                    onClick={() => {
                                        dispatch(changeLanguage(indx === 0 ? "ru" : "en"));
                                    }}
                                    key={indx}
                                >
                                    {indx === 0
                                        ? <img src={rus}/>
                                        : <img src={usa}/>
                                    }
                                    {elem}
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="btn_redirect"
                        onClick={() => history('/student/auth')}
                    >
                        {variables_23}
                    </div>
                </div>
                <div className="sort">
                    <div className={"search"}>
                        <Input
                            padding={"5px 96px 7px 46px"}
                            value={searchValue}
                            setValue={setSearchValue}
                            backgroundColor={"transparent"}
                            maxWidthParent={"420px"}
                            widthParent={"100%"}
                            needFindBtn={true}
                            placeholder={variables_12}
                        />
                    </div>
                    <div className="category" style={{marginRight: 25}}>
                        <span>{variables_8}</span>
                        <div
                            className={flagDropDownSort ? "select_block active" : "select_block"}
                            onClick={() => setFlagDropDownSort(!flagDropDownSort)}
                        >
                            <span style={{color: "#B3DAFF"}}>{arrSort[activeSortCatalog]}</span>
                            <Arrow/>
                            <ul className={flagDropDownSort ? "drop_down-category active" : "drop_down-category"}>
                                {arrSort.map((elem, indx) =>
                                    <li key={indx} onClick={() => {
                                        setActiveSortCatalog(indx);
                                        setFlagDropDownSort(false);
                                    }}>{elem}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="category">
                        <span>{variables_9}</span>
                        <div
                            className={flagDropDownCategory ? "select_block active" : "select_block"}
                            onClick={() => setFlagDropDownCategory(!flagDropDownCategory)}
                        >
                            <span style={{color: "#B3DAFF"}}>{arrCategory[activeCategory]}</span>
                            <Arrow/>
                            <ul className={flagDropDownCategory ? "drop_down-category active" : "drop_down-category"}>
                                {arrCategory.map((elem, indx) =>
                                    <li key={indx} onClick={() => {
                                        setActiveCategory(indx);
                                        setFlagDropDownCategory(false);
                                    }}>{elem}</li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                {actualList.length > 0 &&
                    <>
                        <div className="actual_title">
                            <span>{variables_10}</span>
                        </div>
                        <div className="actual_list">
                            <ul>
                                {actualList.map((elem, indx) =>
                                    <li
                                        key={indx}
                                        onClick={() => {
                                            getApiActual(elem.ses);
                                            setFlagModalActual(true)
                                        }}
                                    >
                                        {elem.y_image &&
                                            <img src={elem.y_image}/>
                                        }
                                    </li>
                                )}
                            </ul>
                        </div>
                    </>
                }
                <div className="catalog" style={{marginTop: 20}}>
                    <ul>
                        {displayCourses && displayCourses.length > 0
                            ? displayCourses.map((course, indx) => {
                                    return (
                                        <li key={indx} className={"reuse_course-item"}>
                                            <CourseCard
                                                course={course}
                                                dateCourse={true}
                                                cost={true}
                                                redirectUrl={course.id}
                                                isCourseList={true}
                                            />
                                        </li>
                                    );
                                }
                            )
                            : <></>
                        }
                    </ul>
                    {total && total > 20
                        ? <div className="pagination_all">
                            <Pagination
                                itemRender={defaultFuncAndData.itemRenderPagination}
                                defaultCurrent={1}
                                defaultPageSize={20}
                                total={total}
                                onChange={(e) => setCurrentPage(e)}
                                showTitle={false}
                            />
                        </div>
                        : <></>
                    }
                    {loading &&
                        <Spin style={{position: "absolute"}}/>
                    }
                </div>
                {(flagDropDownCategory || flagDropDownSort) &&
                    <div
                        className="overlay"
                        onClick={() => {
                            setFlagDropDownCategory(false);
                            setFlagDropDownSort(false);
                        }}
                    />
                }
                <>
                    <Modal
                        open={flagModalActual}
                        footer={null}
                        className={"modal_actual"}
                        width={"600"}
                        closable={true}
                        closeIcon={
                            <div className="close" onClick={handleCloseModal}>
                                <img src={close_modal_course}/>
                            </div>
                        }
                        onCancel={handleCloseModal}
                        maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                        bodyStyle={{backgroundColor: "transparent", position: 'relative'}}
                    >

                        <div className="parent_slider">
                            <Slider
                                {...settingsSlider}
                                beforeChange={(e, n) => {
                                    if (n === 0) {
                                        setActiveImageLoaded(false);
                                        window.clearTimeout(timeoutVideoVsImage);
                                        window.clearTimeout(timeoutVideoVsImage2);
                                    }
                                    if (n === 1) {
                                        setActiveImageLoaded(true)
                                        setPlaying(false);
                                    }
                                    /*setTimeout(() => {
                                        setActiveSlideActual(n);
                                    }, 500);*/
                                }}
                                afterChange={(e) => setActiveSlideActual(e)}
                                ref={refSlidesActual}
                            >
                                {actualCurrent !== null && actualCurrent?.demo_video || actualCurrent?.demo_youtube
                                    ? <div>
                                        <div className="video">
                                            <ReactPlayer
                                                className='react-player'
                                                style={{marginBottom: 15, borderRadius: 30}}
                                                controls={true}
                                                onReady={(e) => {
                                                    setPlaying(true)
                                                    setMuted(false)
                                                }}
                                                muted={muted}
                                                onPlay={() => setPlaying(true)}
                                                playing={playing}
                                                url={actualCurrent?.demo_youtube ? actualCurrent?.demo_youtube : actualCurrent?.demo_video}
                                            />
                                        </div>
                                    </div>
                                    : null
                                }
                                {actualCurrent?.y_image &&
                                    <>
                                        <div className="parent_loader">
                                            <div className={activeImageLoaded ? "loader active" : "loader"}/>
                                        </div>
                                        <div style={{maxWidth: 600, width: '100%', position: 'relative'}}>
                                            <img src={actualCurrent?.y_image}/>
                                            {activeSlideActual === 1 &&
                                                <div
                                                    className="btn_redirect"
                                                    onClick={() => history("/student/course-preview/" + actualCurrent.ses)}
                                                >
                                                    Перейти к курсу
                                                </div>
                                            }
                                            {(!actualCurrent?.demo_video && !actualCurrent?.demo_youtube) && activeSlideActual === 0
                                                ? <div
                                                    className="btn_redirect"
                                                    onClick={() => history("/student/course-preview/" + actualCurrent.ses)}
                                                >
                                                    Перейти к курсу
                                                </div>
                                                : <></>
                                            }
                                        </div>
                                    </>
                                }
                            </Slider>
                        </div>
                        <ReuseDots
                            activeSlide={activeSlideActual}
                            setActiveSlide={(indx) => setActiveSlideActual(indx)}
                            countDots={(actualCurrent?.demo_video || actualCurrent?.demo_youtube) && actualCurrent?.y_image ? 2 : 1}
                        />
                    </Modal>
                </>
                <>
                    <Modal
                        open={flagModalSettingsMobile}
                        footer={null}
                        className={"modal_settings-mobile"}
                        // width={"auto"}
                        closable={false}
                        centered={true}
                        onCancel={() => {
                            /* setFlagHiddenFotter(null);*/
                            setFlagModalSettingsMobile(false);
                        }}
                        maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
                        bodyStyle={{padding: 15, backgroundColor: "#343843", borderRadius: 16}}
                    >
                        <div className="title">
                            <div className="group_left">
                                <img src={settings_mobile_category}/>
                                <span>Категория</span>
                            </div>
                            <div className="close" onClick={() => {
                                setFlagModalSettingsMobile(false);
                                /*setFlagHiddenFotter(null);*/
                            }}
                            >
                                <img src={close_modal_grey} alt=""/>
                            </div>
                        </div>
                        <div className="list">
                            {arrCategory.map((elem, indx) =>
                                <div
                                    onClick={() => {
                                        setActiveCategory(indx);
                                        setFlagModalSettingsMobile(false);
                                    }}
                                    key={indx}
                                    style={activeCategory === indx ? {color: "rgb(179, 218, 255)"} : {}}
                                >
                                    {elem}
                                </div>
                            )}
                        </div>
                        <div className="title" style={{marginTop: 20}}>
                            <div className="group_left">
                                <img src={settings_mobile_category}/>
                                <span>Сортировать по:</span>
                            </div>
                        </div>
                        <div className="list">
                            {sortCatalog.map((elem, indx) =>
                                <div
                                    onClick={() => {
                                        setActiveSortCatalog(indx);
                                        setFlagModalSettingsMobile(false);
                                    }}
                                    key={indx}
                                >
                                        <span style={activeSortCatalog === indx ? {color: "rgb(179, 218, 255)"} : {}}>
                                            {elem}
                                        </span>
                                </div>
                            )}
                        </div>
                    </Modal>
                </>
            </div>
        </div>
    );
};

export default CourseList;