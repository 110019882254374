import React, { useEffect, useState } from 'react';
import cn from "classnames";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import requests from "../../../../axios/requests";
import { defaultFuncAndData } from "../../../../../utils/defaultData";
import { message, Progress } from "antd";
import variableLanguage from "../../../../../utils/hookGetVariablesLanguage";

import arrow from "../../../../../images/arrow.svg";
import progress_icon from "../../../../../images/icons/progress_icon.svg"
import locker from "../../../../../images/icons/locker.svg"
import default_icon from "../../../../../images/icons/default_course.png";
import "./MobileContent.scss";
import ModalCertificate from "../../ModalCertificate/ModalCertificate";

const StagesPage = (props) => {
    const variable_1 = variableLanguage({keyPage: "lesson", keyVariable: "variable_1"});
    const variable_63 = variableLanguage({keyPage: "lesson", keyVariable: "variable_63"});

    const {
        progress, gradeIdentifyer, stages, matherials,
        getTheoryIcon, getCurrentColorIcon, activate,
        handleClick, setUserCode, setActivePage, activeStageId,
        setActiveStageId
    } = props;

    const history = useNavigate();
    const {language} = useSelector(state => state.Reducer);
    const [dataQuest, setDataQuest] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [oldProgress, setOldProgress] = useState(null);
    const [urlCertificate, setUrlCertificate] = useState("");

    let showCertificate = () => {
        if (Object.values(dataQuest)?.length > 0 && (dataQuest?.organization_id === 481) && !dataQuest?.certificate) {
            return dataQuest?.progress?.progress || 0
        }
        return 0
    }
    let handleGenerateCertificate = (fio, email) => {
        requests.quests.generate_certificate(dataQuest?.ses, fio, email)
            .then((data) => {
                message.success("Сертификат отправлен на вашу почту.")
                setOpenModal(false);
                setUrlCertificate(`https://skillometer.ru${data?.certificate}` || "")
                fetchCurrentQuest()
            })
            .catch(err => console.error(err))
    }
    let fetchCurrentQuest = () => {
        requests.quests.get_course_id("/" + stages[0]?.quest_ses)
            .then(({data}) => {
                setDataQuest(data)
                if (data.progress?.progress !== 100)
                    setOldProgress(data.progress?.progress)
            })
            .catch(err => console.error(err));
    }

    useEffect(() => {
        if (stages?.length > 0 && Object.values(dataQuest).length === 0) {
            requests.quests.get_course_id("/" + stages[0].quest_ses)
                .then(({data}) => setDataQuest(data))
                .catch(err => console.error(err));
        }
    }, [stages])
    return (
        <>
            <div className="preview_section">
                <div
                    className="prev_button"
                    onClick={() => history(-1)}
                >
                    <img
                        src={arrow}
                        alt="arrow"
                    />
                </div>
                <div className="progress">
                    {gradeIdentifyer(progress.progress)[1]}
                    {progress.progress < 10 &&
                        <span>{variable_1}</span>
                    }
                    <img src={progress_icon} alt="progress icon"/>
                </div>
                <div className="preview_image">
                    {dataQuest?.y_image &&
                        <img
                            src={dataQuest?.y_image}
                            alt="course image"
                        />
                    }
                    {dataQuest?.y_image !== undefined && !dataQuest.y_image &&
                        <img
                            src={defaultFuncAndData.defaultImage(dataQuest.speciality - 1) || default_icon}
                            alt="course image"
                        />
                    }
                </div>
            </div>
            <div className="stages_section">
                <div className="course_name">
                    {stages?.length > 0 &&
                        <span>{stages[0].quest_name} </span>
                    }
                </div>
                <div className="matherial_count">
                    {(progress?.count_video ||
                            progress?.count_article ||
                            progress?.count_quiz ||
                            progress?.count_task ||
                            progress?.count_code_task) &&
                        <div className="count_row">
                            {language === "ru" &&
                                <>
                                    {progress?.count_video > 0 &&
                                        <span>
                                            {progress.count_video} видео
                                        </span>
                                    }
                                    {progress?.count_article > 0 &&
                                        <span>
                                            {progress.count_article}
                                            {defaultFuncAndData.declension(progress.count_article, [" статья", " статьи", " статей"])}
                                        </span>
                                    }
                                    {progress?.count_quiz > 0 &&
                                        <span>
                                            {progress.count_quiz}
                                            {defaultFuncAndData.declension(progress.count_quiz, [" тест", " теста", " тестов"])}
                                        </span>
                                    }
                                    {progress?.count_text_task > 0 &&
                                        <span>
                                            {progress.count_text_task}
                                            {defaultFuncAndData.declension(progress.count_text_task, [" задание", " задания", " заданий"])}
                                        </span>
                                    }
                                    {progress?.count_code_task > 0 &&
                                        <span>
                                            {progress.count_code_task}
                                            {defaultFuncAndData.declension(progress.count_code_task, [" урок программирования", " урока программирования", " уроков программирования"])}
                                        </span>
                                    }
                                </>
                            }
                            {language === "en" &&
                                <>
                                    {progress?.count_video > 0 &&
                                        <span>
                                            {progress.count_video === 1
                                                ? "video"
                                                : "videos"
                                            }
                                        </span>
                                    }
                                    {progress?.count_article > 0 &&
                                        <span>
                                            {progress.count_article === 1
                                                ? "article"
                                                : "articles"
                                            }
                                        </span>
                                    }
                                    {progress?.count_quiz > 0 &&
                                        <span>
                                            {progress.count_quiz === 1
                                                ? "quiz"
                                                : "quizes"
                                            }
                                        </span>
                                    }
                                    {progress?.count_text_task > 0 &&
                                        <span>
                                            {progress.count_text_task === 1
                                                ? "homework"
                                                : "homeworks"
                                            }
                                        </span>
                                    }
                                    {progress?.count_code_task > 0 &&
                                        <span>
                                            {progress.count_code_task} programming homework
                                        </span>
                                    }
                                </>
                            }
                        </div>
                    }
                    <div
                        className={cn("percent", {
                            "fiolet": progress?.progress >= 61,
                            "yellow": progress?.progress <= 60,
                            "red": progress?.progress <= 20,
                        })}
                    >
                        {progress?.progress}%
                    </div>
                </div>
                <Progress
                    className={"progress_antd"}
                    showInfo={false}
                    percent={progress.progress}
                    strokeColor={defaultFuncAndData.calcColorPercentProgressCourse(progress.progress).color}
                    trailColor="#757A88"
                    width="100%"
                />
                <div className="list_stages">
                    {stages?.length > 0 &&
                        stages.map(elem =>
                            <div
                                className={cn("stage", {
                                    active: activeStageId === elem.id
                                })}
                                onClick={() => {
                                    if (elem.available)
                                        setActiveStageId(elem.id);
                                }}
                                key={elem.id}
                            >
                                {!elem.available &&
                                    <img src={locker} alt={"locker"}/>
                                }
                                <span>{elem.name}</span>
                            </div>
                        )
                    }
                </div>
                {(showCertificate() === 100) &&
                    <div
                        className="certificate"
                        onClick={() => setOpenModal(true)}
                    >
                        <span>{variable_63}</span>
                    </div>
                }
                {matherials?.length > 0 &&
                    <div className="list_matherials">
                        {matherials.map((elem, indx) =>
                            <div
                                className="stage_item"
                                key={elem.id}
                                onClick={() => {
                                    activate(indx);
                                    handleClick();
                                    setActivePage(1)
                                    setUserCode('');
                                }}
                            >
                                <div className={getCurrentColorIcon(elem, indx)}>
                                    {getTheoryIcon(elem.type, elem.seen, elem?.task_type)}
                                    {elem.title.length > 50
                                        ? elem.title.substring(0, 50) + "..."
                                        : elem.title
                                    }
                                </div>
                            </div>
                        )
                        }
                    </div>
                }
            </div>
            {(openModal || (progress?.progress === 100 && oldProgress !== null && oldProgress < 100))
                && dataQuest?.organization_id === 481 && !dataQuest?.certificate &&
                <ModalCertificate
                    setOpenModal={setOpenModal}
                    handleGenerateCertificate={handleGenerateCertificate}
                    currentQuest={dataQuest}
                    setOldProgress={setOldProgress}
                />
            }
        </>
    );
};

export default StagesPage;