import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import cn from "classnames";
import { defaultFuncAndData } from "../../../../utils/defaultData";
import { setUser } from "../../../redux/actions/userActions";

import { message, Modal, Spin } from "antd";
import requests from "../../../axios/requests";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import Button from "../../../components/Button/Button";
import ModalBuy from "../../GenerateCourse/Modals/ModalBuy/ModalBuy";

import close_modal from "../../../../images/icons/close_modal-course.svg";
import reload from '../../../../images/search_profession/reload.svg';
import download_gpt from '../../../../images/search_profession/download_gpt.svg';
import active_checkbox from '../../../../images/active_checkbox.svg';
import { ReactComponent as Arrow } from '../../../../images/arrow.svg';
import './ChatGptModal.scss';

const ChatGptModal = ({openModal, handleCloseModal}) => {
    const variable_1 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_1'});
    const variable_2 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_2'});
    const variable_3 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_3'});
    const variable_4 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_4'});
    const variable_5 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_5'});
    const variable_6 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_6'});
    const variable_7 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_7'});
    const variable_8 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_8'});
    const variable_10 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_10'});
    const variable_11 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_11'});
    const variable_12 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_12'});
    const variable_13 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_13'});
    const variable_16 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_16'});
    const variable_17 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_17'});
    const variable_18 = variableLanguage({keyPage: 'chatGptModal', keyVariable: 'variable_18'});

    const {trajectorySkills, config, user} = useSelector(state => state.Reducer);
    const history = useNavigate();
    const dispatch = useDispatch();

    const [selectTag, setSelectTag] = useState("");
    const [loadingGpt, setLoadingGpt] = useState(false);
    const [resultGpt, setResultGpt] = useState("");
    const [uploadDate, setUploadDate] = useState("");
    const [generateId, setGenerateId] = useState(null);
    const [idCourseGenerated, setIdCourseGenerated] = useState("");
    const [educationTries, setEducationTries] = useState([]);
    const [currentEducation, setCurrentEducation] = useState(null);
    const [allEducationUser, setAlllEducationUser] = useState([]);
    const [openModalBuy, setOpenModalBuy] = useState(false);

    let recursyProgress = (task_id) => {
        setLoadingGpt(true);
        requests.users.calery_progress(task_id)
            .then((res) => {
                let data = res.data;
                if (data.progress.current !== 100)
                    setTimeout(() => {
                        recursyProgress(task_id)
                    }, 2000)
                else
                    fetchGptApi()
            })
    }
    let updateEducationPlan = (text) => {
        if (user?.education_plan_limit === 0) {
            setLoadingGpt(false)
            return message.error(variable_12)
        }

        requests.users.education_plan({speciality_tag: selectTag.id, text})
            .then((res) => {
                setGenerateId(res.data.id)
                recursyProgress(res.data.task_id);
            })
            .catch(err => console.error(err))
    }
    let generateQuest = async () => {
        if (idCourseGenerated) {
            setTimeout(() => {
                history(`/student/course/lesson/${idCourseGenerated}`)
            }, 500)
            return
        }

        if (user?.education_plan_limit === 0)
            return message.error(variable_12)

        await requests.users.generate_quest(generateId)
            .then((res) => {
                let newUser = {...user};
                newUser.education_plan_limit -= 1;
                dispatch(setUser(newUser))
                getExistTags();
                setTimeout(() => {
                    history(`/student/course/lesson/${res.data?.quest?.id}`)
                }, 500)
            })
            .catch(err => {
                console.error(err)
                setLoadingGpt(false)
            })
    }
    let fetchGptApi = async () => {
        setLoadingGpt(true);

        await requests.users.fetch_education_plan(selectTag.id)
            .then(res => {
                setIdCourseGenerated("")
                let data = res.data?.results;
                if (data?.length > 0) {
                    setCurrentEducation(data?.length - 1);
                    setEducationTries(data)
                    setLoadingGpt(false);
                } else
                    updateEducationPlan()
            })
            .catch(err => console.error(err))
    }
    let checkExistTags = () => {
        return allEducationUser
            .filter(elem => elem.quest?.id)
            .map((elem) => elem.speciality_tag)
    }
    let getExistTags = () => {
        requests.users.get_plannings()
            .then(res => setAlllEducationUser(res.data.results))
            .catch(err => console.error(err))
    }

    useEffect(() => {
        getExistTags();
    }, [])
    useEffect(() => {
        if (allEducationUser.length > 0)
            checkExistTags();
    }, [allEducationUser])
    useEffect(() => {
        if (selectTag) {
            setResultGpt("");
            setIdCourseGenerated("")
            setGenerateId(null)
            setUploadDate("")
            fetchGptApi()
        }
    }, [selectTag])
    useEffect(() => {
        if (educationTries.length > 0 && (typeof educationTries[currentEducation] !== "undefined")) {
            setResultGpt(educationTries[currentEducation].text)
            setIdCourseGenerated(educationTries[currentEducation].quest?.id)
            setGenerateId(educationTries[currentEducation].id)
            setUploadDate(moment(educationTries[currentEducation].created_at).format("DD.MM.YY"))
        }
    }, [educationTries, currentEducation])
    return (
        <Modal
            open={openModal}
            footer={null}
            className={"reuse_modal gpt_"}
            closable={false}
            onCancel={handleCloseModal}
            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
            bodyStyle={{padding: "40px", borderRadius: 40}}
            destroyOnClose
        >
            <>
                <div className="top_section">
                    <div className="title_">
                        <span>{variable_1}</span>
                        <div
                            className="close_icon"
                            onClick={handleCloseModal}
                        >
                            <img
                                className={"close_title"}
                                width={40}
                                height={40}
                                src={close_modal}
                            />
                        </div>
                    </div>
                    <div className="info">
                        {variable_2}
                    </div>
                    <div className="tags_section">
                        <div className="h2">
                            {variable_3}
                        </div>
                        <div className="label_info">
                            {variable_4}
                        </div>
                        <ul className="list_tags">
                            {trajectorySkills?.length > 0 &&
                                trajectorySkills.map((elem) =>
                                    <li
                                        className={cn("tag", {
                                            active: selectTag?.name === elem.name,
                                            exist_quest: checkExistTags().includes(elem.id)
                                        })}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            if (!loadingGpt)
                                                setSelectTag(elem)
                                            else
                                                message.warning(variable_18)
                                        }}
                                        key={elem.id}
                                    >
                                        {elem.name}
                                        <img src={active_checkbox} alt="check"/>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
                {selectTag?.name &&
                    <div className="result_section">
                        <div className="buttons_row">
                            <Button
                                padding={"14px 15px 16px 15px"}
                                text={variable_13 + " " + user?.education_plan_limit + ")"}
                                click={() => setOpenModalBuy(true)}
                                maxWidth={"fit-content"}
                                zIndex={1}
                            />
                            {(educationTries.length > 0 && !loadingGpt && !idCourseGenerated) &&
                                <div className="attempts">
                                    <div>
                                        <div className="label">
                                            {variable_16}
                                        </div>
                                        <Arrow
                                            onClick={() => {
                                                if (currentEducation > 0 && (educationTries.length - 1 >= currentEducation - 1))
                                                    setCurrentEducation(prev => prev - 1)
                                            }}
                                        />
                                        <div className="current">
                                            {currentEducation + 1}
                                            /
                                            {educationTries.length}
                                        </div>
                                        <Arrow
                                            onClick={() => {
                                                if (currentEducation < 5 && (educationTries.length - 1 >= currentEducation + 1))
                                                    setCurrentEducation(prev => prev + 1)
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {(!loadingGpt && !idCourseGenerated) &&
                                (educationTries.length < config?.PLAN_GENERATION_UPDATE_LIMIT) &&
                                <div
                                    className="btn_reload"
                                    onClick={() => updateEducationPlan(true)}
                                >
                                    <div>
                                        {variable_5}
                                    </div>
                                    <img
                                        src={reload}
                                        alt="reload"
                                    />
                                    {uploadDate &&
                                        <span>
                                            {variable_7}
                                            {" "}
                                            {uploadDate || moment().format("DD.MM.YY")}
                                        </span>
                                    }
                                </div>
                            }
                        </div>
                        <div className="h1">
                            <span>
                                {selectTag.name}
                            </span>

                        </div>
                        <div className="info_row">
                            <span>
                                {variable_6}
                            </span>
                        </div>
                        {loadingGpt
                            ? <div className="message_loading">
                                <span>{variable_8}</span>
                                <Spin/>
                            </div>
                            : <>
                                {resultGpt &&
                                    <>
                                        <pre>
                                            {defaultFuncAndData.convertToFontCode(resultGpt)}
                                        </pre>
                                        <div
                                            className="download_btn"
                                            onClick={() => generateQuest()}
                                        >
                                            <span>{idCourseGenerated ? variable_17 : variable_10}</span>
                                            {!idCourseGenerated &&
                                                <img src={download_gpt} alt="download_gpt"/>
                                            }
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                }
                {(!loadingGpt && resultGpt) &&
                    <Button
                        className="btn_save-course"
                        text={idCourseGenerated ? variable_17 : variable_10}
                        click={() => generateQuest()}
                        maxWidth={"170px"}
                        zIndex={1}
                    />
                }
            </>
            <ModalBuy
                openModalBuy={openModalBuy}
                setOpenModalBuy={setOpenModalBuy}
                config={config}
            />
        </Modal>
    );
};

export default ChatGptModal;