import React, { useEffect, useState } from 'react';
import requests from "../../../../axios/requests";
import ReactPlayer from "react-player";
import moment from "moment/moment";
import cn from "classnames";
import variableLanguage from "../../../../../utils/hookGetVariablesLanguage";
import { useSelector } from "react-redux";
import { defaultFuncAndData } from "../../../../../utils/defaultData";

import { Image, message, notification } from "antd";
import { StorageUploader } from "../../../../data/StorageUploader";
import Quiz from "../WebContent/Quiz/Quiz";
import Scorm from "../WebContent/Scorm/Scorm";
import Button from "../../../../components/Button/Button";
import Comments from "../WebContent/Comments/Comments";
import QuizMob from "./QuizMob/QuizMob";

import arrow from "../../../../../images/arrow.svg";
import task_file_icon from "../../../../../images/icons/upload_task.svg";

const ContentPage = (props) => {
    const variable_22 = variableLanguage({keyPage: "lesson", keyVariable: "variable_22"});
    const variable_27 = variableLanguage({keyPage: "lesson", keyVariable: "variable_27"});
    const variable_28 = variableLanguage({keyPage: "lesson", keyVariable: "variable_28"});
    const variable_29 = variableLanguage({keyPage: "lesson", keyVariable: "variable_29"});
    const variable_33 = variableLanguage({keyPage: "lesson", keyVariable: "variable_33"});
    const variable_40 = variableLanguage({keyPage: "lesson", keyVariable: "variable_40"});
    const variable_52 = variableLanguage({keyPage: "lesson", keyVariable: "variable_52"});

    const {
        title, image_file, task_file, task_file_name, text, urls,
        id, questions, attempts_left, video_file, video_file_name,
        youtube, lms_package, tasks, type, block_next, setActivePage,
        getTheories, stageId, activeMatherial, matherials, activate, showComments, seen
    } = props;
    const {user} = useSelector(state => state.Reducer);

    const [yandexKey, setYandexKey] = useState("");
    const [tasksReverse, setTasksReverse] = useState([]);
    const [answerValue, setAnswerValue] = useState("");
    const [fileListProps, setFileListProps] = useState([]);
    const [comments, setComments] = useState("");
    const [valueComment, setValueComment] = useState("");

    let checkStatus = () => {
        if (tasksReverse?.length > 0)
            return tasksReverse[0].checked
        else
            return "need_correction"
    }
    let sendHomework = () => {
        if (!answerValue)
            return message.error(variable_29);

        requests.stages.post_homework_send({
            theory_id: id,
            text: answerValue,
            ...(fileListProps?.length > 0
                ? {
                    file: "https://storage.yandexcloud.net/skill-storage/" + yandexKey,
                    task_file_name: fileListProps[0].name
                }
                : {})
        })
            .then((v) => {
                notification.success({message: variable_27});
                window.location.reload(false);
            })
            .catch((e) => {
                if (e.message === "Request failed with status code 400") {
                    notification.error({message: variable_28});
                }
            });
    }
    let isVideo = (fileName) => {
        const videoFormats = [
            ".WEBM", ".MPG", ".MP2", ".MPEG", ".MPE", ".MPV",
            ".OGG", ".MP4", ".M4P", ".M4V", ".AVI", ".WMV",
            ".MOV", ".QT", ".FLV", ".SWF", "AVCHD"
        ];
        return (videoFormats.some((format) => fileName?.toLowerCase().includes(format.toLowerCase())));
    };
    let getApiComments = () => {
        requests.comments.get_comment_matherial({
            contentType: 46,
            objectId: id
        })
            .then((res) => setComments(res.data))
            .catch((error) => console.log(error, "error get_comments"));
    };
    let sendComment = () => {
        if (!valueComment)
            return;

        let obj = {
            user: {
                profile: {
                    avatar: user.avatar
                },
            },
            content_type: 46,
            object_id: id,
            text: valueComment
        };
        requests.comments.add_comment_matherial(obj)
            .then((res) => setComments((prev) => [...prev, res.data]))
            .catch((error) => console.log(error, "error add_comment"));
        setValueComment("");
    };
    let handleSetLikeDislake = (id, value) => {
        let obj = {
            "content_type": 86,
            "object_id": id,
            "value": value
        };
        requests.comments.set_like(obj)
            .then(() => getApiComments())
            .catch((error) => console.error(error))
    };

    useEffect(() => {
        requests.storage.get_yandex_key({stage_id: id, theory_type: "T"})
            .then((v) => setYandexKey(v.data.key));


    }, []);
    useEffect(() => {
        requests.stages.post_seen({stage_theory_id: id})
            .catch(err => console.error(err))

        getApiComments()
    }, [id])
    useEffect(() => {
        if (tasks?.length > 0 && type === "T") {
            let newTasks = [...tasks.reverse()]
            setTasksReverse(newTasks)
        }
    }, [tasks])
    return (
        <div className="content_page">
            <div className="prev_button">
                <img
                    src={arrow}
                    alt="arrow"
                    onClick={() => {
                        getTheories(stageId)
                        setActivePage(0)
                    }}
                />
            </div>
            <div className="content_lesson">
                {(title && window.innerWidth > 820) &&
                    <div className="title">{title}</div>
                }
                {(video_file || youtube) &&
                    <>
                        <ReactPlayer
                            className="react-player"
                            controls={true}
                            width="100%"
                            height="100%"
                            url={youtube ? ("https://www.youtube.com/watch?v=" + youtube) : video_file}
                            config={{
                                file: {
                                    attributes: {
                                        controlsList: "nodownload",
                                        onContextMenu: e => e.preventDefault()
                                    }
                                }
                            }}
                            controlsList="nodownload"
                        />
                        {video_file &&
                            <div
                                className={"task_file"}
                                /*href={video_file}
                                download={true}*/
                            >
                                <span>{video_file_name}</span>
                            </div>
                        }
                    </>
                }
                {questions?.length > 0 &&
                    <>
                        {window.innerWidth > 820
                            ? <Quiz
                                questions={questions}
                                attempts_left={attempts_left}
                                stage_theory_id={id}
                                propsKey={"mobile"}
                            />
                            : <QuizMob
                                questions={questions}
                                attempts_left={attempts_left}
                                stage_theory_id={id}
                                title={title}
                            />
                        }
                    </>
                }
                {lms_package &&
                    // <Scorm/>
                    <Scorm taskProps={{id: id, seen: seen, title: title}}/>
                }
                {image_file &&
                    <Image
                        className="image_file"
                        src={image_file}
                    />
                }
                {task_file &&
                    <>
                        <div className="h2">Прикреплённый файл</div>
                        <a
                            className={"task_file"}
                            href={task_file}
                            download={true}
                        >
                            <img src={task_file_icon} alt="task_file"/>
                            <span>{task_file_name}</span>
                        </a>
                    </>
                }
                {text &&
                    <>
                        <div className="h2">Описание материала</div>
                        <pre className="text">{defaultFuncAndData.linkChanger(text)}</pre>
                    </>
                }
                {urls &&
                    <>
                        <div className="h2">Ссылки на источники</div>
                        <pre className="links">{defaultFuncAndData.linkChanger(urls)}</pre>
                    </>
                }
                {type === "T" &&
                    <div className="homework_answers-list">
                        {checkStatus() === "need_correction" &&
                            <>
                                <div className="lable">
                                    Текст к ответу
                                </div>
                                <input
                                    className="input_answer input_classic"
                                    value={answerValue}
                                    onChange={(e) => setAnswerValue(e.target.value)}
                                    type="text"
                                />
                                <div className="lable">
                                    Загрузить файлы на проверку
                                </div>
                                <StorageUploader
                                    yandexKey={yandexKey}
                                    count={1}
                                    editable={false}
                                    setFileListProps={setFileListProps}
                                    setFileName={() => {
                                    }}
                                    removeFile={() => setFileListProps([])}
                                />
                                <Button
                                    text={variable_33}
                                    click={() => sendHomework()}
                                    maxWidth={"fit-content"}
                                    margin={"20px 0"}
                                    boxShadow={"none"}
                                />
                            </>
                        }
                        {(checkStatus() === "not_reviewed" && block_next) &&
                            <span className="status">{variable_52}</span>
                        }
                        {(checkStatus() === "not_reviewed" && !block_next) &&
                            <span className="status">{variable_40}</span>
                        }
                        {tasksReverse?.length > 0 && tasksReverse.map((elem, indx) =>
                            <div
                                className="homework_answer"
                                key={indx}
                            >
                                <div className="student_answer">
                                    <div className="title">
                                        <span>Ответ {tasksReverse.length - indx}</span>
                                        <span>
                                        {elem.time
                                            ? moment(elem.time)
                                                .format("dddd, D MMMM  YYYY, h:mm:ss")
                                            : ""
                                        }
                                    </span>
                                    </div>
                                    <div className="answer">
                                        <span>Ваш ответ:</span>
                                        <span>{elem.text}</span>
                                    </div>
                                    {elem.file &&
                                        <div className="attached_file">
                                            <span>Прикрепленный файл:</span>
                                            <a href={elem.file} download={true}>{elem.task_file_name}</a>
                                        </div>
                                    }
                                </div>
                                {elem.teachers_answer &&
                                    <div
                                        className={cn("teacher_answer", {
                                            denied: elem.checked === "need_correction",
                                            success: elem.checked === "passed",
                                        })}
                                    >
                                        <div className="answer">
                                            <span>Ответ наставника:</span>
                                            <span>{elem.teachers_answer}</span>
                                        </div>
                                        {elem.teachers_file &&
                                            <div className="attached_file">
                                                <span>Прикрепленный файл:</span>
                                                <a href={elem.teachers_file}
                                                   download={true}>{elem.teachers_file_name}</a>
                                            </div>
                                        }
                                        <div className="time_teachers_answer">
                                            <span>Время проверки:</span>
                                            <span>
                                            {moment(elem.check_datetime)
                                                .format("dddd, D MMMM  YYYY, h:mm:ss")}
                                        </span>
                                        </div>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                }
            </div>
            {type !== "Q" &&
                <Button
                    text={(matherials?.length - 1) > activeMatherial ? variable_22 : "Закончить"}
                    click={() => {
                        if ((matherials?.length - 1) > activeMatherial) {
                            activate(activeMatherial + 1)
                        } else {
                            getTheories(stageId)
                            setActivePage(0)
                        }
                        document.getElementById('element_for_scroll_up').scrollTop = 0;
                    }}
                    padding={"7px 15px 9px"}
                    maxWidth={"100%"}
                    margin={"auto 15px 10px 0"}
                    boxShadow={"none"}
                />
            }
            {showComments &&
                <Comments
                    comments={comments}
                    sendComment={sendComment}
                    handleSetLikeDislake={handleSetLikeDislake}
                    valueComment={valueComment}
                    setValueComment={setValueComment}
                />
            }
        </div>
    );
};

export default ContentPage;