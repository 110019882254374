import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import requests from "../../../../axios/requests";
import { setUserTrajectory } from "../../../../redux/actions/userActions";

import { message, Modal, Spin } from "antd";
import variableLanguage from "../../../../../utils/hookGetVariablesLanguage";
import Button from "../../../../components/Button/Button";
import Input from "../../../../components/Input/Input";
import Tag from "../../../../components/Tag/Tag";

import study_traektory from "../../../../../images/icons/study_traektory.svg";
import './ModalTrajectory.scss';

const ModalTrajectory = (props) => {
    const {
        flagModalTraektoryMobile, setFlagModalTraektoryMobile,
        apiRemoveTrajectory, getListApiCourses, addTrajectorySkill
    } = props;

    const history = useNavigate();
    const {trajectorySkills} = useSelector(state => state.Reducer);
    const dispatch = useDispatch();

    const variables_8 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_8"});
    const variables_9 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_9"});
    const variables_10 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_10"});
    const variables_28 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_28"});
    const variables_31 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_31"});
    const variables_36 = variableLanguage({keyPage: "searchProfession", keyVariable: "variables_36"});

    const [valueInputTag, setValueInputTag] = useState("");
    const [existTagsList, setExistTagsList] = useState([]);
    const [timeoutSearch, setTimeoutSearch] = useState(null);
    const [timeoutFetchPagination, setTimeoutFetchPagination] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [flagOpenSelectTags, setFlagOpenSelectTags] = useState(false);
    const [loading, setLoading] = useState(false);

    let fetchSearchTags = (page_size) => {
        setLoading(true);
        requests.profs.get_speciality_tags(valueInputTag, 1, page_size || pageSize)
            .then((v) => setExistTagsList(v.data))
            .finally(() => setLoading(false))
    }
    let handleScroll = (event) => {
        let scrollTop = event.target.scrollTop;
        if (timeoutFetchPagination)
            clearTimeout(timeoutFetchPagination)
        if ((scrollTop >= (pageSize * 14)) && (existTagsList?.count > pageSize)) {
            setTimeoutFetchPagination(
                setTimeout(() => {
                    setPageSize(prev => prev + 10);
                    fetchSearchTags(pageSize + 10)
                }, 500)
            )
        }
    }

    useEffect(() => {
        if (valueInputTag.length > 1) {
            let lastSymbol = valueInputTag[valueInputTag.length - 1];
            if (lastSymbol !== " ") {
                setPageSize(10)
                setExistTagsList([]);
                if (timeoutSearch)
                    clearTimeout(timeoutSearch)

                setTimeoutSearch(setTimeout(() =>
                    fetchSearchTags(10), 500))
            }
        }
    }, [valueInputTag])
    return (
        <Modal
            open={flagModalTraektoryMobile}
            footer={null}
            className={"modal_traektory-mobile"}
            closable={false}
            onCancel={() => setFlagModalTraektoryMobile(false)}
            maskStyle={{backgroundColor: "rgba(0, 0, 0, 0.10)"}}
            bodyStyle={{padding: 15, backgroundColor: "#27292F", borderRadius: 20}}
        >
            <div className="title">
                <img src={study_traektory}/>
                <span>{variables_8}</span>
            </div>

            <div className="select_block">
                <Input
                    padding={"3px 15px 5px"}
                    margin={"0 0 15px 0"}
                    value={valueInputTag}
                    setValue={setValueInputTag}
                    placeholder={variables_9}
                    onFocus={() => setFlagOpenSelectTags(true)}
                    zIndex={4}
                    onBlur={() => {
                        setTimeout(() => {
                            setFlagOpenSelectTags(false);
                        }, 100);
                    }}
                />
                {existTagsList?.results?.length > 0 &&
                    <div
                        className={flagOpenSelectTags
                            ? "parent_tag open"
                            : "parent_tag"
                        }
                        onScroll={handleScroll}
                    >
                        {existTagsList.results.map((elem, indx) =>
                            <div
                                className={"tag_"}
                                key={indx}
                                onClick={() => {
                                    setValueInputTag("");
                                    for (let i = 0; i < trajectorySkills.length; i++) {
                                        if (trajectorySkills[i].name === elem.name) {
                                            return message.warning(variables_31);
                                        }
                                    }
                                    addTrajectorySkill(elem);
                                    let newPrev = [...trajectorySkills];
                                    newPrev = [...newPrev, {name: elem.name}];
                                    return dispatch(setUserTrajectory(newPrev));
                                }}
                            >
                                {elem.name}
                            </div>
                        )}
                    </div>
                }
                {loading &&
                    <Spin className="spin_loading"/>
                }
            </div>
            <div className="down_block-traektory">
                <div className="block_tags">
                    {trajectorySkills.map((elem, indx) => {
                            return (
                                <Tag
                                    removeTag={apiRemoveTrajectory}
                                    elem={elem}
                                    key={indx}
                                />
                            );
                        }
                    )}
                </div>
            </div>
            <div className="buttons">
                <Button
                    text={variables_36}
                    click={() => {
                        history('/student/generate-course')
                        setFlagModalTraektoryMobile(false)
                    }}
                    padding={"7px"}
                    background={"transparent"}
                />
                <Button
                    text={variables_10}
                    click={getListApiCourses}
                    padding={"7px"}
                    background={"transparent"}
                />
            </div>
            <div
                className="close"
                onClick={() => setFlagModalTraektoryMobile(false)}
            >
                {variables_28}
            </div>
        </Modal>
    );
};

export default ModalTrajectory;