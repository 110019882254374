import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter, Routes } from "react-router-dom";
import { ConfigProvider } from 'antd';
import App from './App';
import ru_RU from 'antd/lib/locale/ru_RU';
import store from '../src/student/redux/store';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "antd/dist/antd.less";
import 'intro.js/introjs.css';
import './styles/index.scss';

ReactDOM.render(
        <BrowserRouter>
            <ConfigProvider locale={ru_RU}>
                <Provider store={store}>
                    <App/>
                </Provider>
            </ConfigProvider>
        </BrowserRouter>,
    document.getElementById('root')
);
