import React, { useEffect, useState } from "react";
import requests from "../../../axios/requests";
import cities from "../../../data/hhcities.json";

import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import CustomCheckbox from "../../../components/CustomCheckbox";
import { Modal, Radio, Row, Select, Slider, Spin, Tree } from "antd";
import close_modal_course from "../../../../images/icons/close_modal-course.svg";
import close_tag from "../../../../images/icons/close_tag.svg";
import cn from "classnames";
import Input from "../../../components/Input/Input";

const ModalFilter = ({flagModalFilter, closeModal, setAllJobsVacancy}) => {
    const variable_1 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_1'})
    const variable_2 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_2'})
    const variable_3 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_3'})
    const variable_4 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_4'})
    const variable_5 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_5'})
    const variable_6 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_6'})
    const variable_7 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_7'})
    const variable_8 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_8'})
    const variable_9 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_9'})
    const variable_10 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_10'})
    const variable_11 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_11'})
    const variable_12 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_12'})
    const variable_13 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_13'})
    const variable_14 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_14'})
    const variable_15 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_15'})
    const variable_16 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_16'})
    const variable_17 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_17'})
    const variable_18 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_18'})
    const variable_19 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_19'})
    const variable_20 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_20'})
    const variable_21 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_21'})
    const variable_22 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_22'})
    const variable_23 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_23'})
    const variable_24 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_24'})
    const variable_25 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_25'})
    const variable_26 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_26'})
    const variable_27 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_27'})
    const variable_28 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_28'})
    const variable_29 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_29'})
    const variable_30 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_30'})
    const variable_31 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_31'})
    const variable_32 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_32'})
    const variable_33 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_33'})
    const variable_34 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_34'})
    const variable_35 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_35'})
    const variable_36 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_36'})
    const variable_37 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_37'})
    const variable_38 = variableLanguage({keyPage: 'filters', keyVariable: 'variable_38'})

    const [sliderValue, setSliderValue] = useState(30000);
    const [activePrice, setActivePrice] = useState(0);
    const [inputCities, setInputCities] = useState("");
    const [showModalCategory, setShowModalCategory] = useState(false);
    const [industry, setIndustry] = useState([]);
    const [flagOpenCity, setFlagOpenCity] = useState(false);
    const [arrayArea, setArrayArea] = useState(["Москва"]);
    const [experienseRadio, setExperienseRadio] = useState(1);
    const [order_by, setOrder_by] = useState(1);
    const [period, setPeriod] = useState(1);
    const [allCities, setAllCities] = useState([]);
    const [loading, setLoading] = useState(false);

    const [checkedKeys, setCheckedKeys] = useState(["0-0-0"]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [valuesObject, setValuesObject] = useState({
        text: "Веб-дизайнер",
        area: window.localStorage.getItem("region"),
        search_field: "",
        industry: "",
        salary: 0,
        only_with_salary: false,
        experiense: experienseRadio,
        schedule: false,
        order_by: order_by,
        period: period
    });
    const [valuesCheckboxWords, setValuesCheckboxWords] = useState({
        inNameCompany: false,
        inDescriptionVacansy: false
    });
    const [valuesCheckboxWork, setValuesCheckboxWork] = useState({
        fullDay: false,
        shift: false,
        flexible: false,
        remote: false,
        flyInFlyOut: false
    });

    let getCities = () => {
        let result = [];
        const start = new Date().getTime();
        for (let i = 0; i < cities.length; i++) {
            for (let j = 0; j < cities[i].areas.length; j++) {
                result = [...result, cities[i].areas[j].name];
                for (let c = 0; c < cities[i].areas[j].areas.length; c++) {
                    result = [...result, cities[i].areas[j].areas[c].name];
                }
            }
        }
        const end = new Date().getTime();
        console.log(`SecondWay: ${end - start}ms`); // время выполнения функции
        setAllCities(result);
    };
    let onExpand = () => {
        setAutoExpandParent(false);
    };
    let onCheck = (checkedKeysValue, second) => {
        let arrChecked = [];

        for (let i = 0; i < second?.checkedNodes?.length; i++) {
            if (second.checkedNodes[i].hh_id.includes(".")) // проверка, чтобы не отправлялись parent элементы дерева.
                arrChecked = [...arrChecked, second.checkedNodes[i].id];
        }
        arrChecked = Array.from(new Set(arrChecked));
        setValuesObject((prev) => ({...prev, ["industry"]: arrChecked}));
        setCheckedKeys(checkedKeysValue);
    };
    let onSelect = (selectedKeysValue, info) => {
        setSelectedKeys(selectedKeysValue);
    };
    let onChange = (newValue) => {
        setSliderValue(newValue);
    };
    let handleCloseModal = () => closeModal(false);
    let handleAddCities = () => {
        if (inputCities)
            setInputCities("");
    };
    let getApiIndustry = () => {
        requests.profs.get_industry()
            .then((res) => {
                let newArr = [];
                if (res.data && res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        let newObj = {
                            title: res.data[i].name,
                            key: res.data[i].name,
                            hh_id: res.data[i].hh_id,
                            id: res.data[i].id,
                            children: res.data[i].children.map((elem) => {
                                return {
                                    title: elem.name,
                                    key: elem.name,
                                    hh_id: elem.hh_id,
                                    id: elem.id,
                                };
                            })
                        };
                        newArr = [...newArr, newObj];
                    }
                    setIndustry(newArr);
                }
            })
            .catch((error) => {
                console.log(error, "error get_category");
            });
    };
    let getApiVacansy = () => {
        setLoading(true);
        requests.profs.get_hh_vacancy_filter(valuesObject)
            .then((res) => setAllJobsVacancy(res?.data?.vacancies))
            .catch((error) => console.error(error))
            .finally(() => {
                closeModal(false);
                setLoading(false);
            });
    };

    useEffect(() => {
        getApiIndustry();
    }, []);
    useEffect(() => {
        if (activePrice === 0)
            setSliderValue(arrSlider[0]);
        if (activePrice === 1)
            setSliderValue(arrSlider[1]);
        if (activePrice === 2)
            setSliderValue(arrSlider[2]);
    }, [activePrice]);
    useEffect(() => {
        let str = "";
        if (valuesCheckboxWords.inNameCompany) {
            str = str + `company_name,`;
        }
        if (valuesCheckboxWords.inDescriptionVacansy) {
            str = str + `description`;
        }
        setValuesObject((prev) => ({...prev, ["search_field"]: str}));
    }, [valuesCheckboxWords]);
    useEffect(() => {
        let str = "";
        if (arrayArea && arrayArea.length > 0) {
            for (let i = 0; i < arrayArea.length; i++) {
                if (i === arrayArea.length - 1) {
                    str = str + `${arrayArea[i]}`;
                } else {
                    str = str + `${arrayArea[i]}, `;
                }
            }
        }
        setValuesObject((prev) => ({...prev, ["area"]: str}));
    }, [arrayArea]);
    useEffect(() => {
        setValuesObject((prev) => ({...prev, ["salary"]: sliderValue}));
    }, [sliderValue]);
    useEffect(() => {
        let experiense = "";
        if (experienseRadio === 1) {
            experiense = "noExperience";
        }
        if (experienseRadio === 2) {
            experiense = "between1And3";
        }
        if (experienseRadio === 3) {
            experiense = "between3And6";
        }
        if (experienseRadio === 4) {
            experiense = "moreThan6";
        }
        setValuesObject((prev) => ({...prev, ["experiense"]: experiense}));
    }, [experienseRadio]);
    useEffect(() => {
        let graphicWork = "";
        for (let key in valuesCheckboxWork) {
            if (valuesCheckboxWork[key])
                graphicWork = graphicWork + `${key},`;
        }
        if (graphicWork)
            graphicWork = graphicWork.substring();
        setValuesObject((prev) => ({...prev, ["schedule"]: graphicWork.replace(/,\s*$/, "")}));
    }, [valuesCheckboxWork]);
    useEffect(() => {
        let order_byStr = "";
        if (order_by === 1) {
            order_byStr = "publication_time";
        }
        if (order_by === 2) {
            order_byStr = "salary_desc";
        }
        if (order_by === 3) {
            order_byStr = "salary_asc";
        }
        if (order_by === 4) {
            order_byStr = "relevance";
        }
        if (order_by === 5) {
            order_byStr = "distance";
        }
        setValuesObject((prev) => ({...prev, ["order_by"]: order_byStr}));
    }, [order_by]);
    useEffect(() => {
        let periodStr = "";
        if (period === 1) {
            periodStr = "all";
        }
        if (period === 2) {
            periodStr = "month";
        }
        if (period === 3) {
            periodStr = "week";
        }
        if (period === 4) {
            periodStr = "3_days";
        }
        if (period === 5) {
            periodStr = "1_day";
        }
        setValuesObject((prev) => ({...prev, ["period"]: periodStr}));
    }, [period]);

    const arrSlider = [[30000], [70000], [120000]];
    return (
        <>
            <Modal
                open={flagModalFilter}
                footer={null}
                className={"modal_filter"}
                width={"100%"}
                closable={false}
                bodyStyle={{padding: 40, marginTop: -80, backgroundColor: "#F0E9FF", borderRadius: 60}}
            >
                <div className="title">
                    <div className="h1">{variable_1}</div>
                    <div
                        className="close_icon"
                        onClick={handleCloseModal}
                        style={{}}
                    >
                        <img width={40} height={40} src={close_modal_course}/>
                    </div>
                </div>
                <div className="group">
                    <div className="parent">
                        <div className="left_block-filter">
                            <span>{variable_2}</span>
                        </div>
                        <div className="right_block-filter">
                            <Input
                                padding={"7px 15px 9px"}
                                margin={"0 0 15px 0"}
                                value={valuesObject.text}
                                setValue={(e) => setValuesObject((prev) => ({...prev, ["text"]: e}))}
                                backgroundColor={"rgba(129,70,255,.2"}
                                border={"1px solid #8146ff"}
                                color={"#16151b"}
                            />
                            <span style={{marginBottom: 10}}>{variable_3}</span>
                            <CustomCheckbox
                                value={valuesCheckboxWords.inNameCompany}
                                setValue={(e) => setValuesCheckboxWords((prev) => ({...prev, ["inNameCompany"]: e}))}
                                label={variable_4}
                                id={"inNameCompany"}
                            />
                            <CustomCheckbox
                                value={valuesCheckboxWords.inDescriptionVacansy}
                                setValue={(e) => setValuesCheckboxWords((prev) => ({
                                    ...prev,
                                    ["inDescriptionVacansy"]: e
                                }))}
                                label={variable_5}
                                id={"inDescriptionVacansy"}
                            />
                        </div>
                    </div>
                    <div className="parent" style={{display: 'block'}}>
                        <div className="left_block-filter">
                            <span>{variable_6}</span>
                        </div>
                        <div className="right_block-filter" style={{alignSelf: "end"}}>
                            <Row>
                                <div
                                    onClick={() => {
                                        setShowModalCategory(true);
                                        getApiIndustry();
                                    }}
                                    className="select_specialization"
                                >
                                    {variable_7}
                                </div>
                            </Row>
                        </div>
                    </div>
                    <div className="parent">
                        <div className="left_block-filter">
                            <span>{variable_9}</span>
                        </div>
                        <div className="right_block-filter">
                            <Select
                                className={flagOpenCity ? "regionSelect active" : "regionSelect"}
                                size="medium"
                                showSearch
                                showArrow={false}
                                placeholder="Укажите город/регион"
                                onChange={(e) => {
                                    if (arrayArea.includes(e)) {
                                        return;
                                    }
                                    setArrayArea((prev) => [...prev, e]);
                                }}
                                defaultValue={localStorage.getItem("region")}
                                onDropdownVisibleChange={(e) => {
                                    setFlagOpenCity(e)
                                    if (e && allCities.length === 0)
                                        getCities();
                                }}
                                dropdownAlign={{
                                    offset: [0, 0]
                                }}
                            >
                                <>
                                    {allCities.length > 0 &&
                                        allCities.map((city, indx) =>
                                            <Select.Option
                                                key={indx}
                                                value={city}
                                            >
                                                {city}
                                            </Select.Option>
                                        )
                                    }
                                </>
                            </Select>
                            <div className="btn_add" onClick={handleAddCities}>{variable_10}</div>
                            <div className="basket_cities">
                                {arrayArea.map((c, indx) =>
                                    <div key={indx} className="city">
                                        {c}
                                        <img src={close_tag} onClick={() => {
                                            let newPrev = [...arrayArea];
                                            newPrev = newPrev.filter(t => !t.includes(c));
                                            setArrayArea(newPrev);
                                        }}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="parent">
                        <div className="left_block-filter" style={{width: "16.5%"}}>
                            <span style={{whiteSpace: "nowrap"}}>{variable_11}</span>
                        </div>
                        <div className="right_block-filter" style={{width: "17%"}}>
                            <Row>
                                <div className="btn price">{sliderValue}</div>
                                {/* <div className="apostrophe">-</div>
                            <div className="btn price">{sliderValue[1]}</div>*/}
                            </Row>
                            <div className="slider">
                                <Slider
                                    defaultValue={sliderValue}
                                    min={1}
                                    max={300000}
                                    tipFormatter={null}
                                    onChange={onChange}
                                    handleStyle={{
                                        top: 3,
                                        backgroundColor: "#8146FF",
                                        border: "1px solid white",
                                        boxShadow: "none"
                                    }}
                                    value={sliderValue}
                                    trackStyle={{backgroundColor: "#8146FF", height: 1}}
                                    // value={typeof sliderValue === 'number' ? sliderValue : 0}
                                />
                            </div>
                            <span
                                onClick={() => setActivePrice(0)}
                                style={activePrice === 0 ? {
                                    color: "#333333",
                                    fontSize: 16,
                                    marginTop: 10, cursor: "pointer"
                                } : {
                                    color: "#A2AACA",
                                    fontSize: 16,
                                    marginTop: 10,
                                    cursor: "pointer"
                                }}>
                                {variable_12} 30 000
                            </span>
                            <span
                                onClick={() => setActivePrice(1)}
                                style={activePrice === 1 ? {
                                    color: "#333333",
                                    fontSize: 16,
                                    marginTop: 10, cursor: "pointer"
                                } : {color: "#A2AACA", fontSize: 16, marginTop: 10, cursor: "pointer"}}>{variable_12} 70 000</span>
                            <span
                                onClick={() => setActivePrice(2)}
                                style={activePrice === 2 ? {
                                    color: "#333333",
                                    fontSize: 16,
                                    marginTop: 10, cursor: "pointer"
                                } : {
                                    color: "#A2AACA",
                                    fontSize: 16,
                                    marginTop: 10,
                                    cursor: "pointer"
                                }}>{variable_12} 120 000 </span>
                        </div>
                    </div>
                    <div className="parent">
                        <div className="left_block-filter">
                            <span>{variable_13}</span>
                        </div>
                        <div className="right_block-filter" style={{marginTop: 6}}>
                            <CustomCheckbox
                                value={valuesObject.only_with_salary}
                                setValue={(e) => setValuesObject((prev) => ({...prev, ["only_with_salary"]: e}))}
                                label={variable_14}
                                id={"only_with_salary"}
                            />
                        </div>
                    </div>
                    <div className="parent">
                        <div className="left_block-filter">
                            <span>{variable_15}</span>
                        </div>
                        <div className="right_block-filter">
                            <Radio.Group
                                className={"radio"}
                                value={experienseRadio}
                                style={{display: "flex", flexDirection: "column"}}
                                onChange={(e) => setExperienseRadio(e.target.value)}
                            >
                                <Radio value={1}>{variable_16}</Radio>
                                <Radio value={2}>{variable_17}</Radio>
                                <Radio value={3}>{variable_18}</Radio>
                                <Radio value={4}>{variable_19}</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="parent">
                        <div className="left_block-filter">
                            <span>{variable_20}</span>
                        </div>
                        <div className="right_block-filter">
                            <CustomCheckbox
                                value={valuesCheckboxWork.fullDay}
                                setValue={(e) => setValuesCheckboxWork((prev) => ({...prev, ["fullDay"]: e}))}
                                label={variable_21}
                                id={"fullDay"}
                            />
                            <CustomCheckbox
                                value={valuesCheckboxWork.shift}
                                setValue={(e) => setValuesCheckboxWork((prev) => ({...prev, ["shift"]: e}))}
                                label={variable_22}
                                id={"shift"}
                            />
                            <CustomCheckbox
                                value={valuesCheckboxWork.flexible}
                                setValue={(e) => setValuesCheckboxWork((prev) => ({...prev, ["flexible"]: e}))}
                                label={variable_23}
                                id={"flexible"}
                            />
                            <CustomCheckbox
                                value={valuesCheckboxWork.remote}
                                setValue={(e) => setValuesCheckboxWork((prev) => ({...prev, ["remote"]: e}))}
                                label={variable_24}
                                id={"remote"}
                            />
                            <CustomCheckbox
                                value={valuesCheckboxWork.flyInFlyOut}
                                setValue={(e) => setValuesCheckboxWork((prev) => ({...prev, ["flyInFlyOut"]: e}))}
                                label={variable_25}
                                id={"flyInFlyOut"}
                            />
                        </div>
                    </div>
                    <div className="parent">
                        <div className="left_block-filter">
                            <span>{variable_26}</span>
                        </div>
                        <div className="right_block-filter">
                            <Radio.Group
                                className={"radio"}
                                value={order_by}
                                onChange={(e) => setOrder_by(e.target.value)}
                                style={{display: "flex", flexDirection: "column"}}
                            >
                                <Radio value={1}>{variable_27}</Radio>
                                <Radio value={2}>{variable_28}</Radio>
                                <Radio value={3}>{variable_29}</Radio>
                                <Radio value={4}>{variable_30}</Radio>
                                <Radio value={5}>{variable_31}</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="parent">
                        <div className="left_block-filter">
                            <span>{variable_32}</span>
                        </div>
                        <div className="right_block-filter">
                            <Radio.Group
                                className={"radio"}
                                value={period}
                                onChange={(e) => setPeriod(e.target.value)}
                                style={{display: "flex", flexDirection: "column"}}
                            >
                                <Radio value={1}>{variable_33}</Radio>
                                <Radio value={2}>{variable_34}</Radio>
                                <Radio value={3}>{variable_35}</Radio>
                                <Radio value={4}>{variable_36}</Radio>
                                <Radio value={5}>{variable_37}</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="line_bottom"></div>
                    <div
                        className="btn_bottom"
                        onClick={getApiVacansy}
                    >
                        {variable_38}
                        {loading &&
                            <Spin
                                style={{position: "absolute", left: "50%", top: "-50%", trasform: "translateX(-50%)"}}/>
                        }
                    </div>
                </div>
                <div className={"modal_parent"}>
                    <Modal
                        open={showModalCategory}
                        footer={null}
                        onCancel={() => setShowModalCategory(false)}
                        className={"modal_filter-category"}
                        // width={"716"}
                        closable={false}
                        centered={true}
                        bodyStyle={{padding: 30, backgroundColor: "#201E26", borderRadius: 40}}
                    >
                        <div className="title">
                            <span>{variable_8}</span>
                            <img
                                style={{cursor: "pointer"}}
                                onClick={() => setShowModalCategory(false)}
                                src={close_modal_course}
                            />
                        </div>
                        <Tree
                            checkable
                            onExpand={onExpand}
                            autoExpandParent={autoExpandParent}
                            onCheck={onCheck}
                            checkedKeys={checkedKeys}
                            onSelect={onSelect}
                            selectedKeys={selectedKeys}
                            treeData={industry}
                            className={"tree_antd"}
                            /*switcherIcon={<DownOutlined/>}*/
                        />
                    </Modal>
                </div>
            </Modal>
        </>
    );
};

export default ModalFilter;