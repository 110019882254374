import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import requests from "../../axios/requests";
import { defaultFuncAndData } from "../../../utils/defaultData";
import { constantsResume } from "../Profile/constantsResume";

import { ReactComponent as Arrow } from "../../../images/search_talants/arrow.svg";
import photo_profile from "../../../images/icons/fake_photo.png";
import location from "../../../images/icons/location_resume.svg";
import age from "../../../images/icons/age_resume.svg";
import phone from "../../../images/icons/phone_resume.svg";
import mail from "../../../images/icons/mail_resume.svg";
import roadmap from "../../../images/search_talants/roadmap.svg";

const Resume = () => {
    const params = useParams();
    const history = useNavigate();
    const [data, setData] = useState({});
    const arrMonths = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

    let calcCurrentAge = (dateString) => {
        let today = new Date();
        let birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };
    let getCurrentLevels = (value) => {
        switch (value) {
            case "a1":
                return 1;
            case "a2":
                return 2;
            case "b1":
                return 3;
            case "b2":
                return 4;
            case "c1":
                return 5;
            case "c2":
                return 6;
            default:
                return 1;
        }
    }
    let paramsToString = () => {
        switch (data?.category) {
            case "engineering_it":
                return "Инженерные & IT";
            case "science_medicine":
                return "Наука & Медицина";
            case "languages_copywriting":
                return "Языки & Копирайтинг";
            case "management_marketing":
                return "Менеджмент & Маркетинг";
            case "finance_law":
                return "Финансы & Юриспруденция";
            case "design_creative":
                return "Дизайн & Креатив";
            case "sport":
                return "Спорт";
            default:
                return ""
        }
    };

    useEffect(() => {
        requests.users.get_resume_id(params?.id)
            .then(res => setData(res.data))
            .catch(() => {
                requests.profile.get_profile_resume()
                    .then((res) => setData(res.data))
                    .catch((err) => console.error(err))
            })
    }, [])
    return (
        <div className={"parent_resume"}>
            <div className="resume">
                <div className="left_block">
                    <div className="roadmap">
                        <img src={roadmap}/>
                        <span
                            onClick={() => history("/student/freelance-list/" + data?.category)}
                        >
                        Фрилансеры
                    </span>
                        <Arrow/>
                        <span>{paramsToString()}</span>
                    </div>
                    <div className="photo_block">
                        <img src={data?.photo || photo_profile} className={"img"}/>
                        {data?.city &&
                            <div>
                                <img src={location}/>
                                <span> {data.city} </span>
                            </div>
                        }
                        {data?.birth_date &&
                            <div>
                                <img src={age} style={{marginRight: 8}}/>
                                <span style={{whiteSpace: "nowrap"}}>
                                    Возраст: {calcCurrentAge(data.birth_date)}
                                    {defaultFuncAndData.declension(calcCurrentAge(data.birth_date), [" год", " года", " лет"])}
                                </span>
                            </div>
                        }
                    </div>
                    <div className="info_block">
                        <div className="h4">Основная информация</div>
                        {data?.phone &&
                            <div>
                                <img src={phone} style={{height: 21}}/>
                                <span
                                    style={{whiteSpace: "nowrap"}}
                                >
                                    {data?.phone}
                                </span>
                            </div>
                        }
                        {(data?.contacts?.length > 0 || data?.email) &&
                            <>
                                {data.contacts.map((elem) =>
                                    <div key={elem.id}>
                                        <img src={defaultFuncAndData.viewImg(elem.social_network)}/>
                                        <span>{elem.value} </span>
                                    </div>
                                )}
                                {data?.email &&
                                    <div>
                                        <img src={mail}/>
                                        <span>{data.email} </span>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    {data?.language_skills?.length > 0 &&
                        <div className="languages_block">
                            <div className="h4">Языки</div>
                            {data.language_skills.map((elem, indx) =>
                                <div
                                    className="lang"
                                    key={indx}
                                >
                                    <span className={"span"}>{constantsResume.languages[elem.language]}</span>
                                    <div className="level">
                                        <div className="figure_block">
                                            {Array(6).fill(0).map((elem, indx) =>
                                                <div key={indx} className="figure_off"/>
                                            )}
                                            {Array(getCurrentLevels(elem.level)).fill(0).map((elem, indx) =>
                                                <div key={indx} style={{left: indx * 12}} className="figure_on"/>
                                            )}
                                        </div>
                                    </div>
                                    <div className="current_label-level">
                                        {constantsResume.language_levels[elem.level]}
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    {data?.skills?.length > 0 &&
                        <div className="skills_block">
                            <div className="h4">Навыки</div>
                            <div className="parent_skills">
                                {data.skills.map((elem, indx) =>
                                    <div
                                        style={{whiteSpace: "nowrap"}}
                                        className={"skill"}
                                        key={indx}
                                    >
                                        {elem}
                                    </div>
                                )}
                            </div>
                        </div>
                    }

                </div>
                <div className="right_block">
                    <div className="title">{`${data?.first_name || ""} ${data?.last_name || ""}`} < /div>
                    <div className="name_resume">
                        <span>{data?.position}</span>
                        <span>
                            {data?.salary &&
                                <>
                                    {`${data.salary} ${constantsResume.currencies[data.salary_currency]}/ ${data.rate_type === "month" ? "месяц" : "час"}`}
                                </>
                            }
                        </span>
                    </div>
                    <div style={{fontSize: 14, color: "#A2AACA", marginBottom: 30}}>
                        {data?.willingness_to_travel
                            ? "Готов к коммандировкам"
                            : "Не готов к командировкам"
                        }

                    </div>
                    {data?.work_experiences?.length > 0 &&
                        <>
                            <div className="h4">
                                <span>Опыт работы: </span>
                                <span>{/*14 лет и 3 месяца*/}</span>
                            </div>
                            {data.work_experiences.map((elem, indx) =>
                                <div
                                    className="experience_group"
                                    key={indx}
                                >
                                    <div className="experience">
                                        <span>
                                            {elem?.start_month !== null && arrMonths[elem?.start_month - 1]} {elem?.start_year}
                                            {" "}
                                            –
                                            {" "}
                                            {(!elem?.end_month || !elem?.end_year)
                                                ? "работает сейчас"
                                                : <>
                                                    {arrMonths[elem?.end_month - 1]}
                                                    {" "}
                                                    {elem?.end_year}
                                                </>
                                            }
                                        </span>
                                        <span>
                                            {defaultFuncAndData.countExperience(`${elem.start_year}-${elem.start_month}`, `${elem.end_year}-${elem.end_month}`)}
                                        </span>
                                    </div>
                                    <div className="function_job">
                                        <span>{elem.position} </span>
                                        <span>{elem.organization}</span>
                                        <div className="h4">Обязанности</div>
                                        <ul className="list_job-funtions">
                                            <li>{elem.description}</li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </>
                    }
                    {data?.educations?.length > 0 &&
                        <>
                            <div className="h4">Образование</div>
                            {data.educations.map((elem, indx) =>
                                <div
                                    className="education_group"
                                    key={indx}
                                >
                                    <div className="type_education">
                                        <span>{elem.end_year}</span>
                                        <span> {constantsResume.education_levels[elem.level]} </span>
                                    </div>
                                    <div className="info_education">
                                        <span>{elem.institution}</span>
                                        <span>Факультет: {elem.faculty}</span>
                                        <span>Специальность: {elem.speciality}</span>
                                    </div>
                                </div>
                            )}
                        </>
                    }
                    {data?.passed_quests?.length > 0 &&
                        <>
                            <div className="h4">Курсы</div>
                            {data.passed_quests.map((elem, indx) =>
                                <div
                                    className="course_group"
                                    key={indx}
                                >
                                    <div className="type_course">
                                        <span>
                                            {elem.passed_date
                                                ? moment(elem.passed_date).format("YYYY")
                                                : "2022"
                                            }
                                        </span>
                                    </div>
                                    <div className="info_course">
                                        <span>{elem.organization  || elem?.name}</span>
                                        <span>{elem?.description}</span>
                                    </div>
                                </div>
                            )}
                        </>
                    }
                    {data?.portfolio?.length > 0 &&
                        <>
                            <div className="h4">Портфолио</div>
                            <div className="portfolio_group">
                                {data.portfolio.map((elem, indx) =>
                                    <span key={indx}>{elem}</span>
                                )}
                            </div>
                        </>
                    }
                    {data?.about_me &&
                        <>
                            <div className="h4">О себе</div>
                            <div className="about_group">
                                <span>{data.about_me}</span>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default Resume;