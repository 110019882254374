const defaultSkillOptions = (speciality_id) => {
    // 1 Спорт
    // 2 Наука & Медицина
    // 3 Менеджмент & Маркетинг
    // 4 Инженерные & IT
    // 5 Языки & Копирайтинг
    // 6 Дизайн & Креатив
    // 7 Финансы & Юриспруденция
    return [
        ['java', 'python', 'javascript', 'sql', 'tcp/ip', 'autocad', 'linux', '3d моделирование'],
        ['научная деятельность', 'научные исследования', 'медицинское оборудование', 'пцр', 'практическая медицина', 'навыки межличностного общения'],
        ['английский язык', 'редактирование текстов', 'написание пресс-релизов', 'литературное редактирование', 'написание сценариев', 'написание научных статей'],
        ['активные продажи', 'организаторские навыки', 'smm', 'crm', 'маркетинговый анализ', 'анализ данных', 'оптимизация бизнес процессов'],
        ['закупки', '1с:бухгалтерия', 'ценообразование', 'юридическая поддержка', 'финансовое планирование'],
        ['Adobe photoshop', 'adobe illustrator', 'figma', 'coreldraw', 'графический дизайн', 'дизайн интерьера', '3d-графика'],
        ['фитнес', 'спортивное питание', 'физическая выносливость', 'спортивные мероприятия', 'индивидуальные тренировки'],
    ][speciality_id]
}

export default defaultSkillOptions