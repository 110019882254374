import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, List, message as messageWarn, Statistic, Typography } from "antd";
import requests from "../../../axios/requests";
import QRCode from "react-qr-code";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";

function useInterval(callback, delay) {
    const savedCallback = useRef(callback);

    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        if (delay === null) {
            return;
        }
        const id = setInterval(() => savedCallback.current(), delay);
        return () => clearInterval(id);
    }, [delay]);
}
export const QrAuth = (props) => {
    const {
        setName, setMessage, setCurrent, variables_2, variables_3, variables_4_1,
        variables_4_2, variables_5, variables_25, variables_7, variables_11
    } = props;
    const history = useNavigate();

    const [qrTimeout, setQrTimeout] = useState(false);
    const [qrCode, setQrCode] = useState("");
    const deadline2 = Date.now() + 1000 * 80;

    const validate = (data, stepCount) => {
        setName(data.name);
        if (data.user_exists === true) {
            if (window.localStorage.getItem("previous_course_link")) {
                window.localStorage.setItem("token", data.auth_token);
                window.localStorage.setItem("username", data.name);
                window.localStorage.setItem("last_name", data.last_name);
                window.localStorage.setItem("middle_name", data.middle_name);
                history(window.localStorage.getItem("previous_course_link"));
                window.localStorage.removeItem("previous_course_link");
                return;
            }
            setCurrent(stepCount);
            window.localStorage.setItem("token", data.auth_token);
            window.localStorage.setItem("username", data.name);
            window.localStorage.setItem("last_name", data.last_name);
            window.localStorage.setItem("middle_name", data.middle_name);
        } else {
            setCurrent(1);
            messageWarn.warning(variables_25);
            setMessage(data.errors[0]);
        }
    };
    const qrPoll = () => {
        if (!qrCode) return;
        requests.auth.qr_login({code: qrCode})
            .then((v) => {
                if (v.status === 204) {
                }
                if (v.status === 200) validate(v.data, 4);
            });
    };

    useInterval(() => qrPoll(), qrTimeout ? null : 1500);
    useEffect(() => {
        if (qrTimeout) {
            setQrCode("");
            return;
        }
        requests.auth.qr_create_session().then((v) => {
            setQrCode(v.data.code);
            qrPoll(v.data);
        });
    }, [qrTimeout]);

    const QrCodeSteps = [
        <span style={{color: "white", fontSize: 18}}>{variables_3}</span>,
        <span style={{color: "white", fontSize: 18}}>{variables_4_1} <span
            style={{fontWeight: "bold"}}>{variables_4_2}</span></span>,
        <span style={{color: "white", fontSize: 18}}>{variables_5}</span>
    ];

    return (
        <>
            <div className={"qr_block"}
                 style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", zIndex: 3}}>
                {!qrTimeout && <div className="qrCodeList" style={{borderBottom: "1px solid #343843"}}>
                    <List
                        style={{flex: "1 1 250px", margin: "20px 20px 10px 0", color: "white"}}
                        header={<h4 style={{color: "white", fontSize: 18}}>{variables_2}</h4>}
                        dataSource={QrCodeSteps}
                        renderItem={(item, i) => (
                            <List.Item key={i}>
                                <Typography.Text>{item}</Typography.Text>
                            </List.Item>
                        )}
                    />
                    <div style={{flex: "0 0 auto", margin: "20px"}}>
                        <QRCode value={qrCode}/>
                    </div>
                </div>}
                <div className="qrCodeList">
                    <Statistic.Countdown
                        title={variableLanguage({keyPage: "authorization", keyVariable: "variables_11"})}
                        value={deadline2}
                        valueStyle={{color: "white", fontSize: 32}}
                        onFinish={() => {
                            setQrTimeout(true);
                        }}
                        style={qrTimeout ? {
                            opacity: 0,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            transform: "scale(0)"
                        } : {}}
                    />
                    {qrTimeout &&
                        <Button
                            className={"button_classic"}
                            style={{marginTop: "20px"}}
                            onClick={() => setQrTimeout(false)}
                        >
                            {variables_11}
                        </Button>
                    }
                </div>
            </div>
            <div className={"line_ore"}>
                <div className="line"/>
                <span>{variables_7}</span>
                <div className="line"/>
            </div>
        </>
    );
};

export default QrAuth;