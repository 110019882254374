export const resizeWindow = (payload) => ({type: 'RESIZE_WINDOW', payload})
export const setScrollDirection = (payload) => ({type: 'SCROLL_DIRECTION', payload})
export const setIsLandingWeb = (payload) => ({type: 'SET_IS_LANDING_WEB', payload})
export const setConfig = (payload) => ({type: 'SET_CONFIG', payload})
export const setRange = (payload) => ({type: 'SET_RANGE', payload})
export const setCollapse = (payload) => ({type: 'SET_COLLAPSE', payload})
export const setFlagSteps = (payload) => ({type: 'SET_FLAG_STEPS', payload})
export const setScrollUp = (payload) => ({type: 'SET_SCROLL_UP', payload})
export const setShowBuyModal = (payload) => ({type: 'SET_SHOW_GENERATE_BUY', payload})

//экшены для скиллсета
export const setSelectedTag = (payload) => ({type: 'SET_SELECTED_TAG', payload})
export const setLoadingGpt = (payload) => ({type: 'SET_LOADING_GPT', payload})
export const setLoadingIndx = (payload) => ({type: 'SET_LOADING_INDX', payload})
//экшены для скиллсета и каталога
export const setUseFilterSkills = (payload) => ({type: 'SET_USE_FILTER_SKILLS', payload})