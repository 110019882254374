import React, {useState} from "react";
import { message } from "antd";
import Button from "../../../components/Button/Button";
import vk_icon from "./../../../../images/new_auth/vk_icon.svg"
import yandex_icon from "./../../../../images/new_auth/yandex_icon.svg"
import "./AuthForm.scss"
import cn from "classnames";
import requests from "../../../axios/requests";

const AuthForm =  ({showSteps}) => {

    const [mode, setMode] = useState('auth')// reg
    const [variant, setVariant] = useState('email')// phone
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [emailConfirm, setEmailConfirm] = useState(false)
    const [phoneConfirm, setPhoneConfirm] = useState(false)
    const [phoneConfirmApproved, setPhoneConfirmApproved] = useState(false)
    // const [emailConfirmApproved, setEmailConfirmApproved] = useState(false)
    const [emailConfirmCode, setEmailConfirmCode] = useState('')
    const [phoneConfirmCode, setPhoneConfirmCode] = useState('')

    const loginHandler = () => {
        if (mode === 'auth') {
            if (variant === 'email') {
                if (emailConfirm) {
                    console.log('Проверяем, редиректим 1')
                    // setEmailConfirmApproved(true)
                } else {
                    console.log('Отправляем почту, ждем код 1')
                    setEmailConfirm(true)
                }
            } else if (variant === 'phone') {
                if (phoneConfirm) {
                    console.log('Указываем почту 2')
                    requests.users.validate_sms({phone: phone, code: phoneConfirmCode})
                        .then(({data}) => {
                            if (!data.errors) {
                                setPhoneConfirmApproved(true)
                            } else message.warn("Неверный код")
                        })
                } else {
                    console.log('Отправляем номер, получаем смс 3')
                    requests.users.request_sms({phone})
                        .then(({data}) => {
                            if (data?.result === "sent" || data?.status === "success") setPhoneConfirm(true)
                        })
                }
            }
        } else if (mode === 'reg') {
            if (emailConfirm) {
                console.log('Вводим код, если все ок, то переходим к шагам 5')
                showSteps()
            } else {
                console.log('Отправляем почту,получаем код 5')
                setEmailConfirm(true)
            }

        }
    }

    const inputHandler = (e) => {
        if (variant === 'email') setEmail(e.target.value)
        if (variant === 'phone') setPhone(e.target.value)
    }

    const confirmHandler = () => {
        // if (mode === 'auth') {
        //     if (variant === 'email') {
        //         setEmailConfirm(true)
        //     } else if (variant === 'phone') {
        //         setPhoneConfirm(true)
        //     }
        // } else if (mode === 'reg') {
        //     if (variant === 'email') {
        //         setEmailConfirm(true)
        //     } else if (variant === 'phone') {
        //         setPhoneConfirm(true)
        //     }
        // }
    }

    const inputConfirmHandler = (e) => {
        if (e.target.name === 'email') setEmail(e.target.value)
        if (variant === 'email') setEmailConfirmCode(e.target.value)
        if (variant === 'phone') setPhoneConfirmCode(e.target.value)
    }

    const getLabel = () => {
        if (phoneConfirm) return "Мы отправили на номер телефона смс-код для подтверждения входа"
        if (emailConfirm) return "Мы отправили на почту код для подтверждения входа"
        if (mode === 'reg') return ''
        return "Выберите способ входа в аккаунт"
    }

    return (
        <div className="AuthForm">
            <div className="title">{mode === "auth" ? "Авторизация" : "Регистрация"}</div>
            <div className="label">{getLabel(mode,variant)}</div>

            {mode === "auth" && !emailConfirm && !phoneConfirm && <div className="variant" onClick={() => setVariant(variant === 'email' ? 'phone' : 'email')}>
                <div className="auth-item email">По email</div>
                <div className="auth-item phone">По номеру телефона</div>
                <div className={cn("figure", {email: variant === 'email', phone: variant === 'phone'})}/>
            </div>}


            { (phoneConfirm || emailConfirm)
                ? phoneConfirmApproved
                    ? <input
                        type="email"
                        name="email"
                        className="auth-item input"
                        value={email}
                        onChange={inputConfirmHandler}
                        placeholder={'Введите адрес эллектронной почты_'}
                    />
                    : <input
                        className="auth-item input"
                        value={emailConfirm ? emailConfirmCode : phoneConfirmCode}
                        onChange={inputConfirmHandler}
                        placeholder={variant === 'email' ? 'Введите код из письма' : 'Введите код из смс'}
                    />
                : <input
                    type="email"
                    autoComplete="email"
                    name="email"
                    className="auth-item input"
                    value={variant === 'email' ? email : phone}
                    onChange={inputHandler}
                    placeholder={variant === 'email' ? 'Введите адрес эллектронной почты' : 'Введите номер'}
                />
            }

            <Button
                text={'Войти'}
                click={loginHandler}
                zIndex={3}
                className={'AuthFormBtn'}
            />

            {!emailConfirm && !phoneConfirm && <>
                <div className="center">Или</div>
                <div className="auth-item pointer">
                    <span className="auth-redirect">Войти через VK <img src={vk_icon} alt="vk_icon"/></span>
                </div>
                <div className="auth-item pointer">
                    <span className="auth-redirect">Войти через Яндекс <img src={yandex_icon} alt="yandex_icon"/></span>
                </div>
            </>}

            {(emailConfirm || phoneConfirm) && <div className="center violet pointer" onClick={() => {
                setPhoneConfirm(false)
                setEmailConfirm(false)
                setPhoneConfirmApproved(false)
                // setEmailConfirmApproved(false)
            }}>
                Код не пришел? Запросить заново
            </div>}

            <div className="center violet pointer" onClick={() => {
                if (mode === "auth") setVariant("email")
                setMode(mode === "auth" ? "reg" : "auth")
                setEmailConfirm(false)
                setPhoneConfirm(false)
                setPhoneConfirmApproved(false)
                // setEmailConfirmApproved(false)
            }}>
                {mode === "auth" ? "Нет аккаунта? Зарегистрируйтесь" : "Есть аккаунт? Войти в существующий"}
            </div>
        </div>
    )
}

export default AuthForm