import React, { useEffect, useState } from 'react';
import requests from "../../../axios/requests";
import { message, Select, Spin, Tag } from "antd";
import { defaultFuncAndData } from "../../../../utils/defaultData";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";
import defaultSkillOptions from "../../../../utils/defaultSkillOptions";
import Button from "../../../components/Button/Button";
import FreelancerCard from "../../SearchTalants/FreelancerCard/FreelancerCard";

import { ReactComponent as Arrow } from "../../../../images/arrow.svg";
import fake_photos from "../../../../images/fake_photos.png";
import add_activity_bottom from "../../../../images/auth/add_skill_dark.svg";
import it_icon from "../../../../images/icons/it_icon.svg";
import medical_icon from "../../../../images/icons/medical_icon.svg";
import languages_icon from "../../../../images/icons/languages_icon.svg";
import menegement_icon from "../../../../images/icons/menegement_icon.svg";
import finance_icon from "../../../../images/icons/finance_icon.svg";
import design_icon from "../../../../images/icons/design_icon.svg";
import sport_icon from "../../../../images/icons/sport_icon.svg";
import './Step_3.scss';
import { useSelector } from "react-redux";

const Step3 = (props) => {
    const {
        tagsStep3, activeActivity, validateSelectSkills, freelanceStep,
        setActiveActivity, setTagsStep3, setFreelanceStep,
        suggestedSelectOptions, setCurrent, name, image, loadingSkills,

    } = props;
    const variables_12 = variableLanguage({keyPage: "authorization", keyVariable: "variables_12"});
    const variables_29 = variableLanguage({keyPage: "authorization", keyVariable: "variables_29"});
    const variables_30 = variableLanguage({keyPage: "authorization", keyVariable: "variables_30"});
    const variables_31 = variableLanguage({keyPage: "authorization", keyVariable: "variables_31"});
    const variables_32 = variableLanguage({keyPage: "authorization", keyVariable: "variables_32"});
    const variables_33 = variableLanguage({keyPage: "authorization", keyVariable: "variables_33"});
    const variables_34 = variableLanguage({keyPage: "authorization", keyVariable: "variables_34"});
    const variables_35 = variableLanguage({keyPage: "authorization", keyVariable: "variables_35"});
    const variables_36 = variableLanguage({keyPage: "authorization", keyVariable: "variables_36"});
    const variables_37 = variableLanguage({keyPage: "authorization", keyVariable: "variables_37"});
    const variables_38 = variableLanguage({keyPage: "authorization", keyVariable: "variables_38"});
    const variables_39 = variableLanguage({keyPage: "authorization", keyVariable: "variables_39"});
    const variables_40 = variableLanguage({keyPage: "authorization", keyVariable: "variables_40"});
    const variables_41 = variableLanguage({keyPage: "authorization", keyVariable: "variables_41"});

    const {trajectorySkills} = useSelector(state => state.Reducer);
    const [flagDropDownActivity, setFlagDropDownActivity] = useState(false);
    const [openListSkills, setOpenListSkills] = useState(null);
    const [valueInputTag, setValueInputTag] = useState("");
    const [existTagsList, setExistTagsList] = useState([]);
    const [flagOpenSelectTags, setFlagOpenSelectTags] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [timeoutSearch, setTimeoutSearch] = useState(null);
    const [timeoutFetchPagination, setTimeoutFetchPagination] = useState(null);
    const [loading, setLoading] = useState(false);

    const arrActivity = [
        <div>
            <img src={it_icon}/>
            <span> {variables_30}</span>
        </div>,
        <div>
            <img src={medical_icon}/>
            <span>{variables_31}</span>
        </div>,
        <div>
            <img src={languages_icon}/>
            <span>{variables_32}</span>
        </div>,
        <div>
            <img src={menegement_icon}/>
            <span>{variables_33}</span>
        </div>,
        <div>
            <img src={finance_icon}/>
            <span>{variables_34}</span>
        </div>,
        <div>
            <img src={design_icon}/>
            <span>{variables_35}</span>
        </div>,
        <div>
            <img src={sport_icon}/>
            <span>{variables_36}</span>
        </div>
    ];

    let fetchSearchTags = (page_size) => {
        setLoading(true);
        requests.profs.get_speciality_tags(valueInputTag, 1, page_size || pageSize)
            .then((v) => setExistTagsList(v.data))
            .finally(() => setLoading(false))
    }

    let tagRender = (props) => {
        const {label, closable} = props;
        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={() => {
                    let newArr = [...tagsStep3];
                    newArr = newArr.filter(tag => tag !== label);
                    setTagsStep3(newArr)
                    requests.skills.delete_user_skill({speciality: specialityId(), skill: label})
                        .catch(err => console.error(err))
                }}
                style={{
                    marginRight: 3,
                    marginBottom: 5,
                    color: "#FFD23B",
                    fontSize: 12,
                    background: "rgba(255, 210, 59, 0.2)",
                    lineHeight: 2.1,
                    border: "none"
                }}
            >
                {label}
            </Tag>
        );
    };
    let specialityId = () => {
        switch (activeActivity) {
            case 0:
                return 4;
            case 1:
                return 2;
            case 2:
                return 5;
            case 3:
                return 3;
            case 4:
                return 7;
            case 5:
                return 6;
            case 6:
                return 1;
        }
    };
    let activeColorList = () => {
        switch (activeActivity) {
            case 0:
                return "#8146FF";
            case 1:
                return "#3077FF";
            case 2:
                return "#30E0A1";
            case 3:
                return "#FFD23B";
            case 4:
                return "#FF47ED";
            case 5:
                return "#FF3434";
            case 6:
                return "#FF5937";
            default:
                return "white";
        }
    };
    let addTags = (name) => {
        for (let i = 0; i < tagsStep3.length; i++) {
            if (tagsStep3[i] === name)
                return;
        }
        /*if (!validateSelectSkills(name)) return;*/ // не  добавлять скилл если его нет в предложенных
        setTagsStep3(prev => [...prev, name]);
        let objAddTag = {
            skill: name,
            speciality: specialityId()
        };
        requests.skills.add_user_skills(objAddTag)
            .then((res) => {
                if (res.data?.detail) {
                    if (res.data.detail.includes("Вы уже получили такой навык, при прохождении курсов")) {
                        message.warning(res.data.detail)
                    } else if (res.data.detail.includes("Вы уже добавили такой навык в одной из веток")) {
                        message.warning(res.data.detail)
                    } else {
                        message.success("Навык успешно добавлен")
                    }
                }
            })
            .catch((e) => console.error(e));
    };

    useEffect(() => {
        if (valueInputTag.length > 1) {
            let lastSymbol = valueInputTag[valueInputTag.length - 1];
            if (lastSymbol !== " ") {
                setPageSize(10)
                setExistTagsList([]);
                if (timeoutSearch)
                    clearTimeout(timeoutSearch)
                setTimeoutSearch(setTimeout(() =>
                    fetchSearchTags(10), 500))
            }
        }
    }, [valueInputTag])
    return (
        <>
            {!freelanceStep &&
                <div className="step_3">
                    <div
                        className={!flagDropDownActivity && flagOpenSelectTags
                            ? "top_block active"
                            : "top_block"
                        }
                    >
                        <div className="left_block">
                            <p> {variables_29}</p>
                            <div
                                style={activeActivity === 2 || activeActivity === 3
                                    ? {color: "#343843", backgroundColor: `${activeColorList()}`}
                                    : {backgroundColor: `${activeColorList()}`, color: "white"}
                                }
                                onClick={() => setFlagDropDownActivity(!flagDropDownActivity)}
                                className={flagDropDownActivity ? "drop_down open" : "drop_down "}
                            >
                                {arrActivity[activeActivity]}
                                <div className={flagDropDownActivity ? "arrow tranform" : "arrow"}>
                                    <Arrow/>
                                </div>
                                <div
                                    style={{
                                        backgroundColor: `${activeColorList()}`,
                                        transform: "scale(0)",
                                        opacity: 0
                                    }}
                                    className={flagDropDownActivity ? "drop_down-list active" : "drop_down-list"}>
                                    <ul>
                                        {arrActivity.map((elem, index) => {
                                                if (index !== activeActivity) {
                                                    return (
                                                        <li
                                                            key={index}
                                                            onClick={() => setActiveActivity(index)}
                                                        >
                                                            {elem}
                                                        </li>
                                                    )
                                                } else
                                                    return ""
                                            }
                                        )}
                                    </ul>
                                </div>
                            </div>
                            {
                                flagDropDownActivity &&
                                <div
                                    style={{width: "100vw", height: "100vh", left: 0, top: 0}}
                                    className="overlay"
                                    onClick={() => setFlagDropDownActivity(false)}
                                />
                            }
                        </div>
                        <div
                            className="right_block"
                            onClick={() => setFlagDropDownActivity(false)}
                        >
                            <p>{variables_29}</p>
                            <Select
                                mode="tags"
                                className={flagOpenSelectTags ? "activity_select active" : "activity_select"}
                                value={tagsStep3}
                                onSearch={setValueInputTag}
                                onSelect={(e) => addTags(e)}
                                tagRender={tagRender}
                                onDropdownVisibleChange={(e) => {
                                    if (!flagOpenSelectTags)
                                        setFlagDropDownActivity(false);
                                    setFlagOpenSelectTags(e);
                                }}
                                dropdownStyle={{
                                    backgroundColor: "#343843", padding: 0, color: "white",
                                    borderRadius: 0
                                }}
                                dropdownAlign={{
                                    offset: [1, -5]
                                }}
                                showSearch
                                placeholder={variables_38}
                                onPopupScroll={async (e) => {
                                    const {target} = e;
                                    if (
                                        target.scrollTop
                                        + target.offsetHeight ===
                                        target.scrollHeight
                                    ) {
                                        if ((existTagsList?.count > pageSize)) {
                                            setTimeoutFetchPagination(
                                                setTimeout(() => {
                                                    setPageSize(prev => prev + 10);
                                                    fetchSearchTags(pageSize + 10)
                                                }, 500)
                                            )
                                        }
                                    }
                                }}
                            >

                                <Select.OptGroup label={variables_39}>
                                    {existTagsList?.results?.map((tag) =>
                                        <Select.Option
                                            key={tag.id}
                                            value={tag.name}
                                        >
                                            {tag.name}
                                        </Select.Option>)
                                    }
                                </Select.OptGroup>
                                <Select.OptGroup label={variables_40}>
                                    {suggestedSelectOptions?.map((option, i) =>
                                        <Select.Option
                                            key={i}
                                            value={option}
                                        >
                                            {option}
                                        </Select.Option>)
                                    }
                                </Select.OptGroup>
                            </Select>
                        </div>
                        {loadingSkills &&
                            <Spin className="spin_loading"/>
                        }
                    </div>
                    <div className="popular">
                            <span> {variables_37}
                                "{defaultFuncAndData.categoryArray()[activeActivity].text}"
                            </span>
                    </div>
                    <div className="bottom_block">
                        <div className="bottom_tags">
                            {defaultSkillOptions(activeActivity).map((tag, indx) =>
                                <div
                                    key={indx}
                                    onClick={() => addTags(tag)}
                                    style={{cursor: "pointer", zIndex: 2}}
                                >
                                    {tag}
                                    <img src={add_activity_bottom}/>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="btn_block">
                        <Button
                            text={variables_41}
                            click={() => setCurrent(1)}
                            maxWidth={"120px"}
                            margin={"0 15px 0 0"}
                            padding={"8px 9px 9px 9px"}
                            backgroundColor={"var(--background_lighten-dark)"}
                            boxShadow={"none"}
                            zIndex={3}
                        />
                        <Button
                            text={variables_12}
                            click={() => {
                                if (tagsStep3.length > 0)
                                    setFreelanceStep(true);
                                else
                                    setCurrent(3)
                            }}
                            maxWidth={"305px"}
                            zIndex={3}
                        />
                    </div>
                </div>
            }
            {
                freelanceStep &&
                <>
                    <div className="freelancers_list-auth">
                        <FreelancerCard
                            indx={0}
                            name={name}
                            photo={image || fake_photos}
                            salary={"Желаемая зарплата"}
                            position={"Укажите специализацию"}
                            openListSkills={openListSkills === 0}
                            inAuth={true}
                            setOpenListSkills={(indx) => {
                                if (openListSkills === indx)
                                    setOpenListSkills(null)
                                else setOpenListSkills(indx)
                            }}
                            skills={tagsStep3 || []}
                            skillsResume={[]}
                        />
                    </div>
                    <div className="btns_navigate">
                        <Button
                            text={variables_41}
                            click={() => setFreelanceStep(false)}
                            maxWidth={"135px"}
                            margin={"0 15px 0 0"}
                            padding={"8px 9px 9px 9px"}
                            backgroundColor={"var(--background_lighten-dark)"}
                            boxShadow={"none"}
                            zIndex={10}
                        />
                        <Button
                            text={variables_12}
                            click={() => setCurrent(3)}
                            maxWidth={"305px"}
                            zIndex={10}
                        />
                    </div>
                </>
            }
        </>
    );
}

export default Step3;