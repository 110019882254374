import React, { useEffect, useState } from "react";
import verical_line_chart from "../../../images/icons/vertical_line-chart.png";

const VerticalProgress = ({progress, percent, name, setActiveVacancy, activeVacancy, elem}) => {
    let flagStyle = typeof activeVacancy !== "undefined";
    let handleActiveId = () => {
        setActiveVacancy(elem);
        const element = document.getElementById(elem.id)
        if (element) {
            element.scrollIntoView({behavior: "smooth", block: "center"});
        }
    };
    return (
        <div
            className={elem.id !== null && flagStyle && activeVacancy?.id === elem.id ? "progress vertical blue" : "progress vertical"}
            onClick={handleActiveId}
            style={{cursor: "pointer"}}
        >
            <div style={{height: `${percent}%`}} className={percent < 5 ? "progress-bar hidden" : "progress-bar"}>
                <span className="sr-only">{/*{`${progress}%`}*/}</span>
            </div>
            <div className="name_employer" style={{position: "absolute"}}>{name}</div>
        </div>
    );
};

const ParentBlockProgressVertical = (props) => {
    let {allJobs, setActiveVacancy, activeVacancy} = props;
    const [salaryArray, setSalaryArray] = useState([]);
    const [copySalary, setCopySalary] = useState([]);

    let calcSalary = (elem) => {
        if (elem.salary && elem.salary.average) {
            if (elem.salary.currency === "RUR")
                return elem.salary.average + " ₽";
            if (elem.salary.currency === "EUR")
                return elem.salary.average + " €";
            if (elem.salary.currency === "USD")
                return elem.salary.average + " $";
            return "";
        }
        return 0;
    };
    let calcVerticalPercent = (indx) => {
        let count = copySalary.filter((elem) => {
            if (typeof elem.salary.average !== 'undefined') {
                return elem
            }
        }).length - 1;
        let countAdaptive = 0;
        const height = 380;
        let procent = ((height / count) + countAdaptive) / height * 100;
        if (indx === count) {
            return 100; // если последний график - всегда 100% высота
        }
        if (indx === 0) {
            return 0; // если первый график - всегда 0% высота
        }
        return Math.round(procent * indx);
    };

    useEffect(() => {
        if (allJobs && allJobs.length > 0) {
            let newArr = [...allJobs];
            // в вакансии может быть ЗП "от n" и "до n".
            // Что-бы адекватно сортировать, складываются эти цифры в среднее,
            // и в дальнейшем сортируются по этому ключу salary.average
            for (let i = 0; i < newArr.length; i++) {
                let salary = newArr[i].salary;
                if (salary) {
                    if (salary.from && salary.to) {
                        newArr[i].salary.average = (salary.from + salary.to) / 2;
                        continue;
                    }
                    if (salary.to) {
                        newArr[i].salary.average = salary.to;
                        continue;
                    }
                    if (salary.from)
                        newArr[i].salary.average = salary.from;
                } else {
                    delete newArr[i];
                }
            }

            // сортировка по среднему ключу salary.average , если он есть.
            newArr = newArr.sort((a, b) => {
                if (a.salary && b.salary) {
                    /* среднее по usd */
                    if (a.salary.currency === "USD" && b.salary.currency === "USD") {
                        if (a.salary.average < b.salary.average)
                            return 1;
                        if (a.salary.average > b.salary.average)
                            return -1;
                    }
                    if (b.salary.currency === "USD" && a.salary.currency === "USD") {
                        if (b.salary.average < a.salary.average)
                            return 1;
                        if (b.salary.average > a.salary.average)
                            return -1;
                    }
                    /* --- */

                    if (a.salary.currency === "USD" || a.salary.currency === "EUR") {
                        if (a.salary.average * 90 < b.salary.average)
                            return 1;
                        if (a.salary.average * 90 > b.salary.average)
                            return -1;
                    }

                    if (b.salary.currency === "USD" || b.salary.currency === "EUR") {
                        if (a.salary.average < b.salary.average * 90)
                            return 1;
                        if (a.salary.average > b.salary.average * 90)
                            return -1;
                    }

                    if (a.salary.average < b.salary.average)
                        return 1;
                    if (a.salary.average > b.salary.average)
                        return -1;
                }
                return 0;
            });
            newArr = newArr.filter(Boolean);
            newArr = newArr.map((elem) => {
                if (elem.salary.average !== 'undefined')
                    return elem
            });
            //newArr = newArr.sort((a, b) => b.salary.average - a.salary.average);
            setSalaryArray(newArr);
            setCopySalary(newArr)
        }
    }, [allJobs]);
    useEffect(() => {
        let newArr = JSON.parse(JSON.stringify(salaryArray));
        newArr = newArr.reverse();
        /*newArr.sort((a, b) => a.salary.average - b.salary.average);*/
        setCopySalary(newArr);
    }, [salaryArray])
    return (
        <div style={{position: "relative"}}>
            <div className="analytics_block-vertical">
                <div className="block_diapazon">
                    {salaryArray.filter(elem => elem.salary.average).map((elem, indx) => {
                            return (
                                <React.Fragment key={indx}>
                                    <div className={"line_row"}>
                                        <div className="company">{calcSalary(elem)}</div>
                                        <img src={verical_line_chart}/>
                                    </div>
                                </React.Fragment>
                            );
                        }
                    )}
                </div>
                <div className={"vertical_progress"}>
                    {copySalary.filter(elem => elem.salary.average).map((elem, indx) => {
                            return (
                                <VerticalProgress
                                    percent={calcVerticalPercent(indx)}
                                    name={elem.employer.name}
                                    elem={{...elem}}
                                    setActiveVacancy={setActiveVacancy}
                                    activeVacancy={activeVacancy}
                                    key={indx}
                                />
                            );
                        }
                    )}
                </div>
            </div>
        </div>
    );
};

export default ParentBlockProgressVertical;