import React from 'react';
import cn from "classnames";

import './Input.scss';
import variableLanguage from "../../../utils/hookGetVariablesLanguage";
import search_icon from "../../../images/search_talants/search.svg";
import search_mobile from "../../../images/icons/search_icon.svg";

export const Input = (props) => {
    const variable_20 = variableLanguage({keyPage: 'searchTalants', keyVariable: 'variable_20'})

    const {
        maxWidth, autoFocus, refProps, value, setValue, placeholder,
        onKeyDown, padding, backgroundColor, borderRadius, border,
        zIndex, margin, onBlur, disabled, error, fontSize, onFocus,
        needFindBtn, needFindIconMobile, marginParent, color, maxWidthParent,
        widthParent, type, textAlign, min, max
    } = props;

    return (
        <div
            className="input_parent"
            style={{
                ...(marginParent && {margin: marginParent}),
                ...(maxWidthParent && {maxWidth: maxWidthParent}),
                ...(widthParent && {width: widthParent}),
            }}
        >
            <input
                className={cn("input_custom", {
                    disabled,
                    error,
                })}
                min={min}
                max={max}
                type={type}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                style={{
                    ...(maxWidth && {maxWidth}),
                    ...(padding && {padding}),
                    ...(backgroundColor
                        ? {backgroundColor}
                        : {backgroundColor: "var(--dark)"}),
                    ...(borderRadius
                        ? {borderRadius}
                        : {borderRadius: "16px"}),
                    ...(border
                        ? {border}
                        : {border: "1px solid var(--background_lighten-dark)"}),
                    ...(zIndex
                        ? {zIndex}
                        : {}),
                    ...(margin
                        ? {margin}
                        : {}),
                    ...(fontSize
                        ? {fontSize}
                        : {}),
                    ...(color
                        ? {color}
                        : {}),
                    ...(textAlign
                        ? {textAlign: "center"}
                        : {})

                }}
                ref={refProps}
                autoFocus={autoFocus || false}
                disabled={disabled}
                placeholder={placeholder || ""}
                onBlur={() => {
                    if (typeof onBlur === "function")
                        onBlur();
                }}
                onFocus={() => {
                    if (typeof onFocus === "function")
                        onFocus()
                }}
                onKeyDown={(e) => {
                    if (typeof onKeyDown === "function")
                        onKeyDown(e)
                }}
            />
            {needFindIconMobile &&
                <img src={search_mobile} className="icon_find-mobile"/>
            }
            {needFindBtn &&
                <>
                    <img src={search_icon} className="icon_find"/>
                    <div className="btn_find">
                        <span>{variable_20}</span>
                    </div>
                </>
            }
        </div>
    );
};

export default Input;