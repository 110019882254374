import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Tag} from "antd";
import Step_2 from "../../Auth/Step_2/Step_2";
import Step_3 from "../../Auth/Step_3/Step_3";
import Step_4 from "../../Auth/Step_4/Step_4";
import {defaultFuncAndData} from "../../../../utils/defaultData";
import defaultSkillOptions from "../../../../utils/defaultSkillOptions";
import requests from "../../../axios/requests";
import "./StepsContainer.scss"
import ModalWelcome from "../../Auth/ModalWelcome/ModalWelcome";

const StepsContainer =  () => {

    const {language, user} = useSelector(state => state.Reducer);

    const [current, setCurrent] = useState(3);
    const [fio, setFio] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [image, setImage] = useState("");

    const [tagsStep3, setTagsStep3] = useState([]);
    const [activeActivity, setActiveActivity] = useState(0);
    const [freelanceStep, setFreelanceStep] = useState(false);
    const [existTagsList, setExistTagsList] = useState([]);
    const [loadingSkills, setLoadingSkills] = useState(false);
    const [valueInputTag, setValueInputTag] = useState("");
    const [suggestedSelectOptions, setSuggestedSelectOptions] = useState([]);
    const [flagOpenSelectTags, setFlagOpenSelectTags] = useState(false);

    let validateSelectSkills = (name) => {
        let haveSkillInList = false;
        for (let i = 0; i < defaultFuncAndData.arrTags().length; i++) {
            if (defaultFuncAndData.arrTags()[i].name === name)
                haveSkillInList = true;
        }
        for (let i = 0; i < defaultSkillOptions(activeActivity).length; i++) {
            if (defaultSkillOptions(activeActivity) === name)
                haveSkillInList = true;
        }
        for (let i = 0; i < defaultFuncAndData.arrTagsAllPopular().length; i++) {
            if (defaultFuncAndData.arrTagsAllPopular()[i].name === name)
                haveSkillInList = true;
        }
        if (existTagsList?.tags?.length > 0) {
            for (let i = 0; i < existTagsList.tags.length; i++) {
                if (existTagsList.tags[i] === name)
                    haveSkillInList = true
            }
        }
        for (let i = 0; i < suggestedSelectOptions.length; i++) {
            if (suggestedSelectOptions[i] === name)
                haveSkillInList = true
        }
        return haveSkillInList; // не  добавлять скилл если его нет в предложенных
    }

    let tagRender = (props) => {
        const {label, closable} = props;
        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={() => {
                    let obj = {name: label};
                    let newArr = [...tagsStep3];
                    newArr = newArr.filter(tag => tag !== label);
                    setTagsStep3(newArr)
                    requests.quests.remove_trajectory_skill(obj)
                        .catch((e) => console.error(e));
                }}
                style={current === 2 ?
                    {
                        marginRight: 3,
                        marginBottom: 5,
                        color: "#FFD23B",
                        fontSize: 12,
                        background: "rgba(255, 210, 59, 0.2)",
                        lineHeight: 2.1,
                        border: "none"
                    }
                    : {
                        marginRight: 3,
                        marginBottom: 5,
                        color: "#A2AACA",
                        fontSize: 12,
                        background: "rgba(162, 170, 202, 0.2)",
                        lineHeight: 2.1,
                        border: "none"
                    }
                }
            >
                {label}
            </Tag>
        );
    };

    useEffect(() => {
        if (typeof user?.avatar !== 'undefined' && user.avatar) {
            setImage(user.avatar)
        }
    }, [user]);
    useEffect(() => {
        setSuggestedSelectOptions(defaultSkillOptions(activeActivity));
    }, [activeActivity]);

    return (
        <div className="StepsContainer">
            { current !== 4 && <div className="inner">
                <div className="step_custom">
                    {/*<div className={current === 0 ? "step_first active" : "step_first"}>*/}
                    {/*    <span>1</span>*/}
                    {/*</div>*/}
                    {/*<div className="step_second">*/}
                    {/*    <div className="line"/>*/}
                    {/*</div>*/}
                    <div className={current === 1 ? "step_third active" : "step_third"}>
                        <span>1</span>
                    </div>
                    <div className="step_fourth">
                        <div className="line"/>
                    </div>
                    <div className={current === 2 ? "step_fifth active" : "step_fifth"}>
                        <span>2</span></div>
                    <div className="step_six">
                        <div className="line"/>
                    </div>
                    <div className={current === 3 ? "step_seventh active" : "step_seventh"}>
                        <span>3</span>
                    </div>
                </div>
                <div className="steps-content">
                    {current === 1 &&
                        <Step_2
                            fio={fio}
                            email={email}
                            setFio={setFio}
                            setEmail={setEmail}
                            phone={phone}
                            setImage={setImage}
                            setCurrent={setCurrent}
                            current={current}
                            setName={setName}
                        />
                    }
                    {current === 2 &&
                        <Step_3
                            tagsStep3={tagsStep3}
                            activeActivity={activeActivity}
                            freelanceStep={freelanceStep}
                            validateSelectSkills={validateSelectSkills}
                            existTagsList={existTagsList}
                            loadingSkills={loadingSkills}
                            name={name}
                            image={image}
                            searchSkills={setValueInputTag}
                            tagRender={tagRender}
                            suggestedSelectOptions={suggestedSelectOptions}
                            flagOpenSelectTags={flagOpenSelectTags}
                            setCurrent={setCurrent}
                            setFlagOpenSelectTags={setFlagOpenSelectTags}
                            setTagsStep3={setTagsStep3}
                            setActiveActivity={setActiveActivity}
                            setFreelanceStep={setFreelanceStep}
                        />
                    }
                    {current === 3 &&
                        <Step_4
                            searchSkills={setValueInputTag}
                            validateSelectSkills={validateSelectSkills}
                            tagRender={tagRender}
                            existTagsList={existTagsList}
                            loadingSkills={loadingSkills}
                            suggestedSelectOptions={suggestedSelectOptions}
                            setCurrent={setCurrent}
                        />
                    }
                </div>
            </div>}
            {current === 4 && <ModalWelcome image={image} name={name} setImage={setImage}/>}
        </div>
    )
}

export default StepsContainer