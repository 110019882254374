import React from 'react';
import Slider from "react-slick";
import cn from "classnames";
import { useDispatch } from "react-redux";

import { setActiveVacancy } from "../../../../redux/actions/searchProfessionActions";
import { defaultFuncAndData } from "../../../../../utils/defaultData";

import hh_icon from "../../../../../images/icons/hh_icon.svg";
import trud_vsem_icon from "../../../../../images/icons/trud_vsem-icon.svg";
import { ReactComponent as Star } from "../../../../../images/icons/nav_bar-star.svg";

const SliderVacancy = ({sliderHeightVacancy, activeProfession,activeVacancy, vacancycData, handleChangeFavoriteVacancy}) => {
    const dispatch = useDispatch();
    const settgingsSliderVacancy = {
        infinite: false,
        speed: 300,
        slidesToShow: getCountShowSlide(),
        slidesToScroll: 1,
        rows: 1,
        swipeToSlide: true,
        className: "format_study-slides-mobile",
        arrows: false,
    };
    function getCountShowSlide() {
        if (window.innerWidth < 370) {
            return 2.2
        }
        if (window.innerWidth < 485) {
            return 3.07
        }
        if (window.innerWidth < 600) {
            return 4.2
        }
        if (window.innerWidth < 730) {
            return 5
        }
        if (window.innerWidth < 830) {
            return 6
        }
        if (window.innerWidth < 950) {
            return 7
        }
        if (window.innerWidth < 990) {
            return 8
        }

        return 3
    };
    return (
        <Slider
            {...settgingsSliderVacancy}
            afterChange={(e) => {
            }}
            key={activeProfession?.name}
        >
            {vacancycData.length > 0 &&
                vacancycData.map((elem, indx) =>
                    <div key={indx}>
                        <div
                            className={cn("vacancy", {
                                "active": activeVacancy?.name === elem?.name
                            })}
                            onClick={() => dispatch(setActiveVacancy(elem))}
                            style={{minHeight: sliderHeightVacancy || "initial"}}
                        >

                            <div className="title_vacancy">
                                <span>{elem.name}</span>
                                <Star
                                    className={elem.favorite ? "active" : ""}
                                    onClick={() => handleChangeFavoriteVacancy(elem)}
                                    width={14}
                                    height={14}
                                />
                            </div>
                            <div className="salary">
                                {defaultFuncAndData.calcSalarysVacancy(elem)}
                            </div>
                            <div className="bottom_row">
                                <div className="company">
                                    {elem?.employer?.name}
                                </div>
                                <div className="redirect_icon">
                                    <img
                                        onClick={() => window.open(elem.alternate_url, "blank")}
                                        width={23}
                                        height={elem.alternate_url.includes("hh.ru") ? 10 : 18}
                                        src={elem.alternate_url.includes("hh.ru") ? hh_icon : trud_vsem_icon}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </Slider>
    );
};

export default SliderVacancy;