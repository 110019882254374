import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import variableLanguage from "../../../../utils/hookGetVariablesLanguage";

import burger_close from "../../../../images/landing/close_burger.svg";
import burger from "../../../../images/landing/burger.svg";
import { ReactComponent as Logo } from "../../../../images/logo_dark.svg";

const Header = ({arrNavBar, handleShowSkills, refs, flagAuth, scrollIntoView, isLandingWeb = false}) => {
        const history = useNavigate();

        const variable_2 = variableLanguage({keyPage: "landing", keyVariable: "variable_2"});
        const variable_3 = variableLanguage({keyPage: "landing", keyVariable: "variable_3"});
        const variable_4 = variableLanguage({keyPage: "landing", keyVariable: "variable_4"});
        const variable_5 = variableLanguage({keyPage: "landing", keyVariable: "variable_5"});
        const variable_6 = variableLanguage({keyPage: "landing", keyVariable: "variable_6"});

        const [flagBurgerMobile, setFlagBurgerMobile] = useState(false);
        return (
            <>
                <header>
                    <div className="icon"
                         onClick={() => window.open("https://skillometer.ru", "blank")}>
                        <Logo/>
                    </div>
                    <div className="nav_bar">
                        <ul>
                            {arrNavBar.map((elem, indx) => {
                                    if (elem.valid) {
                                        return (
                                            <li
                                                key={indx}
                                                onClick={() => {
                                                    if (indx === 0) {
                                                        if (flagAuth)
                                                            history("/student/course-list");
                                                        else
                                                            history("/course-list");
                                                    }
                                                    if (elem.name === variable_2) scrollIntoView(refs.speakersRef);
                                                    if (elem.name === variable_3) scrollIntoView(refs.formatStudyRef);
                                                    if (elem.name === variable_4) scrollIntoView(refs.whoSuitsRef);
                                                    if (elem.name === variable_5) handleShowSkills(elem.name);
                                                }}
                                            >
                                                {elem.name}
                                            </li>
                                        );
                                    }
                                }
                            )}
                        </ul>
                    </div>
                    {(!isLandingWeb && !flagAuth) &&
                        <div
                            className="btn_redirect"
                            onClick={() => history("/student/auth")}
                        >
                            {variable_6}
                        </div>
                    }
                    <div
                        className="btn_redirect-mobile"
                        onClick={() => setFlagBurgerMobile(!flagBurgerMobile)}>
                        <img
                            src={flagBurgerMobile ? burger_close : burger}
                            style={flagBurgerMobile ? {top: 10, left: 10} : {}}
                        />
                    </div>
                </header>
                <div
                    className={flagBurgerMobile
                        ? "drop_down-header active"
                        : "drop_down-header"}
                >
                    <ul className={"list"}>
                        {arrNavBar.map((elem, indx) => {
                                if (elem.valid)
                                    return (
                                        <li
                                            key={indx}
                                            onClick={() => {
                                                if (indx === 0) {
                                                    if (flagAuth)
                                                        history("/student/course-list");
                                                    else
                                                        history("/course-list");
                                                }


                                                if (elem.name === variable_2) scrollIntoView(window.innerWidth > 820 ? refs.speakersRef : refs.speakersRefMobile);
                                                if (elem.name === variable_3) scrollIntoView(window.innerWidth > 820 ? refs.formatStudyRef : refs.formatStudyRefMobile);
                                                if (elem.name === variable_4) scrollIntoView(window.innerWidth > 820 ? refs.whoSuitsRef : refs.whoSuitsRefMobile);
                                                if (elem.name === variable_5) handleShowSkills(elem.name);
                                            }}
                                        >
                                            {elem.name}
                                        </li>
                                    );
                            }
                        )}
                    </ul>
                    {!isLandingWeb &&
                        <div
                            className="btn_redirect-burger"
                            onClick={() => history("/student/auth")}
                        >
                            {variable_6}
                        </div>
                    }
                </div>
            </>
        );
    }
;

export default Header;