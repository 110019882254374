import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, Outlet, useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setUserTrajectory } from "./student/redux/actions/userActions";
import { changeLanguage, changeTheme } from "./student/redux/actions/authorizationActions";
import requests from "./student/axios/requests";
import cn from "classnames";
import { AnimatePresence } from "framer-motion/dist/framer-motion";

import MyCourse from "./student/pages/MyCourse/MyCourse";
import Lesson from "./student/pages/Lesson/Lesson";
import CourseList from "./student/pages/CourseList/CourseList";
import PaymentResp from "./student/pages/Payment/PaymentResponse/PaymentResp";
import Notification from "./student/pages/Notification/Notification";
import AuthToken from "./student/pages/AuthToken/AuthToken";
import Landing from "./student/pages/Landing/Landing";
import SearchTalants from "./student/pages/SearchTalants/SearchTalants";
import LandingWeb from "./student/pages/LandingWeb/LandingWeb";
import CourseListIsAuth from "./student/pages/CourseListIsAuth/CourseListIsAuth";
import Resume from "./student/pages/Resume/Resume";
import FreelanceList from "./student/pages/SearchTalants/FreelanceList";
import Auth from "./student/pages/Auth/Auth";
import AuthNew from "./student/pages/AuthNew/AuthNew";
import SearchProfession from "./student/pages/SearchProfession/SearchProfession";
import Layout from "./student/components/Layout/Layout";
import ModalBuy from "./student/pages/GenerateCourse/Modals/ModalBuy/ModalBuy";
import GenerateCourse from "./student/pages/GenerateCourse/GenerateCourse";
import SkillSetContainer from "./student/pages/SkillSet/SkillSetContainer";
import SearchProfessionSkillSet from "./student/pages/SearchProfession/SearchProfessionSkillSet";
import GenerateCourseSkillSet from "./student/pages/GenerateCourse/GenerateCourseSkillSet";

const Profile = React.lazy(() => import('./student/pages/Profile/Profile'))

const PrivateRoute = (props) => {
    const location = useLocation();
    if (!localStorage.getItem("token") && !location.pathname.includes("/course-preview")) {
        return (
            <Navigate
                to={{
                    pathname: "/student/auth",
                    state: {from: props.location}
                }}
            />
        )
    } else
        return (
            <Layout>
                <React.Suspense
                    fallback={<div
                        style={{position: "absolute", top: "2%", left: "50%", transform: "translateX(-50%)"}}
                    >
                        Loading...
                    </div>}
                >
                    <Outlet/>
                </React.Suspense>
            </Layout>
        )
};

const App = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {isLandingWeb} = useSelector(state => state.Reducer);

    const [currentLocation, setCurrentLocation] = useState("");

    let getApiNotification = () => {
        requests.notification.get_count_notification()
            .then((res) => {
                if (res?.data?.count)
                    localStorage.setItem("count_notification", res.data.count);
            })
            .catch(err => console.error(err));
    };
    let getApiTrajectorySkill = () => {
        requests.quests.get_trajectory_skill()
            .then(({data}) => dispatch(setUserTrajectory(data)))
            .catch((err) => console.error(err));
    };
    let getProfileUser = () => {
        requests.users.get_profile_new()
            .then((res) => {
                dispatch(setUser(res.data))
            })
            .catch(err => console.error(err))
    }
    let detectBrowserUser = () => {
        let sBrowser, sUsrAg = navigator.userAgent;

        if (sUsrAg.indexOf("Firefox") > -1) {
            sBrowser = "Mozilla Firefox";
            // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
        } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
            sBrowser = "Samsung Internet";
            // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
        } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
            sBrowser = "Opera";
            // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
        } else if (sUsrAg.indexOf("Trident") > -1) {
            sBrowser = "Microsoft Internet Explorer";
            // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
        } else if (sUsrAg.indexOf("Edge") > -1) {
            sBrowser = "Microsoft Edge";
            // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
        } else if (sUsrAg.indexOf("Chrome") > -1) {
            sBrowser = "Google Chrome or Chromium";
            // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
        } else if (sUsrAg.indexOf("Safari") > -1) {
            sBrowser = "Apple Safari";
            // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
        } else {
            sBrowser = "unknown";
        }

        return sBrowser;
    }

    useEffect(() => {
        if (localStorage.getItem('language')) {
            dispatch(changeLanguage(localStorage.getItem('language')))
        } else dispatch(changeLanguage('ru'))
        if (localStorage.getItem("theme")) {
            dispatch(changeTheme(true))
        } else dispatch(changeTheme(false))
        if (localStorage.getItem("token")) {
            localStorage.removeItem("count_notification");
            getApiTrajectorySkill();
            getProfileUser();
            getApiNotification();
            setInterval(() => {
                getApiNotification();
            }, 30000);
        }
    }, [])
    useEffect(() => {
        setCurrentLocation(location)
    }, [location])
    return (
        <div
            className={cn("app", {
                "back_color-white": isLandingWeb ||
                    (!localStorage.getItem("token") && location.pathname.includes("/course-preview")),
            })}
        >
            <AnimatePresence mode={"wait"}>
                <Routes
                    /*location={location}
                    key={location.pathname}*/
                >
                    <Route path={"/student/resume/:id"} element={<Resume/>}/>
                    <Route path={"/freelance-list/:category"} element={<FreelanceList/>}/>
                    <Route path={"/search-talants"} element={<SearchTalants/>}/>
                    <Route path={"/student/auth"} element={<Auth/>}/>
                    <Route path={"/student/auth-new"} element={<AuthNew/>}/>
                    <Route path={"/course-list"} element={<CourseList/>}/>
                    <Route path={"/student/course-preview/:id/:token"} element={<LandingWeb/>}/>
                    <Route path={"/student/education-pay/:token"} element={<ModalBuy/>}/>
                    <Route path={"/generate-course"} element={<GenerateCourse/>}/>
                    <Route path={"/"} element={<PrivateRoute/>}>
                        {/*<Route path={"/"} element={<SearchProfession/>}/>*/}
                        <Route path={"/"} element={<SkillSetContainer/>}/>
                        <Route path={"/student/course-list"} element={<CourseListIsAuth/>}/>
                        <Route path={"/student/profile"} element={<Profile/>}/>
                        <Route path={"/student/my-courses"} element={<MyCourse/>}/>
                        <Route path={"/backoffice/skill-set"} element={<SkillSetContainer/>}>
                            <Route path={"/backoffice/skill-set/prof-review"} element={<SearchProfessionSkillSet/>}/>
                            <Route path={"/backoffice/skill-set/generate-course"} element={<GenerateCourseSkillSet/>}/>
                            <Route path={"/backoffice/skill-set/courses"} element={<MyCourse/>}/>
                        </Route>
                        <Route path={"/student/course/lesson/:id"} element={<Lesson/>}/>
                        <Route path={"/student/course/lesson/:name/:id"} element={<Lesson/>}/>
                        <Route path={"/student/payment/:status/:id"} element={<PaymentResp/>}/>
                        <Route path={"/student/payment/education/:status/:id"} element={<PaymentResp/>}/>
                        <Route path={"/student/search-talants"} element={<SearchTalants/>}/>
                        <Route path={"/student/resume"} element={<Resume/>}/>
                        <Route path={"/student/freelance-list/:category"} element={<FreelanceList/>}/>
                        <Route path={"/student/course-preview/:id"} element={<Landing/>}/>
                        <Route path={"/student/authorization-link/:token"} element={<AuthToken/>}/>
                        <Route path={"/notification"} element={<Notification/>}/>
                        <Route path={"/student/generate-course"} element={<GenerateCourse/>}/>
                    </Route>
                    <Route path="*" element={<Navigate to="/"/>}/>
                </Routes>
            </AnimatePresence>
        </div>
    );
};

export default App;
