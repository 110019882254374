import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import requests from "../../../../../axios/requests";
import Editor from "@monaco-editor/react";
import { Image, List, message, Tooltip, Typography, Upload } from "antd";
import variableLanguage from "../../../../../../utils/hookGetVariablesLanguage";
import { defaultFuncAndData } from "../../../../../../utils/defaultData";
import TopButtons from "./TopButtons/TopButtons";

const {Text} = Typography;

import full_screen_editor from '../../../../../../images/icons/full_screen_editor.svg';
import question_icon from "../../../../../../images/icons/question.svg";
import './EditorCode.scss';

const EditorCode = (props) => {
    const variable_27 = variableLanguage({keyPage: "lesson", keyVariable: "variable_27"});
    const variable_28 = variableLanguage({keyPage: "lesson", keyVariable: "variable_28"});
    const variable_36 = variableLanguage({keyPage: "lesson", keyVariable: "variable_36"});
    const variable_38 = variableLanguage({keyPage: "lesson", keyVariable: "variable_38"});
    const variable_39 = variableLanguage({keyPage: "lesson", keyVariable: "variable_39"});
    const variable_40 = variableLanguage({keyPage: "lesson", keyVariable: "variable_40"});
    const variable_41 = variableLanguage({keyPage: "lesson", keyVariable: "variable_41"});
    const variable_42 = variableLanguage({keyPage: "lesson", keyVariable: "variable_42"});
    const variable_43 = variableLanguage({keyPage: "lesson", keyVariable: "variable_43"});
    const variable_44 = variableLanguage({keyPage: "lesson", keyVariable: "variable_44"});
    const variable_45 = variableLanguage({keyPage: "lesson", keyVariable: "variable_45"});
    const variable_46 = variableLanguage({keyPage: "lesson", keyVariable: "variable_46"});
    const variable_47 = variableLanguage({keyPage: "lesson", keyVariable: "variable_47"});
    const variable_52 = variableLanguage({keyPage: "lesson", keyVariable: "variable_52"});
    const variable_53 = variableLanguage({keyPage: "lesson", keyVariable: "variable_53"});
    const variable_54 = variableLanguage({keyPage: "lesson", keyVariable: "variable_54"});
    const variable_55 = variableLanguage({keyPage: "lesson", keyVariable: "variable_55"});
    const variable_56 = variableLanguage({keyPage: "lesson", keyVariable: "variable_56"});
    const variable_58 = variableLanguage({keyPage: "lesson", keyVariable: "variable_58"});
    const variable_60 = variableLanguage({keyPage: "lesson", keyVariable: "variable_60"});
    const variable_61 = variableLanguage({keyPage: "lesson", keyVariable: "variable_61"});

    const {
        language, fontSize, setFullScreen, userCode,
        setUserCode, flagIsArchived, dropDownLanguage, setDropDownLanguage,
        stageItems, activeTheoryNum
    } = props;
    const {
        title, youtube, text, image_file,
        video_file, video_file_name, urls, id
    } = props.taskProps;

    let tasks;
    if (props.taskProps.tasks && props.taskProps.tasks.length > 0)
        tasks = props.taskProps.tasks.sort((a, b) => a.id - b.id);
    else tasks = [];

    const [userOutput, setUserOutput] = useState("");
    const [loading, setLoading] = useState(false);
    const [dataError, setDataError] = useState("");
    const [langugeToEditor, setLangugeToEditor] = useState("");
    const [activeTheme, setActiveTheme] = useState(0);
    const [activeFontSize, setActiveFontSize] = useState(0);
    const [dropDownTheme, setDropDownTheme] = useState(false);
    const [dropDownFontSize, setDropDownFontSize] = useState(false);

    const arrayTheme = [
        {value: "vs-dark", label: "Dark"},
        {value: "light", label: "Light"}
    ];
    const arrayFontSize = [14, 16, 18, 20, 22];
    const arrayLanguage = ["Python", "Java", "C++", "C#", "C", "Golang", "JavaScript"];
    let uploadProps = {
        onChange({file, fileList}) {
            if (file.status !== "uploading") {
            }
        },
        listType: "card",
        fileList: [
            {
                uid: "-1",
                name: video_file_name,
                status: "done",
                response: props.variable_26,
                url: video_file,

            }
        ],
        defaultFileList: [
            {
                uid: "-1",
                name: video_file_name,
                status: "done",
                response: props.variable_26, // custom error message to show
                url: video_file,
            }
        ]
    };

    let displayCurrentLanguage = (elem) => {
        switch (elem.language) {
            case 'python':
                return 0;
            case 'java':
                return 1;
            case 'c':
                return 2;
            case 'c++':
                return 3;
            case 'c#':
                return 4;
            case 'golang':
                return 5;
            case 'js':
                return 6;
        }
    }
    let isVideo = (fileName) => {
        const videoFormats = [
            ".WEBM", ".MPG", ".MP2", ".MPEG", ".MPE", ".MPV",
            ".OGG", ".MP4", ".M4P", ".M4V", ".AVI", ".WMV",
            ".MOV", ".QT", ".FLV", ".SWF", "AVCHD"
        ];
        return (videoFormats.some((format) => fileName?.toLowerCase().includes(format.toLowerCase())));
    };
    let selectText = (task, props) => {
        switch (task.checked) {
            case "passed":
                return <Text strong
                             type="success">{variable_38}</Text>;
            case "need_correction":
                return <Text strong
                             type="danger">{props.taskProps.block_next ? variable_53 : variable_39}</Text>;
            case "not_reviewed":
                return <Text strong
                             type="warning">{props.taskProps.block_next ? variable_52 : variable_40}</Text>;
            default:
                return "";
        }
    }
    let compile = async () => {
        setLoading(true);
        setDataError("");
        if (userCode === ``) {
            return setLoading(false);
        }
        let strLang = "";
        if (langugeToEditor) {
            switch (langugeToEditor) {
                case "python":
                    strLang = "py";
                    break;
                case "java":
                    strLang = "java";
                    break;
                case "cpp":
                    strLang = "cpp";
                    break;
                case "csharp":
                    strLang = "cs";
                    break;
                case "c":
                    strLang = "c";
                    break;
                case "javascript":
                    strLang = "js";
                    break;
                case "go":
                    strLang = "go";
                    break;
                default:
                    return;
            }
        }
        let data = ({
            "code": userCode,
            "language": strLang,
        });

        let result;
        await requests.stages.post_code_editor(data)
            .then((response) => {
                result = response.data.output;
                setUserOutput(response.data.output);
                if (response.data.error)
                    setDataError(response.data.error);
            })
            .catch((error) => console.error(error))
            .finally(() => {
                setLoading(false);
            });
        return result
    }
    let sendTeacher = async () => {
        let promise = await compile();
        if (promise) {
            requests.stages.post_homework_send({
                theory_id: id,
                text: userCode,

            }).then((v) => {
                message.success( variable_27);
                window.location.reload(false);
            }).catch((e) => {
                if (e.message === "Request failed with status code 400")
                    message.error( variable_28);
            });
        } else {
            message.error("Ошибка компилирования")
        }
    }
    let setEditorTheme = (monaco) => {
        monaco.editor.defineTheme("onedark", {
            base: "vs-dark",
            inherit: true,
            rules: [
                {
                    token: "comment",
                    foreground: '#5d7988',
                    fontStyle: "italic",
                },
                {token: "constant", foreground: '#e06c75'},
            ],
            colors: {
                "editor.background": '#21252b',
            },
        });
    }

    if (flagIsArchived) {
        return (
            <div className="archive_row">
                {variable_60}
                <Tooltip
                    title={variable_61}
                    align={"top"}
                >
                    <img src={question_icon} style={{marginBottom: 5, marginLeft: 15, cursor: 'pointer'}}/>
                </Tooltip>
            </div>
        )
    }

    useEffect(() => {
        let language = arrayLanguage[displayCurrentLanguage(stageItems[activeTheoryNum])]
        if (language === "Python") setLangugeToEditor("python");
        if (language === "Java") setLangugeToEditor("java");
        if (language === "C++") setLangugeToEditor("cpp");
        if (language === "C#") setLangugeToEditor("csharp");
        if (language === "C") setLangugeToEditor("c");
        if (language === "JavaScript" || language === "js") setLangugeToEditor("javascript");
        if (language === "Golang") setLangugeToEditor("go");

    }, [])
    return (
        <>
            <TopButtons
                setDropDownTheme={setDropDownTheme}
                dropDownTheme={dropDownTheme}
                arrayTheme={arrayTheme}
                language={langugeToEditor}
                setActiveTheme={setActiveTheme}
                activeFontSize={activeFontSize}
                dropDownFontSize={dropDownFontSize}
                setDropDownFontSize={setDropDownFontSize}
                arrayFontSize={arrayFontSize}
                setActiveFontSize={setActiveFontSize}
                dropDownLanguage={dropDownLanguage}
                setDropDownLanguage={setDropDownLanguage}
                arrayLanguage={arrayLanguage}
                displayCurrentLanguage={displayCurrentLanguage}
                theme={arrayTheme[activeTheme]}

            />
            <div className="code_editor-parent">
                <div className="block_info_lesson">
                    <div className="title_lesson">
                        {title}
                    </div>
                    <div
                        className="title_lesson"
                        style={{fontSize: 26, margin: ' 0 0 10px 0'}}
                    >
                        {variable_54}
                    </div>
                    <pre className="will_be-answer">
                    {defaultFuncAndData.linkChanger(text)}
                </pre>
                    {image_file &&
                        <>
                            <div
                                className="title_lesson"
                                style={{fontSize: 26, margin: '10px 0'}}
                            >
                                {variable_55}
                            </div>
                            <Image src={image_file} className={"img_preview task"}/>
                        </>
                    }
                    {video_file_name &&
                        <>
                            <div
                                className="title_lesson"
                                style={{fontSize: 26, margin: '10px 0'}}
                            >
                                {variable_56}
                            </div>
                            {isVideo(video_file_name) &&
                                <>
                                    <div className="player-wrapper">
                                        <ReactPlayer
                                            controls
                                            className="react-player"
                                            url={video_file}
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                </>
                            }
                            <Upload {...uploadProps} disabled/>
                        </>
                    }
                    {youtube &&
                        <>
                            <div
                                className="title_lesson"
                                style={{fontSize: 26, margin: '10px 0'}}
                            >
                                {variable_56}
                            </div>
                            <div className="player-wrapper">
                                <ReactPlayer
                                    controls
                                    className="react-player"
                                    url={youtube}
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </>
                    }
                    {urls &&
                        <>
                            <div
                                className="title_lesson"
                                style={{fontSize: 26, margin: '10px 0'}}
                            >
                                {variable_36}
                            </div>
                            <div
                                style={{
                                    fontSize: 20,
                                    color: "#A2AACA",
                                    margin: '0 0 20px 0'
                                }}
                                className="url"
                            >
                                {defaultFuncAndData.linkChanger(urls)}
                            </div>
                        </>
                    }
                    {tasks?.length > 0 &&
                        <>
                            <div
                                className="title_lesson"
                                style={{fontSize: 26, margin: 0}}
                            >
                                {variable_58}
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "column-reverse", marginBottom: 15}}
                            >
                                {tasks.map((task, i) =>
                                    <div className="tasks_history" key={i}>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexWrap: 'wrap',
                                            borderBottom: "1px solid #474C5A",
                                            paddingBottom: 5
                                        }}>
                                            <div>
                                                <div style={{
                                                    fontSize: 24,
                                                    color: "white",
                                                    marginBottom: 10
                                                }}>{variable_41}: {i + 1}</div>
                                            </div>
                                            <div className="checkTime">
                                                {task.checked && task.checked !== "not_reviewed" && task.check_datetime
                                                    ? <div style={{fontSize: 16, color: "#757A88"}}>
                                                        <span style={{marginRight: 5}}>{variable_42}: </span>
                                                        <span
                                                            style={{color: "white"}}>{defaultFuncAndData.dateParser(localStorage.getItem('language'), task.check_datetime)}</span>
                                                    </div>
                                                    : <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="boxes">
                                            <List size="small" title={variable_43} bordered layout="vertical"
                                                  column={1}>
                                                <List.Item
                                                    style={{display: 'flex', flexDirection: 'column', minHeight: 200}}>
                                                    <Text strong style={{alignSelf: 'start'}}>{variable_43}:</Text>
                                                    <Editor
                                                        className={"editor"}
                                                        width="100%"
                                                        theme={"vs-dark"}
                                                        language={langugeToEditor}
                                                        defaultLanguage="python"
                                                        defaultValue={task.text}
                                                        options={{
                                                            scrollbar: {
                                                                alwaysConsumeMouseWheel: false,
                                                                vertical: "auto",
                                                                horizontal: "auto",
                                                            },
                                                            contextmenu: false,
                                                            minimap: {enabled: false},
                                                            readOnly: true,
                                                            fontSize
                                                        }}

                                                    />
                                                </List.Item>
                                                {task.task_file_name &&
                                                    <List.Item
                                                        label={variable_44}
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "flex-start",
                                                            justifyContent: "inherit",
                                                            flexWrap: "wrap"
                                                        }}
                                                    >
                                                        <Text strong>{variable_35}:</Text>
                                                        {isVideo(task.task_file_name) &&
                                                            <div className="player-wrapper" style={{width: "100%"}}>
                                                                <ReactPlayer
                                                                    controls
                                                                    className="react-player"
                                                                    url={task.file}
                                                                    width="100%"
                                                                    height="100%"
                                                                />
                                                            </div>
                                                        }
                                                        <a
                                                            style={{color: "white", marginLeft: 5, marginTop: -15}}
                                                            href={task.file}
                                                        >
                                                            {task.task_file_name}
                                                        </a>
                                                    </List.Item>
                                                }

                                                <List.Item
                                                    label={variable_45}
                                                    style={{justifyContent: "initial"}}
                                                >
                                                    <Text strong>{variable_45}:</Text>
                                                    <span style={{
                                                        color: "white",
                                                        marginLeft: 5
                                                    }}>{defaultFuncAndData.dateParser(localStorage.getItem('language'), task.time)}</span>
                                                </List.Item>
                                            </List>
                                        </div>
                                        {(task.teachers_answer || task.teachers_file) &&
                                            <div className="boxes">
                                                <List
                                                    size="small" title={variable_46} bordered layout="vertical"
                                                    column={1}
                                                    style={{marginTop: "8px"}}
                                                >
                                                    <List.Item
                                                        label={variable_46}
                                                        style={{justifyContent: "initial"}}
                                                    >
                                                        <Text strong>{variable_46}:</Text>
                                                        <span
                                                            style={{
                                                                color: "white",
                                                                marginLeft: 5
                                                            }}>  {task.teachers_answer}</span>
                                                    </List.Item>
                                                    {task.teachers_file_name &&
                                                        <List.Item
                                                            style={{justifyContent: "initial"}}
                                                            label={variable_47}
                                                        >
                                                            <Text strong>{variable_35}:</Text>
                                                            {isVideo(task.teachers_file_name)
                                                                ?
                                                                <div className="player-wrapper" style={{width: "100%"}}>
                                                                    <ReactPlayer
                                                                        controls
                                                                        className="react-player"
                                                                        url={task.teachers_file}
                                                                        width="75%"
                                                                        height="75%"
                                                                    />
                                                                </div>
                                                                : <a
                                                                    style={{color: "white", marginLeft: 5}}
                                                                    href={task.teachers_file}
                                                                >
                                                                    {task.teachers_file_name}
                                                                </a>
                                                            }
                                                        </List.Item>
                                                    }
                                                    <List.Item
                                                        className="mobileCheckTime"
                                                        label={variable_42}
                                                        style={{justifyContent: "initial"}}
                                                    >
                                                        <div style={{fontSize: 14, color: "#757A88"}}>
                                                            <span style={{marginRight: 5}}>{props.variable_42}: </span>
                                                            <span style={{color: "white"}}>
                                                            {task.checked && task.checked !== "not_reviewed"
                                                                ? defaultFuncAndData.dateParser(localStorage.getItem('language'), task.check_datetime)
                                                                : <></>
                                                            }
                                                        </span>
                                                        </div>
                                                    </List.Item>
                                                </List>
                                            </div>
                                        }
                                    </div>
                                )
                                }
                            </div>
                        </>
                    }
                </div>
                <div className="block_editor">
                    {tasks.length !== 0 &&
                        <div className="taskInfo">
                            {selectText(tasks[tasks.length - 1], props)}
                        </div>
                    }
                    <div className="code_container">
                        <div
                            className="full_screen-icon"
                            onClick={() => setFullScreen()}
                        >
                            <img src={full_screen_editor}/>
                        </div>
                        {langugeToEditor &&
                            <Editor
                                className={"editor"}
                                width="100%"
                                theme={"vs-dark"}
                                language={langugeToEditor}
                                onChange={(value) => setUserCode(value)}
                                value={userCode}
                                options={{
                                    scrollbar: {
                                        alwaysConsumeMouseWheel: false,
                                        vertical: "auto",
                                        horizontal: "auto",
                                    },
                                    fontSize,
                                    contextmenu: false,
                                    minimap: {enabled: false},
                                }}
                            />
                        }
                    </div>
                    <div className="output_container">
                        {loading
                            ? <div className="spinner-box">
                                <span>"Loading..."</span>
                            </div>
                            : <>
                                {dataError
                                    ? <>
                                        <div className="result_title">результат вывода:</div>
                                        <pre style={{color: "red"}}>{dataError}</pre>
                                    </>
                                    : <>
                                        <div className="result_title">результат вывода:</div>
                                        <Editor
                                            className={"output"}
                                            width="100%"
                                            theme={"vs-dark"}
                                            language={'json'}
                                            value={userOutput}
                                            options={{
                                                scrollbar: {
                                                    alwaysConsumeMouseWheel: false,
                                                    vertical: "auto",
                                                    horizontal: "auto",
                                                },
                                                fontSize,
                                                readOnly: true,
                                                contextmenu: false,
                                                minimap: {enabled: false}
                                            }}
                                        />
                                    </>
                                }
                            </>
                        }
                    </div>
                    <div className="buttons_editor">
                        <div className="btn" onClick={compile}>Запустить</div>
                        {!tasks.passed
                            ? <>
                                {tasks.length > 0
                                    ? <>
                                        {tasks[tasks.length - 1]?.checked === 'need_correction' ?
                                            <div
                                                className="btn send"
                                                onClick={sendTeacher}
                                            >Отправить учителю
                                            </div>
                                            : <></>
                                        }
                                    </>
                                    : <div
                                        className="btn send"
                                        onClick={sendTeacher}
                                    >
                                        Отправить учителю
                                    </div>
                                }
                            </>
                            : <></>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditorCode;