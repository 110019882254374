import React from "react";
import { Progress } from "antd";
import { defaultFuncAndData } from "../../../../utils/defaultData";

import { ReactComponent as InfoIcon } from "../../../../images/icons/nav_bar-info.svg";
import { ReactComponent as Star } from "../../../../images/icons/nav_bar-star.svg";

const Profession = (props) => {
    const {
        professionTitle, procentague, click, activeId, favoriteProfession,
        changeFavoriteProfession, setFlagModalProfessionInfo, prof, name = '',
        isProfessionMobile = false
    } = props;

    const obj = {
        professionTitle: professionTitle,
        procentague,
        tags: prof.tags,
        description: prof.description,
        id: prof.id
    };
    const objChangeFavorite = {
        professionTitle: professionTitle || name,
        procentague,
        tags: prof.tags,
        id: prof.id
    };

    let handleActiveProfession = () => {
        click(obj);
    };
    let handleChangeFavorite = () => {
        changeFavoriteProfession(objChangeFavorite);
    };
    let calcActiveFavorite = () => {
        if (favoriteProfession && favoriteProfession.length > 0) {
            for (let i = 0; i < favoriteProfession.length; i++) {
                if ( prof.id === favoriteProfession[i].profession.id) {
                    return true
                }
            }
        }
        return false
    };
    let getTrailColorProgress = () => {
        if (activeId === prof.id) return "#757A88"
        else return "#474C5A"
    }
    return (
        <li
            onClick={handleActiveProfession}
            className={activeId === prof.id ? "active" : ""}
        >
            <Star
                width={14}
                height={14}
                onClick={(e) => {
                    e.stopPropagation();
                    handleChangeFavorite();
                }}
                // className={calcActiveFavorite() ? "active" : ""}
                className={calcActiveFavorite() ? "active star" : "star"}
            />
            <div className="border"/>
            <span>{professionTitle}</span>
            {!isProfessionMobile &&
                <>
                    <InfoIcon
                        className={activeId === prof.id ? "into_icon active" : "into_icon"}
                        width={18}
                        height={18}
                        onClick={() => setFlagModalProfessionInfo()}
                    />
                    <Progress
                        type="dashboard"
                        percent={procentague}
                        gapDegree={1}
                        strokeColor={defaultFuncAndData.calcColorPercentProgressProfession(procentague)}
                        width={40}
                        height={40}
                        format={(percent) => percent}
                        trailColor={getTrailColorProgress()}
                        strokeWidth={20}
                    />
                </>
            }
            {isProfessionMobile &&
                <div className={"group_progress"}>
                    <InfoIcon
                        className={activeId === prof.id ? "into_icon active" : "into_icon"}
                        width={18}
                        height={18}
                        onClick={() => setFlagModalProfessionInfo()}
                    />
                    <Progress
                        type="dashboard"
                        percent={procentague}
                        gapDegree={1}
                        strokeColor={defaultFuncAndData.calcColorPercentProgressProfession(procentague)}
                        width={40}
                        height={40}
                        format={(percent) => percent}
                        trailColor={getTrailColorProgress()}
                        strokeWidth={20}
                    />
                </div>
            }
        </li>
    );
};

export default Profession;