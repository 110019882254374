import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "querystring";
import ReactPlayer from "react-player";
import cn from "classnames";

import requests from "../../axios/requests";
import { setUser, setUserTrajectory } from "../../redux/actions/userActions";
import { setConfig, setShowBuyModal } from "../../redux/actions/actions";
import { defaultFuncAndData } from "../../../utils/defaultData";

import { message, Pagination, Spin } from "antd";
import Tag from "../../components/Tag/Tag";
import Button from "../../components/Button/Button";
import PlanningCourse from "./PlanningCourse/PlanningCourse";
import variableLanguage from "../../../utils/hookGetVariablesLanguage";
import ModalBuy from "./Modals/ModalBuy/ModalBuy";
import PrevButton from "../../components/PrevButton/PrevButton";
import ModalSize from "./Modals/ModalSize/ModalSize";
import UnAuthenticated from "./UnAuthenticated/UnAuthenticated";

import { ReactComponent as Arrow } from "../../../images/arrow.svg";
import { ReactComponent as Star } from "../../../images/generate_course/star.svg";
import generation_count from "../../../images/generate_course/generation_count.svg";
import present from "../../../images/generate_course/present.png";
import size_icon from "../../../images/generate_course/size_icon.svg";
import size_icon_2 from "../../../images/generate_course/size_icon_2.svg";
import copy_link from "../../../images/generate_course/copy_link.svg";
import whatsapp from "../../../images/generate_course/whatsapp.svg";
import telegram from "../../../images/generate_course/telegram.svg";

import "./GenerateCourse.scss";

const GenerateCourse = () => {
    const variable_1 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_1'});
    const variable_2 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_2'});
    const variable_3 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_3'});
    const variable_4 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_4'});
    const variable_6 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_6'});
    const variable_15 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_15'});
    const variable_17 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_17'});
    const variable_18 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_18'});
    const variable_19 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_19'});
    const variable_21 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_21'});
    const variable_23 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_23'});
    const variable_24 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_24'});
    const variable_25 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_25'});
    const variable_26 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_26'});
    const variable_27 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_27'});
    const variable_28 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_28'});
    const variable_29 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_29'});
    const variable_30 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_30'});
    const variable_32 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_32'});
    const variable_34 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_34'});
    const variable_35 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_35'});
    const variable_36 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_36'});
    const variable_38 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_38'});
    const variable_40 = variableLanguage({keyPage: 'generateCourse', keyVariable: 'variable_40'});

    const history = useNavigate();
    const dispatch = useDispatch();
    const {trajectorySkills, user, config, language, showModalBuy} = useSelector(state => state.Reducer);

    const [page_size, set_page_size] = useState(4);
    const [activePage, setActivePage] = useState(0);
    const [selectTag, setSelectTag] = useState("");
    const [loadingGpt, setLoadingGpt] = useState(false);
    const [currentListEducation, setCurrentListEducation] = useState([]);
    const [allEducationUser, setAlllEducationUser] = useState([]);
    const [openModalSize, setOpenModalSize] = useState(false);
    const [modalSizeIndx, setModalSizeIndx] = useState(null);
    const [currentElemSize, setCurrentElemSize] = useState({});
    const [sizeTrajectory, setSizeTrajectory] = useState(false);
    const [favoritesList, setFavoritesList] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTag, setActiveTag] = useState(null);
    const [curlTrajectory, setCurlTrajectory] = useState(true);
    const [loadingIndx, setLoadingIndx] = useState(null);

    const newUrl = Object.keys(queryString.parse(user?.invite_link?.split("=")[1]))[0]
    const url = `${user?.invite_link?.split("=")[0]}=${newUrl}` || "error link"
    const text = `Подключайся и учись профессиональным навыкам на платформе  SKILLOMETER с искусственным интеллектом.
    %0a %0a Перейдите по ссылке и зарегистрируйтесь сейчас, чтобы получить 2 дополнительных бесплатных генераций курса.`

    let handleSendWhatsaspp = () => {
        window.open(
            `https://wa.me/?text=${text} ${(url)}`,
            "_blank"
        )
        /*window.open(
            `https://web.whatsapp.com/?text=Ссылка на регистрацию ${url}`,
            "_blank"
        )*/
    }
    let handleSendTelegram = () => {
        window.open(
            `https://telegram.me/share/url?url=${url}&text=${text}`,
            "_blank"
        )
    }
    let copyLink = () => {
        setTimeout(async () => {
            await navigator.clipboard.writeText(url)
            message.success("Ссылка скопирована в буфер обмена")
        })
    }
    let recursyProgress = (task_id) => {
        setLoadingGpt(true);
        requests.users.calery_progress(task_id)
            .then((res) => {
                let data = res.data;
                if (data.progress.current !== 100)
                    setTimeout(() => {
                        recursyProgress(task_id)
                    }, 2000)
                else
                    fetchGptApi()
            })
    }
    let updateEducationPlan = () => {
        if (user?.education_plan_limit === 0) {
            setLoadingGpt(false)
            setLoadingIndx(null)
            return message.error(variable_15)
        }
        requests.users.education_plan({speciality_tag: selectTag.id})
            .then((res) => {
                recursyProgress(res.data.task_id);
            })
            .catch((err) => {
                if(err?.response?.data?.length > 0) {
                    err?.response?.data?.forEach(elem => {
                        message.error(elem)
                    })
                }
            })
    }
    let generateQuest = async (id) => {
        if (user?.education_plan_limit === 0)
            return message.error(variable_15)

        await requests.users.generate_quest(id)
            .then((res) => {
                let newUser = {...user};
                newUser.education_plan_limit -= 1;
                dispatch(setUser(newUser))
                getExistTags();
                setTimeout(() => {
                    history(`/student/course/lesson/${res.data?.quest?.id}`)
                }, 500)
            })
            .catch(err => {
                console.error(err)
                setLoadingGpt(false)
            })
    }
    let fetchGptApi = async () => {
        /*setLoadingGpt(true);*/

        await requests.users.fetch_education_plan(selectTag.id)
            .then(res => {
                let data = res.data?.results;
                setCurrentListEducation(data);
                setLoadingGpt(false)
                setLoadingIndx(null)
            })
            .catch(err => console.error(err))
    }
    let checkExistTags = () => {
        return allEducationUser
            .filter(elem => elem.quest?.id)
            .map((elem) => elem.speciality_tag)
    }
    let getExistTags = () => {
        requests.users.get_plannings()
            .then(res => setAlllEducationUser(res.data.results))
            .catch(err => console.error(err))
    }
    let fetchTrajectory = () => {
        requests.quests.get_trajectory_skill()
            .then(({data}) => dispatch(setUserTrajectory(data)))
            .catch((err) => console.error(err));
    }
    let changeFavoritePlan = (id, value) => {
        requests.users.changeFavoritePlan(id, !value)
            .then(() => {
                let newListEducation = [...currentListEducation];
                let indx = newListEducation.findIndex(elem => elem.id === id);
                if (value) {
                    let newListFavorite = [...favoritesList?.results];
                    newListFavorite = newListFavorite.filter(elem => elem.id !== id)
                    setFavoritesList(newListFavorite)
                }
                newListEducation[indx].favorite = !value;
                setCurrentListEducation(newListEducation);
                fetchTrajectory();
            })
            .catch(err => console.error(err))
    }
    let fetchFavorites = () => {
        requests.users.fetchFavorites(currentPage, page_size, activeTag)
            .then(({data}) => {
                setFavoritesList(data)
            })
            .catch(err => console.error(err))
    }
    let haveFavoriteInList = (arr) => {
        arr.forEach((elem) => {
            if (elem.favorite)
                return true
        })
        return false
    }
    let getApiTrajectorySkill = () => {
        requests.quests.get_trajectory_skill()
            .then(({data}) => dispatch(setUserTrajectory(data)))
            .catch((err) => console.error(err));
    };
    let getProfileUser = () => {
        requests.users.get_profile_new()
            .then((res) => {
                dispatch(setUser(res.data))
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        if (window.location.pathname === "/generate-course" && localStorage.getItem("token"))
            history("/student/generate-course")

        getExistTags();
        getApiTrajectorySkill();
        getProfileUser()

        requests.users.get_config()
            .then(res => {
                dispatch(setConfig(res.data))
                set_page_size(res.data?.PLAN_GENERATION_UPDATE_LIMIT || 4);
            })
            .catch(err => console.error(err))
    }, [])
    useEffect(() => {
        if (allEducationUser.length > 0)
            checkExistTags();
    }, [allEducationUser])
    useEffect(() => {
        if (selectTag)
            fetchGptApi()
    }, [selectTag])
    useEffect(() => {
        if (activePage === 1)
            fetchFavorites()
    }, [activePage, currentPage, activeTag])
    return (
        <div className={cn("generate_course", {
            not_auth: !localStorage.getItem("token")
        })}>

            {activePage === 0
                ? <>
                    <div className="group_header">
                        <div
                            className={curlTrajectory
                                ? "header"
                                : "header border_bottom"
                            }>
                            <div className="title_block">
                                <div className="h1">
                                    {variable_1}
                                    {/*<InfoIcon/>*/}
                                </div>
                                {localStorage.getItem("token") &&
                                    <div className="text">
                                        {variable_2}
                                    </div>
                                }
                            </div>
                            {localStorage.getItem("token")
                                ? <div className="header_buttons">
                                    <div
                                        className="favorite"
                                        onClick={() => setActivePage(1)}
                                    >
                                        <Star/>
                                    </div>
                                    <div className="generation_count-block">
                                        {language === "ru"
                                            ? <div className="count">
                                                <span>{user?.education_plan_limit}</span>
                                                <span>{defaultFuncAndData.declension(user?.education_plan_limit, ["Генерация курса", "Генерации курса", "Генераций курса"])}</span>
                                            </div>
                                            : <div>
                                                <span>{user?.education_plan_limit}</span>
                                                <span>Generations course</span>
                                            </div>
                                        }
                                        <div
                                            className="icon"
                                            onClick={() => dispatch(setShowBuyModal(true))}
                                        >
                                            <img src={generation_count} alt="generation_count"/>
                                            <span>{variable_23}</span>
                                        </div>
                                    </div>
                                </div>
                                : <Button
                                    text={variable_36}
                                    click={() => {
                                        history('/student/auth')
                                        localStorage.setItem("previous_course_link", "https://web.skillometer.ru/student/generate-course")
                                    }}
                                    maxWidth={"265px"}
                                />
                            }
                        </div>
                        {localStorage.getItem("token")
                            ? <>
                                <div
                                    className={cn("trajectory_block", {

                                    })}
                                >
                                    <div
                                        className={cn("trajectory", {
                                            open: sizeTrajectory
                                        })
                                        }
                                    >
                                        <div className="top_settings">
                                            <div className="label">
                                                {variable_34}
                                            </div>
                                            <div
                                                className="size_btn"
                                                onClick={() => setSizeTrajectory(!sizeTrajectory)}
                                            >
                                                    <span>
                                                        {sizeTrajectory ? variable_4 : variable_3}
                                                    </span>
                                                {sizeTrajectory
                                                    ? <img src={size_icon_2} alt="size_icon"/>
                                                    : <img src={size_icon} alt="size_icon"/>
                                                }

                                            </div>
                                        </div>
                                        <div className="info">
                                            <div className="figure"/>
                                            <div className="text">{variable_24}</div>
                                            <div className="figure"/>
                                            <div className="text">{variable_25}</div>
                                        </div>
                                        {trajectorySkills?.length > 0 &&
                                            <div className="list_tags">
                                                {trajectorySkills.map((elem) =>
                                                    <Tag
                                                        elem={elem}
                                                        key={elem.id}
                                                        cursorPointer={true}
                                                        hideRemoveIcon={true}
                                                        countCourses={elem.generated_quests_count}
                                                        countPlanes={elem.education_plans_count}
                                                        className={cn("", {
                                                            color_active: selectTag?.name === elem.name,
                                                            exist_quest: checkExistTags().includes(elem.id)
                                                        })}
                                                        exist_quest={checkExistTags().includes(elem.id)}
                                                        click={(event) => {
                                                            event.stopPropagation();
                                                            if (loadingIndx === null)
                                                                setSelectTag(elem)
                                                            else
                                                                message.warning(variable_18)
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        }
                                        {trajectorySkills?.length === 0
                                            ? <Button
                                                text={variable_19}
                                                click={() => history("/")}
                                                margin={"auto 0 0 0"}
                                                padding={"7px 15px"}
                                                maxWidth={"100%"}
                                                backgroundColor={"var(--background_lighten-dark)"}
                                                boxShadow={"none"}
                                            />
                                            : <Button
                                                text={selectTag?.name ? variable_6 : variable_34}
                                                click={() => updateEducationPlan()}
                                                margin={"auto 0 0 0"}
                                                padding={"7px 15px"}
                                                maxWidth={"100%"}
                                                backgroundColor={"var(--background_lighten-dark)"}
                                                boxShadow={"none"}
                                                disabled={(!selectTag?.name || loadingGpt || loadingIndx) ||
                                                    (currentListEducation?.length === config?.PLAN_GENERATION_UPDATE_LIMIT)}
                                            />
                                        }
                                    </div>
                                    <div className="share_link">
                                        <div className="text">
                                            {variable_38}
                                        </div>
                                        <div className="present">
                                            <img src={present} alt="present"/>
                                        </div>
                                        <div className="row">
                                            <div className="copy">
                                                <span>{variable_26}</span>
                                                <div
                                                    className={"copy_btn"}
                                                    onClick={copyLink}
                                                >
                                                    <span>{variable_27}</span>
                                                    <img
                                                        src={copy_link}
                                                        alt="copy_link"
                                                    />
                                                </div>
                                            </div>
                                            <div className="copy">
                                                <span>{variable_28}</span>
                                                <div className="socials">
                                                    <img
                                                        src={whatsapp}
                                                        alt="whatsapp"
                                                        onClick={handleSendWhatsaspp}
                                                    />
                                                    <img
                                                        src={telegram}
                                                        alt="telegram"
                                                        onClick={handleSendTelegram}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="banner">
                                        <img src={banner} alt="banner"/>
                                    </div>*/}
                                </div>
                                {loadingGpt &&
                                    <div className="message_loading">
                                        <span>{variable_17}</span>
                                        <Spin/>
                                    </div>
                                }
                                <div
                                    className={curlTrajectory
                                        ? "btn_curl open"
                                        : "btn_curl"
                                    }
                                    onClick={() => setCurlTrajectory(!curlTrajectory)}
                                >
                                    <span>{curlTrajectory ? variable_32 : variable_35}</span>
                                    <Arrow/>
                                </div>
                                {selectTag &&
                                    <div className="title_plan">{variable_40}</div>
                                }
                                <div className="list_planning">
                                    <>
                                        {Array(Number(page_size)).fill(0).map((_, indx) => {
                                                if ((indx > currentListEducation.length - 1) && selectTag.name) {
                                                    return (
                                                        <div
                                                            className="planning_course btn"
                                                            key={`generate-${indx}`}
                                                        >
                                                            <div className="title_index">
                                                                <div className="h1">
                                                                    <>{variable_21} </>
                                                                    № {indx + 1}
                                                                </div>
                                                            </div>
                                                            {loadingIndx === indx
                                                                ? <>
                                                                    <div className="message_loading">
                                                                        <span>{variable_17}</span>
                                                                        <Spin/>
                                                                    </div>
                                                                </>
                                                                : <Button
                                                                    text={variable_6}
                                                                    click={() => {
                                                                        setLoadingIndx(indx)
                                                                        updateEducationPlan()
                                                                    }}
                                                                    padding={"7px 15px"}
                                                                    maxWidth={"fit-content"}
                                                                    boxShadow={"none"}
                                                                    disabled={loadingIndx !== null}
                                                                />
                                                            }
                                                        </div>
                                                    )
                                                }
                                                if (currentListEducation[indx])
                                                    return (
                                                        <PlanningCourse
                                                            {...currentListEducation[indx]}
                                                            key={`planning-${currentListEducation[indx].id}`}
                                                            indx={indx}
                                                            changeFavoritePlan={changeFavoritePlan}
                                                            generateQuest={generateQuest}
                                                            openModalSize={() => {
                                                                setOpenModalSize(true);
                                                                setModalSizeIndx(indx);
                                                                setCurrentElemSize(currentListEducation[indx])
                                                            }}
                                                            loadingIndx={loadingIndx}
                                                            fullWidth={currentListEducation.length === 1}
                                                            /*fullWidth={true}*/
                                                        />
                                                    )
                                            }
                                        )}
                                    </>
                                </div>
                                {!selectTag &&
                                    <div className="player_auth">
                                        <ReactPlayer
                                            className={"player_demo"}
                                            controls={true}
                                            url={"https://www.youtube.com/watch?v=VuCj68f6pUQ"}
                                            width={"100%"}
                                        />
                                    </div>
                                }

                            </>
                            : <UnAuthenticated
                                curlTrajectory={curlTrajectory}
                                sizeTrajectory={sizeTrajectory}
                                trajectorySkills={trajectorySkills}
                                setSizeTrajectory={setSizeTrajectory}
                            />
                        }
                    </div>
                </>
                : <div className="favorite_page">
                    <PrevButton click={() => setActivePage(0)}/>
                    <div className="header">
                        <div className="title_block">
                            <div className="h1">
                                {variable_29}
                            </div>
                            <div className="text">
                                {variable_30}
                            </div>
                        </div>
                    </div>
                    {(trajectorySkills.length > 0 && haveFavoriteInList(trajectorySkills)) &&
                        <div className="parent_traektory">
                            <div className="traektory">
                                {trajectorySkills.map((elem) => {
                                        if (elem.education_plans_count > 0 && elem.favorite)
                                            return (
                                                <Tag
                                                    key={elem.id}
                                                    elem={elem}
                                                    border={activeTag === elem.id}
                                                    cursorPointer={true}
                                                    hideRemoveIcon={true}
                                                    click={() => {
                                                        if (activeTag === elem.id) {
                                                            setActiveTag(null)
                                                        } else setActiveTag(elem.id)
                                                    }}
                                                />
                                            )
                                    }
                                )}
                            </div>
                        </div>
                    }
                    {(favoritesList?.results?.length > 0 && !loadingGpt) &&
                        <div className="list_planning">
                            <>
                                {favoritesList.results.map((elem, indx) =>
                                    <PlanningCourse
                                        {...elem}
                                        key={`planning-${elem.id}`}
                                        indx={indx}
                                        changeFavoritePlan={(id, value) => {
                                            changeFavoritePlan(id, value);
                                            fetchTrajectory()
                                            fetchFavorites()
                                        }}
                                        generateQuest={generateQuest}
                                        isFavorite={true}
                                    />
                                )}
                            </>
                        </div>
                    }
                    {(favoritesList?.count > page_size) &&
                        <div className="pagination_all">
                            <Pagination
                                itemRender={defaultFuncAndData.itemRenderPagination}
                                current={currentPage}
                                defaultCurrent={1}
                                defaultPageSize={page_size}
                                total={favoritesList.count}
                                onChange={(e) => setCurrentPage(e)}
                                showTitle={false}
                            />
                        </div>
                    }
                </div>
            }

            {/*  <ModalInvite
                openModalInvite={openModalInvite}
                setOpenModalInvite={setOpenModalInvite}
                currentElemSize={currentElemSize}
            />*/}
            <ModalSize
                openModalSize={openModalSize}
                currentElemSize={currentElemSize}
                changeFavoritePlan={changeFavoritePlan}
                generateQuest={generateQuest}
                indx={modalSizeIndx}
                loadingIndx={loadingIndx}
                setOpenModalSize={() => {
                    setOpenModalSize(false)
                    setModalSizeIndx(null)
                    setCurrentElemSize({})
                }}
            />
            <ModalBuy
                openModalBuy={showModalBuy}
                setOpenModalBuy={(value) => dispatch(setShowBuyModal(value))}
                config={config}
            />
        </div>
    )
}

export default GenerateCourse;