import React, { useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import requests from "../../axios/requests";

export default function AuthToken() {
    const params = useParams();
    const history = useNavigate();

    useEffect(() => {
        if (params?.token) {
            localStorage.setItem("token", params.token);
            setTimeout(() => {
                requests.users.get_profile_new()
                    .then(() => {
                        history("/")
                    })
                    .catch(err => console.error(err))
            }, 100)
        }
    }, [])
    return (<div />)
}
