import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MouseParallaxChild, MouseParallaxContainer } from "react-parallax-mouse";
import variableLanguage from "../../../utils/hookGetVariablesLanguage";
import defaultSkillOptions from "../../../utils/defaultSkillOptions";
import { Spin, Tag, } from "antd";
import { defaultFuncAndData } from "../../../utils/defaultData";
import requests from "../../axios/requests";

import left_top from "../../../images/auth/left_top.png";
import left_middle from "../../../images/auth/left_middle.png";
import left_bottom from "../../../images/auth/left_bottom.png";
import right_top from "../../../images/auth/right_top.png";
import right_middle from "../../../images/auth/right_middle.png";
import right_bottom from "../../../images/auth/right_bottom.png";

import LanguageSelect from "./Step_1/LanguageSelect";
import Step_1 from "./Step_1/Step_1";
import Step_2 from "./Step_2/Step_2";
import Step_3 from "./Step_3/Step_3";
import Step_4 from "./Step_4/Step_4";
import ModalWelcome from "./ModalWelcome/ModalWelcome";
import './Auth.scss';

const Auth = () => {
    const history = useNavigate();
    const {language, user} = useSelector(state => state.Reducer);

    const variables_1 = variableLanguage({keyPage: "authorization", keyVariable: "variables_1"});
    const variables_13 = variableLanguage({keyPage: "authorization", keyVariable: "variables_13"});
    const variables_28 = variableLanguage({keyPage: "authorization", keyVariable: "variables_28"});
    const variables_42 = variableLanguage({keyPage: "authorization", keyVariable: "variables_42"});
    const variables_58 = variableLanguage({keyPage: "authorization", keyVariable: "variables_58"});
    const variables_59 = variableLanguage({keyPage: "authorization", keyVariable: "variables_59"});

    const [current, setCurrent] = useState(0);
    const [fio, setFio] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [activeLanguage, setActiveLanguage] = useState(null);
    const [enteredCodStep, setEnteredCodStep] = useState(false);
    const [freelanceStep, setFreelanceStep] = useState(false);
    const [tagsStep3, setTagsStep3] = useState([]);
    const [activeActivity, setActiveActivity] = useState(0);
    const [suggestedSelectOptions, setSuggestedSelectOptions] = useState([]);
    const [existTagsList, setExistTagsList] = useState([]);
    const [flagOpenSelectTags, setFlagOpenSelectTags] = useState(false);
    const [loading, setLoading] = useState(true);
    const [valueInputTag, setValueInputTag] = useState("");
    const [pageSize, setPageSize] = useState(10);
    const [timeoutSearch, setTimeoutSearch] = useState(null);
    const [timeoutFetchPagination, setTimeoutFetchPagination] = useState(null);
    const [loadingSkills, setLoadingSkills] = useState(false);

    let validateSelectSkills = (name) => {
        let haveSkillInList = false;
        for (let i = 0; i < defaultFuncAndData.arrTags().length; i++) {
            if (defaultFuncAndData.arrTags()[i].name === name)
                haveSkillInList = true;
        }
        for (let i = 0; i < defaultSkillOptions(activeActivity).length; i++) {
            if (defaultSkillOptions(activeActivity) === name)
                haveSkillInList = true;
        }
        for (let i = 0; i < defaultFuncAndData.arrTagsAllPopular().length; i++) {
            if (defaultFuncAndData.arrTagsAllPopular()[i].name === name)
                haveSkillInList = true;
        }
        if (existTagsList?.tags?.length > 0) {
            for (let i = 0; i < existTagsList.tags.length; i++) {
                if (existTagsList.tags[i] === name)
                    haveSkillInList = true
            }
        }
        for (let i = 0; i < suggestedSelectOptions.length; i++) {
            if (suggestedSelectOptions[i] === name)
                haveSkillInList = true
        }
        return haveSkillInList; // не  добавлять скилл если его нет в предложенных
    }
    let searchSkills = (e) => {
        setLoading(true);
        requests.profs.get_speciality_tags(valueInputTag, 1, page_size || pageSize)
            .then((v) => setExistTagsList(v.data))
            .finally(() => setLoading(false))
    };
    let tagRender = (props) => {
        const {label, closable} = props;
        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={() => {
                    let obj = {name: label};
                    let newArr = [...tagsStep3];
                    newArr = newArr.filter(tag => tag !== label);
                    setTagsStep3(newArr)
                    requests.quests.remove_trajectory_skill(obj)
                        .catch((e) => console.error(e));
                }}
                style={current === 2 ?
                    {
                        marginRight: 3,
                        marginBottom: 5,
                        color: "#FFD23B",
                        fontSize: 12,
                        background: "rgba(255, 210, 59, 0.2)",
                        lineHeight: 2.1,
                        border: "none"
                    }
                    : {
                        marginRight: 3,
                        marginBottom: 5,
                        color: "#A2AACA",
                        fontSize: 12,
                        background: "rgba(162, 170, 202, 0.2)",
                        lineHeight: 2.1,
                        border: "none"
                    }
                }
            >
                {label}
            </Tag>
        );
    };

    useEffect(() => {
        setLoading(false);
        if (localStorage.getItem("token"))
            return history("/");
    }, []);
    useEffect(() => {
        if (typeof user?.avatar !== 'undefined' && user.avatar) {
            setImage(user.avatar)
        }
    }, [user]);
    useEffect(() => {
        setSuggestedSelectOptions(defaultSkillOptions(activeActivity));
    }, [activeActivity]);
    useEffect(() => {
        setActiveLanguage(language === "en" ? 1 : 0);
        if (language)
            localStorage.setItem("language", language);
    }, [language]);

    useEffect(() => {
        if (valueInputTag.length > 1) {
            let lastSymbol = valueInputTag[valueInputTag.length - 1];
            if (lastSymbol !== " ") {
                setPageSize(10)
                setExistTagsList([]);
                if (timeoutSearch)
                    clearTimeout(timeoutSearch)
                setTimeoutSearch(setTimeout(() =>
                    fetchSearchTags(10), 500))
            }
        }
    }, [valueInputTag])
    if (loading)
        return <Spin style={{position: "absolute", left: "50%", top: "20%"}}/>;
    return (
        <div
            className={"auth"}
            style={{overflowX: "hidden"}}
        >
            {current !== 4 &&
                <main
                    className="main"
                    style={current === 0 ? {margin: "auto"} : current === 3 ? {marginTop: "16.8vh"} : {}}
                >
                    <div className="inner">
                        <h1>
                            {current === 0 && variables_1}
                            {current === 1 && variables_13}
                            {current === 2 &&
                                <>
                                    {freelanceStep
                                        ? <>
                                            {variables_58}
                                            <div className="freelance_info">{variables_59}</div>
                                        </>
                                        : variables_28
                                    }
                                </>
                            }
                            {current === 3 && variables_42}
                        </h1>
                        {current === 0 && !enteredCodStep
                            ? <LanguageSelect activeLanguage={activeLanguage}/>
                            : <></>
                        }
                        <div className="step_custom">
                            <div className={current === 0 ? "step_first active" : "step_first"}>
                                <span>1</span>
                            </div>
                            <div className="step_second">
                                <div className="line"/>
                            </div>
                            <div className={current === 1 ? "step_third active" : "step_third"}>
                                <span>2</span>
                            </div>
                            <div className="step_fourth">
                                <div className="line"/>
                            </div>
                            <div className={current === 2 ? "step_fifth active" : "step_fifth"}>
                                <span>3</span></div>
                            <div className="step_six">
                                <div className="line"/>
                            </div>
                            <div className={current === 3 ? "step_seventh active" : "step_seventh"}>
                                <span>4</span>
                            </div>
                        </div>
                        <div className="steps-content">
                            {current === 0 &&
                                <Step_1
                                    phone={phone}
                                    enteredCodStep={enteredCodStep}
                                    setPhone={setPhone}
                                    setCurrent={setCurrent}
                                    setName={setName}
                                    setFio={setFio}
                                    setImage={setImage}
                                    setEnteredCodStep={setEnteredCodStep}
                                />
                            }
                            {current === 1 &&
                                <Step_2
                                    fio={fio}
                                    email={email}
                                    setFio={setFio}
                                    setEmail={setEmail}
                                    phone={phone}
                                    setImage={setImage}
                                    setCurrent={setCurrent}
                                    current={current}
                                    setName={setName}
                                />
                            }
                            {current === 2 &&
                                <Step_3
                                    tagsStep3={tagsStep3}
                                    activeActivity={activeActivity}
                                    freelanceStep={freelanceStep}
                                    validateSelectSkills={validateSelectSkills}
                                    existTagsList={existTagsList}
                                    loadingSkills={loadingSkills}
                                    name={name}
                                    image={image}
                                    searchSkills={setValueInputTag}
                                    tagRender={tagRender}
                                    suggestedSelectOptions={suggestedSelectOptions}
                                    flagOpenSelectTags={flagOpenSelectTags}
                                    setCurrent={setCurrent}
                                    setFlagOpenSelectTags={setFlagOpenSelectTags}
                                    setTagsStep3={setTagsStep3}
                                    setActiveActivity={setActiveActivity}
                                    setFreelanceStep={setFreelanceStep}
                                />
                            }
                            {current === 3 &&
                                <Step_4
                                    searchSkills={setValueInputTag}
                                    validateSelectSkills={validateSelectSkills}
                                    tagRender={tagRender}
                                    existTagsList={existTagsList}
                                    loadingSkills={loadingSkills}
                                    suggestedSelectOptions={suggestedSelectOptions}
                                    setCurrent={setCurrent}
                                />
                            }
                        </div>
                    </div>
                </main>
            }
            {current === 4 &&
                <ModalWelcome
                    image={image}
                    name={name}
                    setImage={setImage}
                />
            }
            <div
                className="block_parralax"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1
                }}
            >
                <MouseParallaxContainer
                    className="parallax"
                    containerStyles={{
                        width: "100%",
                        height: "98%",
                        overflow: "inherit"
                    }}
                    resetOnLeave
                >
                    <MouseParallaxChild
                        factorX={0.01}
                        factorY={0.02}
                        updateStyles={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <div className="left_top"><img src={left_top} alt=""/></div>
                        <div className="left_middle"><img src={left_middle} alt=""/></div>
                        <div className="left_bottom"><img src={left_bottom} alt=""/></div>
                        <div className="right_top"><img src={right_top} alt=""/></div>
                        <div className="right_middle"><img src={right_middle} alt=""/></div>
                        <div className="right_bottom"><img src={right_bottom} alt=""/></div>
                    </MouseParallaxChild>
                </MouseParallaxContainer>
            </div>
            <div
                className="no_parallax"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 0,
                    opacity: 0,
                    display: "none"
                }}>
                <div className="left_top" style={{zIndex: 0}}><img src={left_top} alt=""/></div>
                <div className="left_middle" style={{zIndex: 0}}><img src={left_middle} alt=""/></div>
                <div className="left_bottom" style={{zIndex: 0}}><img src={left_bottom} alt=""/></div>
                <div className="right_top" style={{zIndex: 0}}><img src={right_top} alt=""/></div>
                <div className="right_middle" style={{zIndex: 0}}><img src={right_middle} alt=""/></div>
                <div className="right_bottom" style={{zIndex: 0}}><img src={right_bottom} alt=""/></div>
            </div>
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 0,
                    opacity: 0
                }}
                className="mobile_block-image"
            >
                <div style={{top: 351, left: 70}} className="left_top"><img width={85} height={85}
                                                                            src={left_top} alt=""/></div>
                <div style={{top: 540, left: 15}} className="left_middle"><img width={85} height={85}
                                                                               src={left_middle} alt=""/>
                </div>
                <div style={{top: 640, left: "65%"}} className="left_bottom"><img width={85} height={85}
                                                                                  src={left_bottom} alt=""/>
                </div>
                <div style={{display: "none"}} className="right_top"><img width={85} height={85}
                                                                          src={right_top}
                                                                          alt=""/></div>
                <div style={enteredCodStep ? {top: 470, right: "25%"} : {top: 500, right: "10%"}}
                     className="right_middle"><img width={85} height={85} src={right_middle} alt=""/></div>
                <div style={enteredCodStep ? {top: 307, left: 262} : {top: 247, left: 32}}
                     className="right_bottom"><img width={85} height={85} src={right_bottom} alt=""/></div>
            </div>
        </div>
    );
};

export default Auth;
