import React from 'react';
import { Modal } from "antd";
import "./ModalContainer.scss";
import close_modal_course from "../../../images/icons/close_modal-course.svg";

const ModalContainer = ({open, close, current,children} ) => {
    return (
        <Modal
            open={open}
            onCancel={() => close(false)}
            footer={null}
            closable={false}
            className={`reuse_modal ${current}`}
            destroyOnClose={true}
            closeIcon={
                <div className="close" onClick={close}>
                    <img src={close_modal_course}/>
                </div>
            }
        >
            {children}
        </Modal>
    );
};

export default ModalContainer;