import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { changeLanguage } from "../../../redux/actions/authorizationActions";

import rus from "../../../../images/country/russian_icon.png";
import usa from "../../../../images/icons/usa.png";
import arrow from '../../../../images/arrow.svg';

const LanguageSelect = ({activeLanguage}) => {
    const dispatch = useDispatch();
    const [flagDropdownLanguage, setFlagDropDownLanguage] = useState(false);
    return (
        <div
            className={flagDropdownLanguage ? "drop_down-language open" : "drop_down-language"}
            tabIndex={0}
            onClick={() => setFlagDropDownLanguage(!flagDropdownLanguage)}
            onBlur={() => setFlagDropDownLanguage(false)}
        >
            {activeLanguage === 0
                ? <img src={rus}/>
                : <img src={usa}/>
            }
            <span> {["RU", "EN"][activeLanguage]}</span>
            <img src={arrow}/>
            <div className={flagDropdownLanguage ? "drop_down open" : "drop_down"}>
                {["RU", "EN"].map((elem, indx) =>
                    <div
                        className="language"
                        onClick={() => dispatch(changeLanguage(indx === 0 ? "ru" : "en"))}
                        key={elem}
                    >
                        {indx === 0
                            ? <img src={rus}/>
                            : <img src={usa}/>
                        }
                        {elem}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LanguageSelect;