import axios from "axios";
import { message } from "antd";
import qs from "qs";

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const baseURLNotification = process.env.REACT_APP_API_URL_NOTIFICATION;
const defaultApiSetting = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
};
// 'https://skillometer.ru/api' ||
const apiClient = axios.create(defaultApiSetting);

let responseSuccessHandler = response => {
    return response;
};
let responseErrorHandler = error => {
    if (error?.response?.status === 401) {
        if (!window.location.href.includes("auth")
            && !window.location.href.includes("authorization-link")
            && !window.location.href.includes("/student/resume/")
            && !window.location.href.includes("/course-preview")
            && !window.location.href.includes("/generate-course")
        ) {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("user_id");
            window.localStorage.removeItem("id");
            window.localStorage.removeItem("username");
            window.localStorage.removeItem("last_name");
            window.localStorage.removeItem("middle_name");
            return window.location.replace("/student/auth");
        }
    }
    if (error?.response?.status === 403 && window.location.href.includes("/student/course/lesson/")) {
        message.error(localStorage.getItem("language") === "en"
            ? "No access to this course."
            : "Нет доступа к этому курсу."
        );
        setTimeout(() => {
            window.location.replace("/student/my-courses");
        }, 2500);
        return Promise.reject("error");
    }
    if (error?.response?.status === 400 && window.location.href.includes("/student/course-preview/")) {
        if (error.response?.data?.error)
            message.error(error.response?.data?.error);
        return Promise.reject(error);
    }
    return Promise.reject(error);
};

apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.common.Authorization = `Token ${token}`;
        }
        return config;
    },
    null, {synchronous: true}
);

apiClient.interceptors.response.use(
    response => responseSuccessHandler(response),
    error => responseErrorHandler(error)
);

export default {
    auth: {
        register_user(request) {
            return apiClient.post(`/v2/web/student/profile/register/`, request);
        },
        qr_create_session(request) {
            return apiClient.post(`request/create_session`, request);
        },
        qr_login(request) {
            return apiClient.post(`request/qr_login`, request);
        }
    },
    storage: {
        get_yandex_key(request) {
            return apiClient.post(`backoffice/get_yandex_key`, request);
        },
        get_yandex_quest_key() {
            return apiClient.get(`backoffice/get_yandex_quest_key`);
        },
        get_yandex_avatar_key() {
            return apiClient.get(`backoffice/get_yandex_avatar_key`);
        },
        get_yandex_cv_key() {
            return apiClient.get(`backoffice/get_yandex_cv_key`);
        },
        storage_upload(yandexKey, file, progressFunc) {
            function encodeRFC5987ValueChars(str) {
                return encodeURIComponent(str).
                    // Замечание: хотя RFC3986 резервирует "!", RFC5987 это не делает, так что нам не нужно избегать этого
                    replace(/['()]/g, escape). // i.e., %27 %28 %29
                    replace(/\*/g, "%2A").
                    // Следующее не требуется для кодирования процентов для RFC5987, так что мы можем разрешить немного больше читаемости через провод: |`^
                    replace(/%(?:7C|60|5E)/g, unescape);
            }

            let formData = new FormData();
            [
                /*["Content-Type", "application/octet-stream"],*/
                ["Content-Disposition", `attachment; filename*=UTF-8''${encodeRFC5987ValueChars(file.name)}`],
                ["key", yandexKey],
                ["file", file]
            ].forEach(([k, v]) => formData.append(k, v));

            return apiClient.request({
                url: "https://storage.yandexcloud.net/skill-storage/",
                method: "POST",
                data: formData,
                onUploadProgress: progressFunc
            });
        },
    },
    users: {
        request_sms(request) {
            return apiClient.post(`request/request_sms`, request);
        },
        validate_sms(request) {
            return apiClient.post(`request/validate_sms`, request);
        },
        get_profile(request) {
            return apiClient.post(`request/get_profile`, request);
        },
        get_profile_new() {
            return apiClient.get(`/v2/web/student/profile/profile/`);
        },
        edit_profile_new(request) {
            return apiClient.patch(`/v2/web/student/profile/profile/`, request);
        },
        logout() {
            return apiClient.post(`request/logout`);
        },
        get_freelancers_list(favorite, sort, search, category, page, page_size) {
            let params = {
                ...(search ? {q: search} : {}),
                ...(category ? {category} : {}),
                ...(sort ? {order: sort} : {}),
                ...(favorite ? {favorite} : {}),
                page,
                page_size
            }
            return apiClient.get(`/v2/web/common/cv/`, {params})
        },
        add_freelancer_favorite(id) {
            return apiClient.post(`/v2/web/common/cv/${id}/favorite/`)
        },
        remove_freelance_favorite(id) {
            return apiClient.delete(`/v2/web/common/cv/${id}/favorite/`)
        },
        get_resume_id(id) {
            return apiClient.get(`/v2/web/common/cv/${id}/`)
        },
        education_plan(request) {
            return apiClient.post("/v2/web/user/education_plan/", request)
        },
        generate_quest(id) {
            return apiClient.post(`/v2/web/user/education_plan/${id}/generate_quest/`)
        },
        fetch_education_plan(id) {
            return apiClient.get(`/v2/web/user/education_plan/?speciality_tag=${id}`)
        },
        get_config() {
            return apiClient.get("/v2/web/common/config/")
        },
        calery_progress(id) {
            return apiClient.get(`/celery-progress/${id}/`)
        },
        buy_education_plan(tries) {
            return apiClient.post("/v2/web/user/education_plan/payment/init/", {tries})
        },
        get_plannings() {
            return apiClient.get("/v2/web/user/education_plan/",
                {params: {page_size: 1000}}
            )
        },
        changeFavoritePlan(id, value) {
            return apiClient.patch(`/v2/web/user/education_plan/${id}/`, {favorite: value})
        },
        fetchFavorites(page, page_size, speciality_tag) {
            return apiClient.get("/v2/web/user/education_plan/?favorite=true", {
                    params: {
                        page,
                        page_size,
                        ...(speciality_tag ? {speciality_tag} : {})
                    }
                }
            )
        }
    },
    quests: {
        get_actual_quest(request) {
            let str = "";
            if (request) str = request + "/";
            return apiClient.get("/v2/web/student/quest/actual_quest/" + str);
        },
        get_trajectory_skill() {
            return apiClient.get(`/v2/web/student/profile/trajectory_skill/`);
        },
        get_course_id(quest_id) {
            return apiClient.get(`/v2/web/student/quest/quest${quest_id}/`);
        },
        add_trajectory_skill(request) {
            return apiClient.post(`/v2/web/student/profile/trajectory_skill/`, request);
        },
        remove_trajectory_skill(name) {
            return apiClient.delete(`/v2/web/student/profile/trajectory_skill/?name=${name.replaceAll("&", "%26").replaceAll("\\?", "%3f")}`);
        },
        get_user_tags() {
            return apiClient.get(`request/get_user_tags`);
        },
        get_quest_quest(
            arr, currentPage, page_size, speciality, searchValue = "",
            my = "", flagPaid = false, ordering = "", finished = false
        ) {
            let params = {
                page: currentPage,
                page_size: page_size,
                ...((Array.isArray(arr) && arr.length > 0) ? {speciality_tag: arr.join(',')} : {}),
                ...(speciality ? {speciality: speciality} : {}),
                ...(searchValue ? {q: searchValue} : {}),
                ...(my ? {my: my} : {}),
                ...(flagPaid ? {price_type: "free"} : {}),
                ...(ordering ? {ordering} : {}),
                ...(finished ? {finished: false} : {}) // показывать только те курсы, которые еще не закончились по дате
            }
            return apiClient.get(`/v2/web/student/quest/quest/`, {params});
        },
        add_request_quest(request) {
            return apiClient.post(`request/add_request_quest`, request);
        },
        get_quest_progress(quest_id) {
            return apiClient.get(`request/get_quest_progress/${quest_id}`);
        },
        post_quest_review(quest, rating, text) {
            return apiClient.post("/v2/web/student/quest/quest_review/", {quest, rating, text});
        },
        get_quest_review(id) {
            return apiClient.get(`/v2/web/student/quest/quest_review/?quest=${id}`);
        },
        post_user_question(request) {
            return apiClient.post("/v2/web/student/quest/user_question/", request);
        },
        payment_init_post(request) {
            return apiClient.post("/v2/web/student/quest/payment/init/", request);
        },
        payment_init_get(id) {
            return apiClient.get(`/v2/web/student/quest/payment/get_state/${id}/`);
        },
        get_referral(link) {
            return apiClient.get(link)
        },
        get_result_quiz(id) {
            return apiClient.get(`v2/web/student/quiz/quiz_result/`, {params: {quiz: id}})
        },
        generate_certificate(id, fio, email) {
            return apiClient.post(`v2/web/student/quest/quest/${id}/generate_certificate/`, {fio, email})
        }
    },
    stages: {
        get_stages(quest_id) {
            return apiClient.get(`request/get_stages?quest_id=${quest_id}`);
        },
        get_stage_theories_info(stage_id) {
            return apiClient.get(`request/get_stage_theories_info?stage_id=${stage_id}`);
        },
        post_quizresult(request) {
            return apiClient.post(`request/post_quizresult`, request);
        },
        post_seen(request) {
            return apiClient.post(`request/post_seen`, request);
        },
        post_homework_send(request) {
            return apiClient.post(`request/homework_send`, request);
        },
        post_code_editor(request) {
            return apiClient.request(
                {
                    method: "post",
                    url: 'https://compile.skillometer.ru/',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: request
                }
            );
        },
        lmg_initialize(id) {
            return apiClient.post(`v2/web/student/stage/stage_theory/${id}/initialize/`, {});
        },
        lms_data(request, id) {
            return apiClient.patch(`v2/web/student/stage/stage_theory/${id}/lms_data/`, request)
        },
        lmg_finish(id) {
            return apiClient.post(`v2/web/student/stage/stage_theory/${id}/finish/`, {});
        }
    },
    profs: {
        get_industry() {
            return apiClient.get(`v2/web/student/vacancy/hh/industry/`);
        },
        get_profs_neyron(current, tags) {
            return apiClient.post(`v2/web/student/profession/profession/${current}/`, tags);
        },
        get_hh_vacancy_filter(props) {
            const {
                text, area, search_field, industry, salary, only_with_salary,
                experiense, schedule, order_by, period
            } = props;

            let params = {
                ...(text ? {text} : {}),
                ...(area ? {area} : {}),
                ...(industry ? {industry} : {}),
                ...(salary ? {salary} : {}),
                ...(only_with_salary ? {only_with_salary} : {}),
                ...(experiense ? {experiense} : {}),
                ...(schedule ? {schedule} : {}),
                ...(order_by ? {order_by} : {}),
                ...(period ? {period} : {}),
                ...(search_field
                        ? {search_field: `name,${search_field}`}
                        : {search_field: `name`}
                )
            }
            return apiClient.get(`v2/web/student/vacancy/hh/vacancies/`, {params});
        },
        get_hh_vacancy(professionName, region) {
            return apiClient.get("v2/web/student/vacancy/hh/vacancies/", {
                params: {
                    ...(professionName ? {text: professionName} : {}),
                    ...(region ? {area: region} : {})
                }
            });
        },
        get_profession_favorite() {
            return apiClient.get("/v2/web/student/profession/favorite_profession/");
        },
        get_vacancy_favorite() {
            return apiClient.get("/v2/web/student/vacancy/favorite_vacancy/");
        },
        add_vacancy_favorite(request) {
            return apiClient.post("/v2/web/student/vacancy/favorite_vacancy/", request);
        },
        remove_vacancy_favorite(request) {
            return apiClient.delete(`/v2/web/student/vacancy/favorite_vacancy/${request}/`);
        },
        add_profession_favorite(request) {
            return apiClient.post("/v2/web/student/profession/favorite_profession/", request);
        },
        remove_profession_favorite(request) {
            return apiClient.delete(`/v2/web/student/profession/favorite_profession/${request}/`);
        },
        profs_list(request) {
            let str = "";
            if (request) str = `?type=it`;
            return apiClient.get(`/v2/web/student/profession/profs_list/` + str);
        },
        get_speciality_tags(name, page, page_size) {
            let params = {name, page, page_size};
            return apiClient.get(`/v2/web/common/speciality_tag/`, {params});
        },
    },
    skills: {
        add_user_skills(request) {
            return apiClient.post(`request/change_user_skills`, request);
        },
        delete_user_skill(request) {
            return apiClient.request({method: "DELETE", url: `request/change_user_skills`, data: request});
        },

    },
    comments: {
        get_comment_matherial(request) {
            return apiClient.get("/v2/web/student/reaction/comment/" + `?content_type=${request.contentType}` + `&object_id=${request.objectId}`);
        },
        add_comment_matherial(request) {
            return apiClient.post("/v2/web/student/reaction/comment/", request);
        },
        set_like(request) {
            return apiClient.post(`/v2/web/student/reaction/like/`, request);
        }
    },
    profile: {
        get_profile_tags(name) {
            let str = "";
            if (name)
                str = `?name=${name}`;
            return apiClient.get("/v2/web/common/speciality_tag/" + str);
        },
        get_profile_resume() {
            return apiClient.get("/v2/web/student/profile/cv/");
        },
        put_profile_resume(request) {
            return apiClient.put("/v2/web/student/profile/cv/", request);
        },
        patch_profile_resume(request) {
            return apiClient.patch("/v2/web/student/profile/cv/", request);
        },
        create_profile_social(request) {
            return apiClient.post("/v2/web/student/profile/cv/contact/", request);
        },
        patch_profile_social(social_id, request) {
            return apiClient.patch(`/v2/web/student/profile/cv/contact/${social_id}/`, request);
        },
        remove_profile_social(social_id) {
            return apiClient.delete(`/v2/web/student/profile/cv/contact/${social_id}/`);
        },
        create_profile_education(request) {
            return apiClient.post("/v2/web/student/profile/cv/education/", request);
        },
        patch_profile_education(education_id, request) {
            return apiClient.patch(`/v2/web/student/profile/cv/education/${education_id}/`, request);
        },
        remove_profile_education(education_id) {
            return apiClient.delete(`/v2/web/student/profile/cv/education/${education_id}/`);
        },
        create_profile_experience(request) {
            return apiClient.post("/v2/web/student/profile/cv/work_experience/", request);
        },
        patch_profile_experience(experience_id, request) {
            return apiClient.patch(`/v2/web/student/profile/cv/work_experience/${experience_id}/`, request);
        },
        remove_profile_experience(experience_id) {
            return apiClient.delete(`/v2/web/student/profile/cv/work_experience/${experience_id}/`);
        },
        create_profile_language(request) {
            return apiClient.post("/v2/web/student/profile/cv/language_skill/", request);
        },
        patch_profile_language(language_id, request) {
            return apiClient.patch(`/v2/web/student/profile/cv/language_skill/${language_id}/`, request);
        },
        remove_profile_language(language_id) {
            return apiClient.delete(`/v2/web/student/profile/cv/language_skill/${language_id}/`);
        },
    },
    notification: {
        get_notification(request) {
            let strViewed = "";
            if (request.viewed !== null)
                strViewed = `&viewed=${request.viewed}`;
            return apiClient.get(`notification/?page=${request.page}&size=${request.size}` + strViewed, {baseURL: baseURLNotification});
        },
        post_view_notification(id) {
            return apiClient.post(`notification/${id}/view/`, {}, {baseURL: baseURLNotification});
        },
        get_count_notification() {
            return apiClient.get("notification/count/", {baseURL: baseURLNotification});
        }
    },
};
